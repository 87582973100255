import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuditoryService } from 'src/app/services/auditory.service';

@Component({
  selector: 'app-card-logs',
  templateUrl: './card-logs.component.html',
  styleUrls: ['./card-logs.component.scss']
})
export class CardLogsComponent implements OnInit {
  @Input() ids
  logs;
  constructor(
    private auditory:AuditoryService
  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(changes.ids.currentValue);
    this.auditory.getAuditoryClient(changes.ids.currentValue).subscribe(resp=>{
      console.log(resp,"asdasdsad asd asdsadasd")
      this.logs=resp;
    })
    
  }

}
