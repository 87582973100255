import { Component, Input, OnInit } from '@angular/core';
import { select, selectAll } from 'd3-selection';
import { transition } from 'd3-transition';
import { forceSimulation, forceLink, forceManyBody, forceCenter } from 'd3-force';
import { drag } from 'd3-drag';
const d3 = { select, selectAll, transition, forceSimulation, forceLink, forceManyBody, forceCenter, drag };

@Component({
  selector: 'app-graphic',
  templateUrl: './graphic.component.html',
  styleUrls: ['./graphic.component.scss']
})
export class GraphicComponent implements OnInit {

  @Input() value = {
    color: '#fff',
    value: 0
  };

  knOptions = {
    readOnly: true,
    size: 80,
    unit: '%',
    textColor: '#000000',
    fontSize: '20',
    fontWeigth: '600',
    fontFamily: 'Source Sans Pro',
    valueformat: 'percent',
    value: 0,
    max: 100,
    trackWidth: 14,
    barWidth: 15,
    trackColor: '#D8D8D8',
    barColor: this.value.color,
    // subText: {
    //   enabled: true,
    //   fontFamily: 'Verdana',
    //   font: '14',
    //   fontWeight: 'bold',
    //   text: 'Overall',
    //   color: '#000000',
    //   offset: 7
    // },
  } 

  constructor() { }

  ngOnInit(): void {
    if(this.value.color === '#dc3545' && this.value.value >= 70){
      this.knOptions.barColor = '#28a745';
    }else{
      this.knOptions.barColor = this.value.color;
    }

    // console.log(this.knOptions.barColor);
  }

}
