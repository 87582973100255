
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { Title } from '@angular/platform-browser';
import { InveriteService } from 'src/app/services/inverite.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  constructor(
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router:Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private inveriteService: InveriteService,
    private title: Title
  ) { }

  ngOnInit() {
    this.inveriteService.getIPAddress().then((res:any)=>{
      localStorage.setItem('ip', `${res.data.ip}`);
    });
    this.title.setTitle('PrestoBroker');
    if (this.tokenStorage.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorage.getUser().roles;
      this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/login', 'Follow User', 'userLabel', 1);
      this.router.navigate(['dashboard']);
    }
  }

  onSubmit() {
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().roles;
        this.reloadPage();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isLoginFailed = true;
      }
    );
  }

  reloadPage() {
    window.location.reload();
  }
}
