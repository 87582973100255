import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = environment.url_server+'/api/questions_frs';

@Injectable({
  providedIn: 'root'
})

export class QuestionfrService {

  constructor(private http: HttpClient) { }

  get(id) {
    console.log(`${baseUrl}/${id}`,"fr")
    return this.http.get(`${baseUrl}/${id}`);
  }

}
