import { Component, HostListener, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'src/app/services/client.service';
import { StatuService } from 'src/app/services/statu.service';
import { BranchService } from 'src/app/services/branch.service';
import { LoandiskService } from 'src/app/services/loandisk.service';
import { Ibv } from 'src/app/services/ibv.service';
import { QuestionAnswerService } from 'src/app/services/question-answer.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { MailService } from "../../services/mail.service";
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuditoryService } from 'src/app/services/auditory.service';
import { InveriteService } from 'src/app/services/inverite.service';
import { TimeStadisticService } from 'src/app/services/time_stadistic.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { trim } from 'jquery';
import { ReasonsService } from 'src/app/services/reasons.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {

  list_clients = null;
  message = '';
  showNav = [true,false,false,false,false,false,false,false,false,false];//All,view,approbate,edit
  userShow = null;
  userIbv = null;
  userIbvDetails = null;
  active = 'active';
  userHistory = null;
  detailsBank = null;
  list_status = null;
  list_branches = null;
  borrower_data = null;
  existIbv = false;
  banks = null;
  dataLoan = null;
  user_view = null;
  user_id = 0;
  user_edit = null;
  request: FormGroup;
  selectedShow :number = null;
  modal : NgbModalRef;
  numberItems = 50;
  date = false;
  submitted = false;
  private borrower_id = null;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  disabledDates = null;
  borrower
  time_id
  reasons = null;
  loan_pack = Array();
  datepickerModel= null;
  daterangepickerModel: Date[];
  minDate: Date;
  maxDate: Date;
  roles = null;
  showAdminBoard = null;
  showModeratorBoard = null;
  showCustomerServiceBoard = null;
  setStatus
  goBacks
  username = '';
  idUser = 0;
  ipAddress = '';
  new_status = "";
  old_status = '';
  report = null;
  borrower_id_user = null
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  xlsx = null;
  search=""
  hour = 0;
  minutes = 0;
  seconds = 0;
  next_page: any = 0;

  hour_return = 0;
  minutes_return = 0;
  seconds_return = 0;

  time_view_file = '';
  button_token=null;
  myDeactive=null;

  maskNumber = {
    guide: true,
    showMask: true,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]//(999)999-9999
  };
  urls:string="";
  urldetails;
  goBackStatus: string;
  allclient;
  logs;
  id: any;

  dropdownList: any = [];
  dropdownSettings: IDropdownSettings = {};
  dropdown = false;

  userActivityTimeout = null;
  userActivityThrottlerTimeout = null;
  isInactive = false;
  USER_ACTIVITY_THROTTLER_TIME: number=1000;
  INACTIVE_USER_TIME_THRESHOLD: number=2000;
  timeout
  load=false
  page: number=1;
  hiddenLoadMore: boolean=false;
  band: boolean=false;
  searchLoad: boolean=true;
  data: any;
  Id: string;
  ibv: string;
  constructor(
    private modalService: NgbModal,
    private loandiskService: LoandiskService,
    private MailService: MailService,
    private branchService: BranchService,
    private clientService: ClientService,
    private statuService: StatuService,
    private questionAnswerService: QuestionAnswerService,
    private IbvService: Ibv,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private rutaActiva: ActivatedRoute,
    public googleAnalyticsService: GoogleAnalyticsService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private auditory: AuditoryService,
    private inveriteService: InveriteService,
    private timeStadisticService: TimeStadisticService,
    private reasonService: ReasonsService
    ) { }

    //JSON.parse(localStorage.getItem('client_id'));

  ngOnInit() {
    // this.mousemove();
    this.next_page = 1;
    // if(this.clientService.getDatatoSearchrequest("Yes.")!=null){
    //   // this.allclient=this.clientService.getDatatoSearchrequest("Yes.")
    //   this.searchLoad=true;
    //   // this.clientService.getAllClientSearchRequest("Yes.").subscribe(data=>{

    //   //   for(var i=0 ; i < Object.keys(data).length ; i++){
    //   //     data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
    //   //   }
    //   //   this.allclient=data
    //   //   this.allclient.filter(resp=>{
    //   //     resp.first_name=resp.first_name+" "+resp.last_name;
    //   //   })
    //   //   console.log(this.allclient,"allrequest")
    //   //   this.clientService.setDatatoSearchrequest("Yes.",this.allclient);
    //   //   this.searchLoad=true;
    //   // })
    // }else{
    //   this.clientService.getAllClientSearchRequest("Yes.").subscribe(data=>{

    //     for(var i=0 ; i < Object.keys(data).length ; i++){
    //       data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
    //     }
    //     this.allclient=data
    //     this.allclient.filter(resp=>{
    //       resp.first_name=resp.first_name+" "+resp.last_name;
    //     })
    //     // console.log(this.allclient,"allrequest")
    //     this.clientService.setDatatoSearchrequest("Yes.",this.allclient);
    //     this.searchLoad=true;
    //   })
    // }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      itemsShowLimit: 10,
      allowSearchFilter: false
    };

    this.logs=[];
    this.id=this.rutaActiva.snapshot.params.id;
    this.goBacks=localStorage.getItem('goBack');
    this.goBackStatus=localStorage.getItem('goBackStatus');
    this.goBackStatus = this.goBackStatus == '0' ? null : this.goBackStatus;
    // console.log(this.rutaActiva.snapshot.params.screen,this.goBackStatus,"asdasdasdasdasdasdasdasdhhhhh------------------------uuuuu");
    if(this.rutaActiva.snapshot.params.screen==null){
      clearInterval(this.button_token);
            this.button_token=null
            clearInterval(this.myDeactive);
            this.myDeactive=null;
      // console.log("entre aqui")
      this.message = '';
      if(this.setStatus=this.rutaActiva.snapshot.params.status==null){
        // console.log("entre aqui 2")

        if(this.goBackStatus!=""){
        // console.log("entre aqui 3")

          if(this.goBackStatus!=null){
        // console.log("entre aqui 5")

            this.getClientStatus(this.goBackStatus);
            this.setStatus=this.goBackStatus;
          }else{
            // console.log("entre aqui 4")

            this.getClients();
            this.getStatus();



            this.goBacks=localStorage.getItem('goBack');
          }
        }else{
          this.getClients();
          this.getStatus();
        this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/requests', 'Follow User', 'userLabel', 1);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
        this.goBacks=localStorage.getItem('goBack');
        }
        this.setStatus=0;
      }else{
        this.setStatus=this.rutaActiva.snapshot.params.status
          // console.log(this.setStatus,"xxxxx")
          if(this.setStatus!="all"){
          this.goBacks=localStorage.getItem('goBack');
          this.getClientStatusType(this.setStatus,this.rutaActiva.snapshot.params.type);
        }else{
          // console.log(this.setStatus,"yyy")
          this.setStatus=0;
          this.goBacks=localStorage.getItem('goBack');
          this.page=1;
          this.getClientsDates(this.rutaActiva.snapshot.params.type);
        }
      }
      this.getStatus();
      this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/requests', 'Follow User', 'userLabel', 1);
      this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
      // console.log(window.location.hostname,"request")
    }else{
      if(this.rutaActiva.snapshot.params.screen==1){
        this.Show(this.rutaActiva.snapshot.params.screen,this.rutaActiva.snapshot.params.id)
        this.getStatus();
      }else{
        this.message = '';
        // this.getClients();
        this.getClientStatus(this.setStatus);
        this.getStatus();
        this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/requests', 'Follow User', 'userLabel', 1);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
      }
    }

    if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
      this.urls="https://sandbox.inverite.com/merchant/request/view/";
      // console.log(this.urls)
    }else{
      this.urls="https://inverite.com/merchant/request/view/";
      // console.log(this.urls)
    }

    const user = this.tokenStorageService.getUser();

    // console.log(this.username+' - '+this.idUser,'data usuario');
    this.roles = user.roles;
    this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
    this.showModeratorBoard = this.roles.includes('ROLE_UNDERWRITER');
    this.showCustomerServiceBoard = this.roles.includes('ROLE_CUSTOMER SERVICE');
    // console.log(this.router.url,"xxssxsx")
    let rout=this.router.url.split("/");
    // console.log(rout,"asdasdsadadsszzzzzzzzzzzzzzzzzzzzzzz");
    if(rout[1]=="inverite"){
      this.ibv=rout[1];
      this.data=rout[3];
      this.Id=rout[4];
      this.showComponent(8)
    }
    if(rout[1]=="flink"){
      this.ibv=rout[1];
      this.data=rout[3];
      this.Id=rout[4];
      this.showComponent(9)
    }
  }

  timeLeft: number = 60;
  interval;

// startTimer() {
//     this.interval = setInterval(() => {
//       if(this.timeLeft > 0) {
//         this.timeLeft--;
//       } else {
//         this.timeLeft = 60;
//       }
//     },1000)
//   }

//   pauseTimer() {
//     clearInterval(this.interval);
//   }


  cronometrar(){
    this.write();
    this.button_token = setInterval(() => {
      this.write();
    },1000);

    this.countdown();

    this.myDeactive = setInterval(() => {
      this.countdown();
    },1000);

  }

  countdown(){
    let hAux, mAux, sAux;
    this.seconds_return++;
    if (this.seconds_return>59){this.minutes_return++;this.seconds_return=0;}
    if (this.minutes_return>59){this.hour_return++;this.minutes_return=0;}
    if (this.hour_return>24){this.hour_return=0;}

    if (this.seconds_return<10){sAux="0"+this.seconds_return;}else{sAux=this.seconds_return;}
    if (this.minutes_return<10){mAux="0"+this.minutes_return;}else{mAux=this.minutes_return;}
    if (this.hour_return<10){hAux="0"+this.hour_return;}else{hAux=this.hour_return;}

    if(this.minutes_return == 30){
      // this.stop_cronometro();
    }

    // console.log("La regresiva es: "+hAux + ":" + mAux + ":" + sAux);
  }

  write(){

    let hAux, mAux, sAux;
    this.seconds++;
    if (this.seconds>59){this.minutes++;this.seconds=0;}
    if (this.minutes>59){this.hour++;this.minutes=0;}
    if (this.hour>24){this.hour=0;}

    if (this.seconds<10){sAux="0"+this.seconds;}else{sAux=this.seconds;}
    if (this.minutes<10){mAux="0"+this.minutes;}else{mAux=this.minutes;}
    if (this.hour<10){hAux="0"+this.hour;}else{hAux=this.hour;}

    this.time_view_file = hAux + ":" + mAux + ":" + sAux;
    // console.log("La avanzada es: "+hAux + ":" + mAux + ":" + sAux);
  }

  stop_cronometro(){
    clearInterval(this.button_token);
    this.button_token=null;
    clearInterval(this.myDeactive);
    this.myDeactive=null;
    this.setStatusSeeing();
  }


  open(content) {
    this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.modal.result.then((e) => {

    });

  }
  getClients() {
    const user = this.tokenStorageService.getUser();
    this.username = user.username;
    this.idUser = user.id;
    this.load=false
    if(this.clientService.getClientsSetted()!=null&&this.band==false){
      this.list_clients=this.clientService.getClientsSetted();
      this.load=true
      this.clientService.getClients(this.next_page)
      .subscribe(
        data_ => {
          let data = data_['data'];
          if(data_['nextPage']!=null){
            this.next_page = data_['nextPage'];
          }else{
            this.hiddenLoadMore = true;
          }
          // console.log(data)
          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }
          if(this.page==1){
             this.list_clients = data;
             this.list_clients.filter(resp=>{
              resp.first_name=resp.first_name+" "+resp.last_name;
            })
          }
          else{
            let aux=[];
            aux.push(data)
            // console.log(aux[0].length)
            if(aux[0].length==0){
              this.hiddenLoadMore=true;
            }
            aux[0].filter(resp=>{
              resp.first_name=resp.first_name+" "+resp.last_name;
              this.list_clients.push(resp)
            })
            // console.log(aux,"auxxxxx");
          }
          // console.log(this.list_clients,"list_clients")

          // this.allclient=this.list_clients;
          // this.clientService.setClients(this.allclient);
      // console.log(window.location.hostname)
      if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
        this.urls="https://sandbox.inverite.com/merchant/request/view/";
        // console.log(this.urls)
      }else{
        this.urls="https://inverite.com/merchant/request/view/";
      }
        this.load=true
        this.clientService.setClients(this.list_clients);
        this.band=false
        },
        error => {
          console.log(error);
        });

    }else{
    //  console.log('la de buscar',this.page, this.next_page);
    this.clientService.getClients(this.next_page)
      .subscribe(
        data_ => {
          let data = data_['data']
          this.next_page = data_['nextPage'];
          // console.log('toda la data', data)
          // console.log('siquiente pagina ',this.next_page)
          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }
          if(this.page==1){
             this.list_clients = data;
             this.list_clients.filter(resp=>{
              resp.first_name=resp.first_name+" "+resp.last_name;
            })
          }
          else{
            let aux=[];
            aux.push(data)
            // console.log(aux[0].length)
            if(aux[0].length==0){
              this.hiddenLoadMore=true;
            }
            aux[0].filter(resp=>{
              resp.first_name=resp.first_name+" "+resp.last_name;
              this.list_clients.push(resp)
            })
            // console.log(aux,"auxxxxx");
          }
          // console.log(this.list_clients,"list_clients")

          // this.allclient=this.list_clients;
          // this.clientService.setClients(this.allclient);
      // console.log(window.location.hostname)
      if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
        this.urls="https://sandbox.inverite.com/merchant/request/view/";
        // console.log(this.urls)
      }else{
        this.urls="https://inverite.com/merchant/request/view/";
      }
        this.load=true
        this.clientService.setClients(this.list_clients);
        this.band=false
        },
        error => {
          console.log(error);
        });
    }
  }
  loadMore(page,status=null){
    // console.log(status,this.rutaActiva.snapshot.params.type,"sadasdasdasdasdasdadasdsadasdasdasdsadad")
    this.hiddenLoadMore=false;
    this.page++;
    this.band=true;

    if(this.setStatus!=0&&this.rutaActiva.snapshot.params.type!=null)
      this.getClientStatusType(this.setStatus,this.rutaActiva.snapshot.params.type);
    else
      if(status==null){
        this.getClients()
      }
      else
        this.getClientStatus(status);
  }
  getClientsDates(type) {
    this.load=false
    // console.log(type,"type")
    this.clientService.getClientsDate(type,this.page)
      .subscribe(
        data => {

          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }
          if(this.page==1){
            this.list_clients = data;
            this.list_clients.filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
           })
         }
         else{
           let aux=[];
           aux.push(data)
          //  console.log(aux[0].length)
           if(aux[0].length==0){
             this.hiddenLoadMore=true;
           }
           aux[0].filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
             this.list_clients.push(resp)
           })
          }

          this.clientService.setClientStatusTypeSetted(this.allclient)
      // console.log(window.location.hostname)
      if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
        this.urls="https://sandbox.inverite.com/merchant/request/view/";
        // console.log(this.urls)
      }else{
        this.urls="https://inverite.com/merchant/request/view/";
      }
          this.load=true
        },
        error => {
          console.log(error);
        });

  }
  showDate(){
    if(this.date == false){
      this.date = true;
    }else{
      this.date = false;
    }
  }

  onValueChange(value: Date): void {

    (document.getElementById("shared") as HTMLInputElement).value = ""+moment(''+value).format('DD/MM/YYYY');
    this.date = false;
    // console.log(value)
    this.clientService.filterClients(""+value, this.setStatus)
        .subscribe(
          data => {

            for(var i=0 ; i < Object.keys(data).length ; i++){
              data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
            }

            this.list_clients = data;
          this.list_clients.filter(resp=>{
            resp.first_name=resp.first_name+" "+resp.last_name;
          })
          // this.allclient=this.list_clients;
            //  console.log(data);
          },
          error => {
            console.log(error);
          }
      );
  }

  ItemsCant($event){
    this.numberItems = parseInt(((document.getElementById("numitems") as HTMLInputElement).value));
  }

  getClientStatus(id) {
  this.load=false

  this.hiddenLoadMore=false;

    // alert();
    this.clientService.getClientStatus(id,this.page)
      .subscribe(
        data_ => {
          let data = data_['data'];

          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }

          if(this.page==1){
            this.list_clients = data;
            this.list_clients.filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
           })
         }
         else{
           let aux=[];
           aux.push(data)
          //  console.log(aux[0].length)
           if(aux[0].length==0){
             this.hiddenLoadMore=true;
           }
           aux[0].filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
             this.list_clients.push(resp)
           })
          //  console.log(aux,"auxxxxx");

         }
          // this.allclient=this.list_clients;
          // console.log(data);
          this.load=true
        },
        error => {
          console.log(error);
        });
  }
  getClientStatusType(id,type) {
    this.load=false

    // console.log(type)
      // console.log(type,"asdasdasdasdasdasdasdasdsadasdasdasdasdasdasdsadsadasdasdsadsadsad")

        this.clientService.getClientStatusType(id,type,this.page).subscribe(
          data => {

            for(var i=0 ; i < Object.keys(data).length ; i++){
              data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
            }

            if(this.page==1){
              this.list_clients = data;
              this.list_clients.filter(resp=>{
               resp.first_name=resp.first_name+" "+resp.last_name;
             })
           }
           else{
             let aux=[];
             aux.push(data)
            //  console.log(aux[0].length)
             if(aux[0].length==0){
               this.hiddenLoadMore=true;
             }
             aux[0].filter(resp=>{
               resp.first_name=resp.first_name+" "+resp.last_name;
               this.list_clients.push(resp)
             })
            //  console.log(aux,"auxxxxx");
            }
            // this.allclient=this.list_clients;
            this.clientService.setClientStatusType(this.allclient);
            this.load=true
            // console.log(data);
          },
          error => {
            console.log(error);
          });


  }
  clearSearch(){
    this.search="";
    this.onInputChanged("");
  }
  onInputChanged(event){
    const name = this.search
    this.list_clients=this.allclient;
    // this.list_clients = this.list_clients.filter((item) => {

    //   // createdAt
    //     return item.first_name.toLowerCase().includes(name.toLowerCase()) || item.email.toLowerCase().includes(name.toLowerCase()) || item.statu.description.toLowerCase().includes(name.toLowerCase()) || item.cell_phone_number.toLowerCase().includes(name.toLowerCase()) || item.createdAt.toLowerCase().includes(name.toLowerCase())

    // });


    if(name == ""){
      if(this.setStatus > 0){
        this.getClientStatus(this.setStatus);
      }else{
        this.next_page = 1;
        this.getClients();
      }
    }
    else{
    this.clientService.filterClients(name, this.setStatus)
        .subscribe(
          data_ => {
            let data = data_['data'];
            // console.log(data,"searching")
            for(var i=0 ; i < Object.keys(data).length ; i++){
              data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
              data[i].first_name = data[i].first_name+" "+data[i].last_name;
            }

            this.list_clients = data;
            this.list_clients = this.list_clients.filter((item) => {

              // createdAt
                return item.first_name.toLowerCase().includes(name.toLowerCase()) || item.email.toLowerCase().includes(name.toLowerCase()) || item.statu.description.toLowerCase().includes(name.toLowerCase()) || item.cell_phone_number.toLowerCase().includes(name.toLowerCase()) || item.createdAt.toLowerCase().includes(name.toLowerCase())

            });
            // console.log(data);
          },
          error => {
            console.log(error);
          }
      );
    }
  }

  public onOptionsSelected(event) {
    const value = event.target.value;
    // console.log(value);
    this.page=1;
    if(value == 0){
      this.getClients();
    } else {
      this.getClientStatus(value);
    }
  }

  getStatus(){
    if(this.statuService.getStatusSetted()!=null){
      this.list_status=this.statuService.getStatusSetted();
    }else{
    this.statuService.getStatus()
      .subscribe(
        data => {
           this.list_status = data;
          //  console.log(data);
          this.statuService.setStatus(data);
        },
        error => {
          console.log(error);
        });
    }
  }

  setStatusSeeing(){
    // const user = this.tokenStorageService.getUser();

    // if(this.button_token != null || this.myDeactive != null){
    //   // console.log("es diferente de null");
    //   this.stop_cronometro();
    // }

    // let data_time = {
    //   userId: user.id,
    //   nameUser: user.username,
    //   clientId: this.userIbv.id,
    //   action: 'Check request',
    //   status: this.userShow.statu.description,
    //   new_status: this.new_status,
    //   time_user: this.time_view_file,
    //   id: this.time_id
    // };
    // this.timeStadisticService.updateTimeStadistic(data_time)
    // .subscribe(data_tim => {
    //   console.log('save data');
    // });
    this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
      // window.location.href=this.goBacks;
      this.router.navigateByUrl(this.goBacks);
    });
  }

  Show(value,user_id){
    this.user_id = user_id;

    // console.log(this.user_id,"Show()")
    if(value == 3){
      //this.deleteUser(user_id);
    } else if(user_id != 0){
      this.clientService.getStatusSeeing(user_id).subscribe(user => {
        this.user_view = user;
        if(this.user_view.seeing == 0){
          this.getClientIbv(user_id);
          this.clientService.getClient(user_id)
            .subscribe(
              dataLoanCLient => {
                this.dataLoan = dataLoanCLient;
                let data = this.dataLoan.client;

                let reason_array = Array();

                this.dataLoan.reason.forEach(el => {
                  reason_array.push({ item_id: el.reason_id, item_text: el.description });
                });

                // console.log(this.dataLoan,"Showssssss");
                if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
                  this.urls="https://sandbox.inverite.com/merchant/request/view/";
                }else{
                  this.urls="https://inverite.com/merchant/request/view/";
                }
                this.urldetails=this.urls+data["guid"];
                this.data=data["guid"];
                // console.log(this.urldetails);
                data['employer_phone_name'] = "("+data['employer_phone_name'].substring(0, 3)+")"+data['employer_phone_name'].substring(3, 6)+"-"+data['employer_phone_name'].substring(6, 10);

                if(data['landline_number'] != null && data['landline_number'] != ""){
                  data['landline_number'] = "("+data['landline_number'].substring(0, 3)+")"+data['landline_number'].substring(3, 6)+"-"+data['landline_number'].substring(6, 10);
                } else {
                  data['landline_number'] = "-";
                }

                this.userShow = data;

                const user = this.tokenStorageService.getUser();
                let data_time = {
                  userId: user.id,
                  nameUser: user.username,
                  clientId: this.userShow.id,
                  action: 'Check request',
                  status: this.userShow.statu.description,
                  new_status: null,
                  time_user: null
                };
                this.getBorrower(this.userShow.email);
                this.new_status = this.userShow.statu.description;

                // this.timeStadisticService.createTimeStadistic(data_time)
                // .subscribe(time => {
                //   this.time_id = time['id'];
                //   console.log('el cronometro va a comenzar'+this.time_id);
                // });
                  // this.cronometrar();

                for(var i = 0; i < data['question_answers'].length; i++){
                  if(data['question_answers'][i]['question_id'] == 5){
                    var amount = data['question_answers'][i]['amount'];
                  }
                }

                this.request = this.fb.group({
                  user_id: [data['id'],Validators.required],
                  cell_phone_number: [data['cell_phone_number'],Validators.required],
                  amount: [amount,Validators.required],
                  login_id: [data['login_id'],Validators.required],
                  status: [data['status_id'],Validators.required],
                  reason: [reason_array],
                  conditions: [null],
                  description: [data['description'],Validators.required],
                  health_card: [data['health_card'],Validators.required],
                  driving_license: [data['driving_license'],Validators.required],
                  social_insurance: [data['social_insurance'],Validators.required],
                  identification_ID: [data['identification_ID'],Validators.required],
                });

                if(data['login_id'] != null) {
                  this.request.controls['login_id'].disable();
                } else {
                  this.request.controls['login_id'].enable();
                }

                if(data['status_id'] == 15){
                  this.getReason(data['status_id']);
                  this.request.controls['description'].disable();
                  this.request.controls['health_card'].disable();
                  this.request.controls['driving_license'].disable();
                  this.request.controls['social_insurance'].disable();
                  this.request.controls['identification_ID'].disable();
                } else {
                  this.request.controls['description'].enable();
                  this.request.controls['health_card'].enable();
                  this.request.controls['driving_license'].enable();
                  this.request.controls['social_insurance'].enable();
                  this.request.controls['identification_ID'].enable();
                }

                this.clientService.setStatusSeeing(user_id, 1).subscribe(data_st => {

                });

              this.showComponent(value);

              },
              error => {
                console.log(error);
          });
        }else{
          this.router.navigate([this.goBacks]);
        }
      });


    } else {

      this.showComponent(value);
    }

  }

  showComponent(value){
    for (var i = 0; i < this.showNav.length; i++) {

      if(i == value){
        this.showNav[i] = true;
      } else {
        this.showNav[i] = false;
      }

    }
  }

  getClientIbv(id) {
    // console.log("get client");
    this.IbvService.getClient(id)
      .subscribe(
        data => {
          this.userIbv = data;
          console.log(this.userIbv,"userIbv")


          // this.inveriteService.getIPAddress().subscribe((res:any)=>{
          //   this.ipAddress=res.ip;
          //   console.log(this.ipAddress)
          //   let datas = {nameUser: user.username,action: 'View user details',status: this.userShow.statu.description,userId: user.id,clientId: this.userIbv.id, nameClient : this.userIbv.first_name, email: this.userIbv.email, ip_address: this.ipAddress, if_renewal: this.userIbv.if_renewal};
          //   console.log(this.userIbv, 'data client');

          //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
          //     console.log(data);
          //   });


          // },err=>{
          //   console.log(err)

          //   this.ipAddress="190.36.186.3";
          //   console.log(this.ipAddress)
          //   let datas = {nameUser: user.username,action: 'View user details',status: this.userShow.statu.description,userId: user.id,clientId: this.userIbv.id, nameClient : this.userIbv.first_name, email: this.userIbv.email, ip_address: this.ipAddress, if_renewal: this.userIbv.if_renewal};
          //   console.log(this.userIbv, 'data client');

          //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
          //     console.log(data);
          //   });


          // });
          // console.log(this.userIbv);
          if(data['ibv_client'] == null){
            this.existIbv = false;
          } else{
            this.existIbv = true;

              data['ibv_client']['phone'] = data['ibv_client']['phone'].replace("(","");
              data['ibv_client']['phone'] = data['ibv_client']['phone'].replace(")","");
              data['ibv_client']['phone'] = data['ibv_client']['phone'].replace("-","");
              data['ibv_client']['phone'] = data['ibv_client']['phone'].replace(" ","");

          }
           //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  isEquals(value_a, value_b){
    if((value_a && value_b) && (value_a.toUpperCase() == value_b.toUpperCase())){
      return true;
    }
    return false;
  }

  viewHistory(id){
    this.showComponent(3);
    this.clientService.getHistory(id)
      .subscribe(
        data => {
          var size = Object.keys(data).length;
          if(size==1){
            this.userHistory = data;
          }else{
            console.log(data)
            // var question_ = JSON.parse(data[10]['question']['question']);

            // if(data[10]['answer']['id'] == 43 || data[10]['answer']['id'] == 44){
            //   data[10]['question']['question'] = question_[0];
            // } else {
            //   data[10]['question']['question'] = question_[1];
            // }

            this.userHistory = data;
            // console.log(data);
          }
        },
        error => {
          console.log(error)
        });
  }

  viewDetailsBank(id,content){

    this.open(content);

    this.IbvService.getDetailsAccount(id)
      .subscribe(
        data => {

          for(var i=0; i< data[0].ibv_accounts.length; i++){
            for(var j=0; j< data[0].ibv_accounts[i].ibv_transactions.length; j++){
              data[0].ibv_accounts[i].ibv_transactions[j].flags = JSON.parse(data[0].ibv_accounts[i].ibv_transactions[j].flags);
            }
            for(var j=0; j< data[0].ibv_accounts[i].ibv_statistics.length; j++){
                data[0].ibv_accounts[i].ibv_statistics[j].value = JSON.parse(data[0].ibv_accounts[i].ibv_statistics[j].value);
            }
          }
          this.userIbvDetails = data[0];
          // console.log(this.userIbvDetails);
          //console.log(this.userShow);
          this.showComponent(4);

          this.modal.close();
        },
        error => {
          console.log(error);
        }
      );
  }

  reloadPage(){
    location.reload();
  }

  verifyStatus(){
    this.dropdown = false;
    // this.reasons.reasons = [];
    this.dropdownList = [];

    if(parseInt( this.request.value.status) === 15 /*|| parseInt(this.client_form.value.status) === 6*/){
      this.getReason(parseInt( this.request.value.status));
    }else{
      this.request.controls['reason'].clearValidators();
      this.request.controls['reason'].updateValueAndValidity();
    }

    // if(parseInt(this.request.value.status) !== 6){
    //   this.request.controls['amount'].clearValidators();
    //   this.request.controls['amount'].updateValueAndValidity();
    // }else{
    //   this.request.controls['amount'].setValidators(Validators.required);
    //   this.request.controls['amount'].updateValueAndValidity();
    // }
  }

  getReason(status_id){
    console.log(status_id)
    this.reasonService.getReason(status_id)
      .subscribe(res => {
        this.reasons = res;

        this.reasons.reasons.forEach(el => {
          this.dropdownList.push({ item_id: el.id, item_text: el.description });
        });

        this.dropdown = this.reasons.ok;

        this.request.controls['reason'].setValidators(Validators.required);
        this.request.controls['reason'].updateValueAndValidity();

        console.log(this.reasons.reasons.length, this.dropdownList, )
      });
  }

  fieldIsInvalid(option){

  }

  verifyOptions(){
    let notes = Array();

    // console.log(this.client_form.value.reason);

    for (let i = 0; i < this.request.value.reason.length; i++) {
      for (let j = 0; j < this.dropdownList.length; j++) {
        if(this.dropdownList[j].item_text === this.request.value.reason[i].item_text){
          notes.push(this.dropdownList[j].item_text);
        }
      }
    }

    this.request.controls['description'].setValue(notes.join('\n'))
  }

  searchStatus(id){
    // let id_status = parseInt(((document.getElementById("status") as HTMLInputElement).value));

    for(let i = 0; i < this.list_status.length; i++){
      if(this.list_status[i].id == id){
        this.new_status = this.list_status[i].description;
      }
    }
  }

  saveDataRequest(form){
    // console.log(this.userShow);
    this.old_status = this.userShow.statu.description;
    this.searchStatus(form.status);

    var data = { cell_phone_number: form.cell_phone_number,
                 status_id: form.status,
                 description: form.description,
                 health_card: form.health_card,
                 driving_license: form.driving_license,
                 social_insurance: form.social_insurance,
                 identification_ID: form.identification_ID,
                 reasons: JSON.stringify({ reasons: form.reason}),
                guid:form.login_id };
    var dataSend = { cell_phone_number: form.cell_phone_number,
                 status_id: form.status,
                 description: form.description,
                 health_card: form.health_card,
                 driving_license: form.driving_license,
                 social_insurance: form.social_insurance,
                 identification_ID: form.identification_ID,
                 guid:form.login_id
                };

                this.urldetails=this.urls+form.login_id
                //  console.log(data);return "";


    var amount = {amount: form.amount};

    var date_ = new Date(this.userShow.date_birth);
    var day, month, borrower_landline;

    for (let i = 0; i < this.list_status.length; i++) {
      if (this.list_status[i].id == form.status) {
        this.userShow.statu.description = this.list_status[i].description;
      }
    }

    // return '';

    if(date_.getMonth() < 10){
      month = date_.getMonth() + 1;
      month = "0" + month;
    } else {
      month = date_.getMonth() + 1;
    }

    if(date_.getDate() < 10){
      day = "0"+date_.getDate();
    } else {
      day = date_.getDate();
    }

    if(this.userShow.landline_number != null){
      borrower_landline = this.userShow.landline_number.replace("(","");
      borrower_landline = borrower_landline.replace(")","");
      borrower_landline = borrower_landline.replace("-","");
      borrower_landline = borrower_landline.replace(/_/g,"");
    } else {
      borrower_landline = '';
    }
    // console.log(name_status, 'nombre del status');

    const user = this.tokenStorageService.getUser();

      this.ipAddress = localStorage.getItem('ip') || "190.36.186.3";
      let datas = {nameUser: user.username,action: 'Update status in request',status: this.old_status, new_status: this.new_status, userId: user.id,clientId: this.userIbv.id, nameClient : this.userIbv.first_name, email: this.userIbv.email, ip_address: this.ipAddress, if_renewal: this.userIbv.if_renewal,updated_file: 1};

      this.auditory.createRegisterAuditory(datas).subscribe(data => {
        // console.log(data);
      });

    if(this.userShow.borrower_id && form.status != 15){
      this.branchService.selectedBranch()
        .subscribe(
          data => {
            this.loandiskService.getBorrower(this.userShow.borrower_id,data[0]['value'])
              .subscribe(
                borrower_user => {
                  this.borrower_data = borrower_user;
                  this.borrower_data = this.borrower_data.response.Results[0];
                  // console.log(this.borrower_data, 'el borrower del usuario');
                  let data_borrower = {
                    borrower_id: this.borrower_data.borrower_id,
                    borrower_unique_number: this.borrower_data.borrower_unique_number,
                    borrower_firstname: this.borrower_data.borrower_firstname,
                    borrower_lastname: this.borrower_data.borrower_lastname,
                    borrower_country: this.borrower_data.borrower_country,
                    borrower_gender: this.borrower_data.borrower_gender,
                    borrower_mobile: this.borrower_data.borrower_mobile,
                    borrower_email: this.borrower_data.borrower_email,
                    borrower_address: this.borrower_data.borrower_address,
                    borrower_city: this.borrower_data.borrower_city,
                    borrower_province: this.borrower_data.borrower_province,
                    borrower_zipcode: this.borrower_data.borrower_zipcode,
                    borrower_landline: this.borrower_data.borrower_landline,
                    custom_field_5703: this.borrower_data.custom_field_5703,
                    custom_field_5690: trim(form.health_card) ? form.health_card: this.borrower_data.custom_field_5690,//Health card / Assurance Maladie
                    custom_field_5691: trim(form.driving_license) ? form.driving_license: this.borrower_data.custom_field_5691,//Driving License
                    custom_field_5692: trim(form.social_insurance) ? form.social_insurance: this.borrower_data.custom_field_5692,//SIN
                    custom_field_5693: trim(form.identification_ID) ? form.identification_ID: this.borrower_data.custom_field_5693,//Health card / Assurance Maladie
                    custom_field_5934: this.borrower_data.custom_field_5934,
                    custom_field_5936: this.borrower_data.custom_field_5936,
                    custom_field_5935: this.borrower_data.custom_field_5935,
                    custom_field_5937: this.borrower_data.custom_field_5937,
                    custom_field_6370: this.borrower_data.custom_field_6370,
                    custom_field_8611: this.borrower_data.custom_field_8611,
                    branch_id: data[0]['value'],
                    borrower_business_name: this.borrower_data.borrower_business_name,
                    borrower_credit_score: this.borrower_data.borrower_credit_score,
                    borrower_description: this.borrower_data.borrower_description,
                    borrower_dob: this.borrower_data.borrower_dob,
                    borrower_fullname: this.borrower_data.borrower_fullname,
                    borrower_title: this.borrower_data.borrower_title,
                    borrower_working_status: this.borrower_data.borrower_working_status,
                    loan_other: this.dataLoan.loan
                  };

                  // console.log(data_borrower,"data_borrower", form);

                  // this.loandiskService.updateBorrower(data_borrower)
                  //   .subscribe(
                  //     data => {
                  //     //console.log(data);

                  //     },
                  //     error => {
                  //       console.log(error);
                  //     }
                  // );
                }
              );

          },
          error => {
            console.log(error);
          }
        );
    }
    // console.log(data,"update")

    this.clientService.updateClient(form.user_id,data)
      .subscribe(
        data => {
          // console.log(data);
          this.questionAnswerService.updateAmount(form.user_id,amount)
            .subscribe(
              data => {
                // console.log(data);

                this.clientService.getClient(form.user_id)
                  .subscribe(
                    data => {
                      //console.log(data);
                      // this.userShow = data;

                      this.toastrService.success('Client successfully updated.', 'Success', {
                        timeOut: 5000
                      });

                      if(form.status == 15){
                        this.request.controls['description'].disable();
                        this.request.controls['health_card'].disable();
                        this.request.controls['driving_license'].disable();
                        this.request.controls['social_insurance'].disable();
                        this.request.controls['identification_ID'].disable();
                      } else {
                        this.request.controls['description'].enable();
                        this.request.controls['health_card'].enable();
                        this.request.controls['driving_license'].enable();
                        this.request.controls['social_insurance'].enable();
                        this.request.controls['identification_ID'].enable();
                      }
                    },
                    error => {
                      // console.log(error);
                });

              },
              error => {
                console.log(error);
              });
              if(dataSend.status_id==6){
                let data_2 = {
                  mail_to:this.userShow.email,
                  name_to:this.userShow.first_name,
                  subject:`${this.userShow.first_name},You have been approved`,
                  textContent:"...",
                  id: this.userShow.id,
                  template: 'approved'
                };
                // console.log(data_2);
                this.MailService.sendEmail(data_2)
                      .subscribe(
                        data => {
                          // console.log("enviado");
                          this.toastrService.success('Have been sent an email to: '+this.userShow.email, 'Success', {
                            timeOut: 5000
                          });

                        },
                        error => {
                          console.log(error.message);
                          this.toastrService.error(`Have not been sent an email to: ${this.userShow.email}. Please click again in 'SAVE' button`, 'Error', {
                            timeOut: 5000
                          });
                        }
                      );
              }
              if(dataSend.status_id==15){
                let data_2 = {
                  mail_to:this.userShow.email,
                  name_to:this.userShow.first_name,
                  subject:`${this.userShow.first_name}, Your request has been refused.`,
                  textContent:"...",
                  id: this.userShow.id,
                  template: 'refusal'
                };
                // console.log(data_2);
                this.MailService.sendEmail(data_2)
                      .subscribe(
                        data => {
                          this.toastrService.success('Have been sent an email to: '+this.userShow.email, 'Success', {
                            timeOut: 5000
                          });

                        },
                        error => {
                          // this.toastrService.error('Have not been sent an email to: '+this.userShow.email+". Please click again in 'SAVE' button.', 'Error', {
                          //   timeOut: 5000
                          // });

                          this.toastrService.error(`Have not been sent an email to: ${this.userShow.email}. Please click again in 'SAVE' button`, 'Error', {
                            timeOut: 5000
                          });
                        }
                      );
              }
        },
        error => {
          console.log(error);
        });


  }

  async createLoan(content){

  // alert()
    this.open(content);
    this.branchService.selectedBranch()
      .subscribe(
        data => {

          if(this.userShow.status_id == 15){
            this.toastrService.error('Client rejected.', 'Error', {
              timeOut: 5000
            });
            this.modal.close();
          } else if(this.userShow.health_card != '' || this.userShow.driving_license != '' || this.userShow.social_insurance != '' || this.userShow.identification_ID != '' ){
            this.createBorrower(data[0]['value']);
          } else {
            this.toastrService.info("Health card, Driver's license, Social security, Identification ID.", 'You must complete at least one of the fields to generate the loan.', {
              timeOut: 5000
            });

            this.modal.close();
          }
          this.showComponent(7);

        },
        error => {
          console.log(error);
        }
      );

  }

  async createBorrower (branch_id){
    this.userShow.branch_id = branch_id;
    this.userShow.borrower_id_client= this.borrower_id_user;
    // console.log(this.userShow,"createBorrowerxxxxx")
    // console.log(this.userIbv);
    this.loan_pack.push(this.userShow);
    this.loan_pack.push(this.userIbv);
    this.clientService.getLoanID(this.userShow.id)
      .subscribe(
        data => {
          // console.log(data,"getLoan");

          if(Object.keys(data).length > 0){
            this.toastrService.info('The loan has already been created.', 'Info', {
              timeOut: 5000
            });
            this.modal.close();
          } else {

              var date_ = new Date(this.userShow.date_birth);
              var day, month, borrower_landline;

              if(date_.getMonth() < 10){
                month = date_.getMonth() + 1;
                month = "0" + month;
              } else {
                month = date_.getMonth() + 1;
              }

              if(date_.getDate() < 10){
                day = "0"+date_.getDate();
              } else {
                day = date_.getDate();
              }

              if(this.userShow.landline_number != null){
                borrower_landline = this.userShow.landline_number.replace("(","");
                borrower_landline = borrower_landline.replace(")","");
                borrower_landline = borrower_landline.replace("-","");
                borrower_landline = borrower_landline.replace(/_/g,"");
              } else {
                borrower_landline = '';
              }

              let data_borrower = {
                  borrower_unique_number: 'CID00000'+this.userShow.id,
                  borrower_firstname: this.userShow.first_name,
                  borrower_lastname: this.userShow.last_name,
                  borrower_country: "CA",
                  borrower_gender: (this.userShow.gender == "female") ? "Female" : "Male",
                  borrower_mobile: this.userShow.cell_phone_number,
                  borrower_email: this.userShow.email,
                  borrower_address: this.userShow.address,
                  borrower_city: this.userShow.city_id,
                  borrower_province: this.userShow.province.description,
                  borrower_zipcode: this.userShow.postal_code,
                  borrower_landline: borrower_landline,
                  custom_field_5703: "Canada",
                  custom_field_5690: this.userShow.health_card,//Health card / Assurance Maladie
                  custom_field_5691: this.userShow.driving_license,//Driving License
                  custom_field_5692: this.userShow.social_insurance,//SIN
                  custom_field_5693: this.userShow.identification_ID,//Health card / Assurance Maladie
                  custom_field_6370: this.userShow.employer_phone_name,
                  branch_id: branch_id,
                  client_id: this.userIbv.id,
                  loan_other: this.dataLoan.loan
                };
                // console.log(data_borrower)
                this.borrower=data_borrower;
                this.modal.close();

                // this.loandiskService.createBorrower(data_borrower)
                //   .subscribe(
                //     data => {
                //       console.log(data);
                //       this.borrower_id = data;
                //       this.userShow.borrower_id = this.borrower_id.response.borrower_id;
                //       this.createLoanApi(this.borrower_id.response.borrower_id,branch_id);
                //     },
                //     error => {
                //       console.log(error);
                //     }
                //   );
          }
        },
        error => {
          console.log(error);
        }
    );

  }
  back(x){
    this.getClients();
    this.showComponent(x);
  }
  async createLoanApi (borrower_id,branch_id){

    //console.log(this.userShow);
    //console.log(this.userIbv);

    var loan_released_date = new Date();
    var day, month;

    if(loan_released_date.getMonth() < 10){
      month = loan_released_date.getMonth() + 1;
      month = "0" + month;
    } else {
      month = loan_released_date.getMonth() + 1;
    }

    if(loan_released_date.getDate() < 10){
      day = "0"+loan_released_date.getDate();
    } else {
      day = loan_released_date.getDate();
    }
    // console.log(this.userShow.question_answers);
    var loan_duration;
    var loan_fees;
    if(this.userShow.question_answers[13].answer_id == 51){
      loan_duration = 3;
      loan_fees = 252;
    }
    if(this.userShow.question_answers[13].answer_id == 52){
      loan_duration = 4;
      loan_fees = 352;
    }

    var data_ = {
      loan_product_id: 67680,//Flexi-Loan
      borrower_id: borrower_id,
      loan_application_id: 'PR100000'+this.userShow.id,
      loan_disbursed_by_id: 51498,//Interac E-transfer
      loan_principal_amount: 400,
      loan_released_date: day + "/" + month + "/" + loan_released_date.getFullYear(),//Date now
      loan_interest_method: "compound_interest",
      loan_interest_type: "percentage",
      loan_interest: 23,
      loan_interest_period: "Year",
      loan_interest_schedule: "charge_interest_normally",
      loan_duration_period: "Months",
      loan_duration: loan_duration,
      loan_payment_scheme_id: 9,//by-weekly
      loan_num_of_repayments: 6,
      automatic_payments: 1,//yes
      payment_posting_period: 2,//04.01am - 08.00am
      loan_fee_id_4065: loan_fees,//Credit Protection Membership
      loan_fee_schedule_4065: "distribute_fees_evenly",//How should Credit Protection Membership be charged in Loan Schedule? evenly
      loan_decimal_places: "round_off_to_two_decimal",
      loan_status_id: 8,//Processing
      custom_field_3271: "Yes",
      custom_field_5696: this.userIbv.ibv_client.ibv_accounts[0].account,
      custom_field_5695: this.userIbv.ibv_client.ibv_accounts[0].transit,
      custom_field_5694: this.userIbv.ibv_client.ibv_accounts[0].institution,
      custom_field_5701: "API KEY",
      custom_field_5702: "Hashed Signature (VOPAY)",
      branch_id: branch_id
    }

    //console.log(data_);
      this.loandiskService.createLoan(data_)
        .subscribe(
          data => {
            // console.log(data);

            this.modal.close();
            if(typeof data['response']['Errors'] === 'undefined'){

              var data2_ = {
                loan_id: data['response']['loan_id'],
                loan_product_id: 67680,//Flexi-Loan
                borrower_id: borrower_id,
                loan_application_id: 'PR100000'+this.userShow.id,
                loan_disbursed_by_id: 51498,//Interac E-transfer
                loan_principal_amount: 400,
                loan_released_date: day + "/" + month + "/" + loan_released_date.getFullYear(),//Date now
                loan_interest_method: "compound_interest",
                loan_interest_type: "percentage",
                loan_interest: 23,
                loan_interest_period: "Year",
                loan_interest_schedule: "charge_interest_normally",
                loan_duration_period: "Months",
                loan_duration: loan_duration,
                loan_payment_scheme_id: 9,//by-weekly
                loan_num_of_repayments: 6,
                automatic_payments: 1,//yes
                payment_posting_period: 2,//04.01am - 08.00am
                loan_fee_id_4065: loan_fees,//Credit Protection Membership
                loan_fee_schedule_4065: "distribute_fees_evenly",//How should Credit Protection Membership be charged in Loan Schedule? evenly
                loan_decimal_places: "round_off_to_two_decimal",
                loan_status_id: 8,//Processing
                custom_field_3271: "Yes",
                custom_field_5696: this.userIbv.ibv_client.ibv_accounts[0].account,
                custom_field_5695: this.userIbv.ibv_client.ibv_accounts[0].transit,
                custom_field_5694: this.userIbv.ibv_client.ibv_accounts[0].institution,
                custom_field_5701: "API KEY",
                custom_field_5702: this.userShow.first_name+" "+this.userShow.last_name+" "+data['response']['loan_id'],
                branch_id: branch_id
              }
              //console.log(data2_);
              this.loandiskService.updateLoan(data2_)
                  .subscribe(data__ => {
                      //console.log(data__);
                      let data_loan = {
                        loan_id: data['response']['loan_id'],
                        client_id: this.userShow.id
                      };

                      this.loandiskService.createLoanLocal(data_loan)
                        .subscribe(data => {
                            this.toastrService.success('Borrower and Loan successfully created.', 'Success', {
                              timeOut: 5000
                            });
                            this.modal.close();
                          },
                          error => {
                            console.log(error);
                      });

                      var data_ = { borrower_id:  this.userShow.borrower_id};
                      //console.log(data_);
                      this.clientService.updateClient(this.userShow.id,data_)
                        .subscribe(
                          data => {

                          },
                          error => {
                            console.log(error);
                          }
                      );
                    },
                    error => {
                      console.log(error);
                });



            } else {
              this.toastrService.error("Error: "+data['response']['Errors'], 'Error', {
                timeOut: 5000
              });

              this.modal.close();
            }

          },
          error => {
            console.log(error);
          }
      );
  }

  checkedin($event){

  }
  @HostListener('click', ['$event.target'])
  onClick() {
    // console.log('button', 'number of clicks:');
    if(this.rutaActiva.snapshot.params.screen!=null){
      this.minutes_return = 0;
      this.hour_return = 0;
      this.seconds_return = 0;
    }
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    // console.log('button', 'number of handleKeyDown:');
    if(this.rutaActiva.snapshot.params.screen!=null){
      this.minutes_return = 0;
      this.hour_return = 0;
      this.seconds_return = 0;
    }
  }
  // resetCounter() {
  //   // console.log('button', 'number of resetCounter:');

  // }
  @HostListener('window:scroll', ['$event'])
  scroll() {
    // console.log('button', 'number of scroll:');
    if(this.rutaActiva.snapshot.params.screen!=null){
      this.minutes_return = 0;
      this.hour_return = 0;
      this.seconds_return = 0;
    }

  }
  @HostListener('window:resize', ['$event'])
  resize(){
    // console.log('button', 'number of resize:');
    if(this.rutaActiva.snapshot.params.screen!=null){
      this.minutes_return = 0;
      this.hour_return = 0;
      this.seconds_return = 0;
    }

  }
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    // Do something

     event.preventDefault();
     event.returnValue = false;
     this.setStatusSeeing();
  }
  @HostListener('window:mousestop', ['$event'])
  mousestop() {
      // this.mouseDown = false;
      // console.log('mousestop ');

  }

  @HostListener('window:unload', ['$event'])
  unloadHandler(event) {
      // console.log('unloadHandler');
  }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander(event) {
  //     return false;
  // }
  @HostListener('window:mousemove', ['$event'])
  mousemove(e){
    // console.log("testing 0",this.isInactive,"!this.userActivityThrottlerTimeout",!this.userActivityThrottlerTimeout)
    if(this.rutaActiva.snapshot.params.screen!=null){
      this.minutes_return = 0;
      this.hour_return = 0;
      this.seconds_return = 0;
    }

  }

  createReportExcel(guid){
    this.inveriteService.fetch(guid)
    .subscribe(data => {
      this.report = data;
      let name = this.report.name;

      for(let i = 0; i < this.report.accounts.length; i++){
        let name_bank = this.report.accounts[i].bank;
        let number_account = this.report.accounts[i].account;
        this.exportAsExcelFile(this.report.accounts[i].transactions, `${name}-${name_bank}-${number_account}`);
        // console.log(this.report.accounts[i].transactions);
      }

    });
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '-transactions-' + new  Date().getTime() + this.EXCEL_EXTENSION);
  }

  getBorrower(email){
    this.clientService.getBorrowerClient(email)
      .subscribe(data => {
        this.borrower_id_user = Object.keys(data).length  != 0 ? data[0].borrower_id : '';
      });
  }
  showDetailRequest($event){
    // alert();
    // console.log($event)
    this.Show(1,$event.id)

  }
}
