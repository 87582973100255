import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  content: string;

  constructor(private userService: UserService,
              public googleAnalyticsService: GoogleAnalyticsService
              ) { }

  ngOnInit() {
    this.userService.getPublicContent().subscribe(
      data => {
        this.content = data;
      },
      err => {
        this.content = JSON.parse(err.error).message;
      }
    );
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/home', 'Follow User', 'userLabel', 1);
  }
}