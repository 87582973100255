import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

const baseUrl = `${environment.url_server}/api/reasons`;

@Injectable({
  providedIn: 'root'
})
export class ReasonsService {

  constructor(private http: HttpClient) { }

  getReason(status_id) {
    return this.http.get(`${baseUrl}/getReasons/${status_id}`);
  }
}
