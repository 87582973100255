// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url_server: 'http://localhost:8001',
  // flinks_page: 'https://instantfunding-iframe.private.fin.ag/v2/?institutionsLocation=ca&demo=true',

  url_server: 'https://old.prestocash.app',
  flinks_page: 'https://prestocash-iframe.private.fin.ag/v2/?institutionsLocation=ca&daysOfTransactions=Days365&monthsOfStatements=Months12'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.   8946668    8560479
