import { Component, OnInit } from '@angular/core';
import { AuditoryService } from 'src/app/services/auditory.service';
import { IbvWebsitesService } from 'src/app/services/ibv-websites.service';
import { Ibv } from 'src/app/services/ibv.service';
import { InveriteService } from 'src/app/services/inverite.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-select-ibv',
  templateUrl: './select-ibv.component.html',
  styleUrls: ['./select-ibv.component.scss']
})
export class SelectIbvComponent implements OnInit {
  listIframes=[]
  ipAddress: any;
  constructor(
    private tokenStorageService: TokenStorageService,
    private inveriteService: InveriteService,
    private auditory: AuditoryService,
    private ibvWebsites: IbvWebsitesService

  ) { }

  ngOnInit() {
    this.ibvWebsites.getAllIbv().subscribe(ibv=>{
      this.ibvWebsites.getWebsites().subscribe(website=>{
        this.ibvWebsites.getAllIbvWebsites().subscribe(ibvWebsite=>{
          console.log(ibv);
          let IBV=[]
          IBV.push(ibv)
          console.log(website);
          let WEBSITE=[]
          WEBSITE.push(website)
          console.log(ibvWebsite);
          let IBVWEBSITE=[]
          IBVWEBSITE.push(ibvWebsite)
          IBV[0].filter(ibvs=>{
            this.listIframes.push(
              {
                id:ibvs.id,
                name:ibvs.name,
                websites:[]
              }
            )            
          })
          this.listIframes.filter(list=>{
            WEBSITE[0].filter(web=>{
              IBVWEBSITE[0].filter(iw=>{
                
                if(web.id==iw.website_id && list.id==iw.ibv_id){
                  
                  list.websites.push({
                    id:iw.id,
                    disabled:iw.disabled,
                    selected:iw.selected,
                    website_id:iw.website_id,
                    url:web.url

                  })
                }
              })
            })
          })
          
          console.log(this.listIframes,"ssdsddd")

        })
      })
    })
    
  }
  handleChange(ibv_id, website_id,$event,ibv_pre,website_normal_id){ 
    // this.displayParameter = evt;
    console.log(website_normal_id)
    let pre=document.getElementById("r4"+ibv_pre+""+website_id).getAttribute("value")
    let current=document.getElementById("r4"+ibv_id+""+(ibv_id==1?website_id-3:website_id+3)).getAttribute("value");
    if($event.target.value=="1"){
      document.getElementById("r4"+ibv_pre+""+website_id).removeAttribute("disabled");

      $event.target.value="0"
      pre=document.getElementById("r4"+ibv_pre+""+website_id).getAttribute("value")
      current=document.getElementById("r4"+ibv_id+""+(ibv_id==1?website_id-3:website_id+3)).getAttribute("value");
      let dataCurr={
        id:(ibv_id==1?website_id-3:website_id+3),
        website_id:website_normal_id,
        ibv_id:ibv_id,
        selected:$event.target.value,
        disabled:""
      }
      let dataPre={
        id:website_id,
        website_id:website_normal_id,
        ibv_id:ibv_pre,
        selected:$event.target.value,
        disabled:""

      }
      this.ibvWebsites.postUpdate(dataCurr).subscribe(curr=>{
        this.ibvWebsites.postUpdate(dataPre).subscribe()
      })
      console.log(dataPre,dataCurr,"data")
    }else{
      document.getElementById("r4"+ibv_pre+""+website_id).setAttribute("disabled","disabled");;      
      $event.target.value="1"
      pre=document.getElementById("r4"+ibv_pre+""+website_id).getAttribute("value")
      current=document.getElementById("r4"+ibv_id+""+(ibv_id==1?website_id-3:website_id+3)).getAttribute("value");
      let dataCurr={
        id:(ibv_id==1?website_id-3:website_id+3),
        website_id:website_normal_id,
        ibv_id:ibv_id,
        selected:$event.target.value,
        disabled:""
      }
      let dataPre={
        id:website_id,
        website_id:website_normal_id,
        ibv_id:ibv_pre,
        selected:"0",
        disabled:"disabled"

      }
      this.ibvWebsites.postUpdate(dataCurr).subscribe(curr=>{
        this.ibvWebsites.postUpdate(dataPre).subscribe()
      })
      console.log(dataPre,dataCurr,"data")

    }
    



  }

}
