import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/cities';

@Injectable({
  providedIn: 'root'
})

export class CityService {

  constructor(private http: HttpClient) { }

  getCityAll(province_id) {
    return this.http.get(`${baseUrl}/province/${province_id}`);
  }

}
