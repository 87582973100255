import { Component, OnInit } from '@angular/core';
import { QuestionService } from 'src/app/services/question.service';
import { QuestionfrService } from 'src/app/services/questionfr.service';
import { QuestionAnswerfrService } from 'src/app/services/question-answerfr.service';
import { AnswerService } from 'src/app/services/answer.service';
import { AnswerfrService } from 'src/app/services/answerfr.service';
import { ProvinceService } from 'src/app/services/province.service';
import { CityService } from 'src/app/services/city.service';
import { ClientService } from 'src/app/services/client.service';
import { QuestionAnswerService } from 'src/app/services/question-answer.service';
import { InveriteService } from 'src/app/services/inverite.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { IbvWebsitesService } from 'src/app/services/ibv-websites.service';
import { isNumber } from 'util';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  currentQuestion = null;
  seeform: number = 0;
  question: FormGroup;
  numberQuestion = 0;
  firstLoan = false;
  see=true;
  minDate: Date;
  maxDate: Date;
  minDate_ = null;
  maxDate_ = null;
  province = null;
  minQuantity_ = 1200;
  province_data = null;
  currentAnswer = null;
  numberAnswer = 1;
  client = null;
  ipAddress:string;
  form2:boolean = false;

  currentProvinces = null;
  currentCities = null;
  phone_ = [0,0,0,0,0]

  test = true;
  endTest = false;
  bad_answers = ["14","15","16","17","18","19","27","28","29","33","38","44","48","50","53","55"];
  answers_array = [];
  form: FormGroup;
  submitted = false;

  maskNumber = {
    guide: true,
    showMask: true,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]//(999)999-9999
  };

  model: NgbDateStruct;
  date: {year: number, month: number};
  modal : NgbModalRef;

  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  disabledDates = null;

  datepickerModel= null;
  daterangepickerModel: Date[];
  showContinue = true;
  arrayquestions: any=[];
  clickButton: boolean = false;

  constructor(
    private modalService: NgbModal,
    private inveriteService: InveriteService,
    private router:Router,
    private questionService: QuestionService,
    private QuestionfrService: QuestionfrService,
    private answerService: AnswerService,
    private AnswerfrService: AnswerfrService,
    private QuestionAnswerfrService: QuestionAnswerfrService,
    private provinceService: ProvinceService,
    private cityService: CityService,
    private clientService: ClientService,
    private questionAnswerService : QuestionAnswerService,
    private fb: FormBuilder,
    public googleAnalyticsService: GoogleAnalyticsService,
    public ibvWebsitesService: IbvWebsitesService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    localStorage.setItem('sendMail', '0');

    this.activatedRoute.params
      .subscribe(({id}) => {

        this.arrayquestions = JSON.parse(`${localStorage.getItem('answers')}`) ? JSON.parse(`${localStorage.getItem('answers')}`) : [];

        if(isNaN(id)){
          if(id === 'data-client'){
            this.seeform = 1;
            this.form = this.fb.group({
              gender: ['female', [Validators.required]],
              first_name: [null,Validators.required],
              date_birth: [null,Validators.required],
              last_name: [null,Validators.required],
              email: [null,[Validators.required, Validators.email]],
              cell_phone_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
              province_id: [null,Validators.required],
              landline_number:[null],
              city_id: [null,Validators.required],
              postal_code: [null,Validators.required],
              address: [null,Validators.required],
              employer_name: [null,Validators.required],
              employer_phone_name: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
              if_renewal:'Yes.',
              origin: 'prestobroker'
            });

            this.getAllProvinces();
          }

          if(id === 'languaje'){
            localStorage.clear();
            this.seeform = 0;
            this.arrayquestions = [];
            this.getQuestion(1);
            this.getAnswer(1);
          }

          if(id === 'continue'){
            this.seeform = 2;
          }

          if(id === 'finished'){
            this.seeform = 3;
          }
        }else{
          if(localStorage.getItem('salt_11')){
            id = parseInt(`${localStorage.getItem('salt_11')}`);
          }
          this.numberQuestion = parseInt(id);

          if(this.arrayquestions){
            this.getQuestion(parseInt(id) + 1);
            this.getAnswer(parseInt(id) + 1);
          }else if(this.arrayquestions != 0){
            this.router.navigateByUrl(`/form/${this.arrayquestions.length}`);
          }else{
            this.router.navigateByUrl(`/form/languaje`);
          }
        }
      });

    this.question = this.fb.group({
      answer: [null,Validators.required]
    });
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/form', 'Follow User', 'userLabel', 1);
    this.inveriteService.getIPAddress().then((res:any)=>{
      this.ipAddress=res.ip;
      console.log(this.ipAddress)
    },err=>{
      console.log(err)
      this.ipAddress="190.36.186.3";
      console.log(this.ipAddress)

    });

    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });

    var millisecondPerDay = 24 * 60 * 60 * 1000;
    var now = new Date();
    var startDate:Date = new Date(now.setFullYear(now.getFullYear() - 18));
    var endDate:Date = new Date(now.setFullYear(now.getFullYear() + 20));//change as per your need
    //console.log(startDate);
    //console.log(endDate);
    this.disabledDates = [];

    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 27375);
    this.maxDate.setDate(this.maxDate.getDate() - 6570);


    this.minDate_ = this.format_date(this.minDate);
    this.maxDate_ = this.format_date(this.maxDate);

    do{
      this.disabledDates.push(startDate);
      startDate = new Date((startDate.getTime() + millisecondPerDay));
    } while(startDate <= endDate)

  }

  continue(){
    this.seeform = 0;
    this.router.navigateByUrl(`/form/${this.arrayquestions.length}`);
  }

  finished(){
    if(!this.clickButton){
      this.clickButton = true;
      this.client = JSON.parse(`${localStorage.getItem('data_client')}`);
      this.createClient(this.client);
    }

  }

  format_date(date_){
    var date_birth = new Date (date_);
    let month = null;
    let day = null;

    if(date_birth.getMonth() < 9){
      month = date_birth.getMonth() + 1;
      month = "0"+month ;
    } else {
      month = date_birth.getMonth() + 1;
    }

    if(date_birth.getDate() < 10){
      day = "0"+date_birth.getDate();
    } else {
      day = date_birth.getDate();
    }

    return `${date_birth.getFullYear()}-${month}-${day}`;
  }

  onValueChange(value: Date): void {
    //this.data = value;
    this.datepickerModel = value;
  }
  open(content) {
    this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.modal.result.then((e) => {

    });

  }

  getQuestion(id) {
    // console.log(id,"ans")
    // this.arrayquestions[ this.arrayquestions.length]=this.numberQuestion

    if(id==1){


      this.questionService.get(id)
        .subscribe(
          data => {

          console.log(data, 'data');

          this.currentQuestion = data;



        },
        error => {
          console.log(error);
        });
    }else{
      console.log(this.answers_array[1],id)
      // this.arrayquestions[ this.arrayquestions.length]=this.numberQuestion



      if(this.arrayquestions[0].answer == 2){

        this.questionService.get(id)
        .subscribe(
          data => {

            if(data['id'] == 11){
                data['question'] = data['question'];
            }
            this.currentQuestion = data;



          },
          error => {
            console.log(error);
          });

      }else{//1

        this.QuestionfrService.get(id)
        .subscribe(
          data => {

            this.currentQuestion = data;



          },
          error => {
            console.log(error);
          });
      }
    }
    if(id==11){
      if(this.form2 == true || this.answers_array[10]==41){
        this.form = this.fb.group({
          gender: ['female'],
          first_name: [null,Validators.required],
          last_name: [null,Validators.required],
          cell_phone_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
          landline_number:[null],
          email: [null,[Validators.required, Validators.email]],
          date_birth: [null,Validators.required],
          province_id: [null,Validators.required],
          city_id: [null,Validators.required],
          postal_code: [null,Validators.required],
          address: [null,Validators.required],
          employer_name: [null,Validators.required],
          employer_phone_name: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
          // name_reference_one: [null,Validators.required],
          // name_reference_one_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
          // name_reference_two: [null,Validators.required],
          // name_reference_two_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
          if_renewal:'Yes.',
          origin: 'prestobroker'

        });
      }
    }
    if(id == 16 && this.answers_array[10]==42){
      this.form = this.fb.group({
        gender: ['female'],
        first_name: [null,Validators.required],
        last_name: [null,Validators.required],
        cell_phone_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
        landline_number:[null],
        email: [null,[Validators.required, Validators.email]],
        // date_birth: [null,Validators.required],
        province_id: [null,Validators.required],
        city_id: [null,Validators.required],
        postal_code: [null,Validators.required],
        address: [null,Validators.required],
        // employer_name: [null,Validators.required],
        // employer_phone_name: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
        name_reference_one: [null,Validators.required],
        name_reference_one_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
        name_reference_two: [null,Validators.required],
        name_reference_two_number: [null,[Validators.required,Validators.pattern(/[(0-9{3})][0-9]{3}-[0-9]{4}/)]],
        if_renewal:'No.',
        origin: 'prestobroker'
      });
    }
    // console.log(this.arrayquestions);
  }

  getAnswer(id) {
    console.log(id,"xxxx")
    if(id==1){
      this.answerService.getAnswerAll(id)
      .subscribe(
        data => {
          console.log(data,"entrandp")
          // if(id == 12){
          //   var data_ = [];
          //   if(this.firstLoan){
          //     data_[0] = JSON.parse('{"id":'+data[0]['id']+',"answer":"'+data[0]['answer']+'", "question_id":'+data[0]['question_id']+'}');
          //     data_[1] = JSON.parse('{"id":'+data[1]['id']+',"answer":"'+data[1]['answer']+'", "question_id":'+data[1]['question_id']+'}');
          //   }
          //   this.currentAnswer = data_;

          // } else {
            this.currentAnswer = data;
          // }
          //console.log(data);
        },
        error => {
          console.log(error);
        });
    }else{
      if(this.arrayquestions[0].answer == 2){
        this.answerService.getAnswerAll(id)
        .subscribe(
          data => {
            console.log(data,"entrandp answer == 2 id=",id)
            // if(id == 13){
            //   var data_ = [];
            //   console.log("firstLoan");
            //   console.log(this.firstLoan);
            //   if(this.firstLoan){
            //     data_[0] = JSON.parse('{"id":'+data[0]['id']+',"answer":"'+data[0]['answer']+'", "question_id":'+data[0]['question_id']+'}');
            //     console.log("data_[0]");
            //     console.log(data_[0]);
            //   } else {
            //     //data_[0] = JSON.parse('{"id":'+data[2]['id']+',"answer":"'+data[2]['answer']+'", "question_id":'+data[2]['question_id']+'}');
            //     data_[1] = JSON.parse('{"id":'+data[1]['id']+',"answer":"'+data[1]['answer']+'", "question_id":'+data[1]['question_id']+'}');
            //     console.log("data_[1]");
            //     console.log(data_[1]);
            //   }
            //   this.currentAnswer = data_;

            // } else {
              this.currentAnswer = data;
            //}
            //console.log(data);
          },
          error => {
            console.log(error);
        });
      }else{
          this.AnswerfrService.getAnswerAll(id)
        .subscribe(
          data => {
            console.log(data,"entrandp")
            // if(id == 12){
            //   var data_ = [];
            //   if(this.firstLoan){
            //     data_[0] = JSON.parse('{"id":'+data[0]['id']+',"answer":"'+data[0]['answer']+'", "question_id":'+data[0]['question_id']+'}');
            //   } else {
            //     //data_[0] = JSON.parse('{"id":'+data[2]['id']+',"answer":"'+data[2]['answer']+'", "question_id":'+data[2]['question_id']+'}');
            //     data_[1] = JSON.parse('{"id":'+data[1]['id']+',"answer":"'+data[1]['answer']+'", "question_id":'+data[1]['question_id']+'}');
            //   }
            //   this.currentAnswer = data_;

            // } else {
              this.currentAnswer = data;
            // }
            //console.log(data);
          },
          error => {
            console.log(error);
          });
        }
    }


  }

  getAllProvinces(){
    this.provinceService.getAll()
      .subscribe(
        data => {
          this.currentProvinces = data;
          //console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  getAllCities(id){
    this.cityService.getCityAll(id)
      .subscribe(
        data => {
          this.province_data = data;
          this.province = this.province_data.province_cod;

          // console.log(this.province);
        },
        error => {
          console.log(error);
        });
  }

  changeProvince(e) {
    //console.log("province: "+e.target.value);
    this.getAllCities(e.target.value);
  }

  createClient(data){
    this.see=false;
    this.iframeSelect(1,data);
    return '';

    console.log(data,"createClient")


  }
  iframeSelect(x,data){
    this.ibvWebsitesService.getSearch(x).subscribe(web=>{
      console.log(web, 'web')
      localStorage.setItem("web", JSON.stringify(web))
        this.clientService.create(data)
          .subscribe(
            data => {
              console.log(data["id"]);
              // return ''
              this.createFormClient(data['id']);
              this.client = data;
              console.log(this.client)
              localStorage.setItem('client_id', data['id']);
            },
            error => {
              console.log(error);
        });
    });
  }
  createFormClient(client_id){
    console.log(this.arrayquestions);

    for (var i = 0; i < this.arrayquestions.length; i++) {
      if(i != 13){
        var question_answer = { question_id: this.arrayquestions[i].question_id, answer_id: this.arrayquestions[i].answer, client_id: client_id };
        // if(this.answers_array[1]==2){
          this.questionAnswerService.create(question_answer)
            .subscribe(
              data => {
                console.log("data");
                this.clientService.updateClient(this.client.id,this.client).subscribe(upd=>{
                  // console.log('udp ==> ', upd)
                  // this.createClientInverite()
                  this.router.navigate(['ibv']);
                });

              },
              error => {
                console.log(error);
          });
        // }else{
        //   this.QuestionAnswerfrService.create(question_answer)
        //     .subscribe(
        //       data => {
        //         //console.log(data);
        //         this.modal.close();
        //         this.router.navigate(['ibv']);
        //       },
        //       error => {
        //         console.log(error);
        //   });
        // }
      }
    }
  }

  // createClientInverite(){

  //   this.cityService.getCityAll(this.client.province_id)
  //     .subscribe(
  //       data_pro => {
  //         console.log(data_pro, 'provinces');
  //         this.province_data = data_pro;
  //         this.province = this.province_data.province_cod;

  //         let data_ = { ip: this.ipAddress,
  //           email: this.client.email,
  //           firstname : this.client.first_name,
  //           lastname : this.client.last_name,
  //           referenceid : this.client.id,
  //           province: this.province,
  //           type : "web"};

  //         // console.log(this.province);
  //         this.inveriteService.create(data_)
  //         .subscribe(
  //             data => {
  //                 console.log(data, 'del ibv');
  //                 this.client.guid = data['request_guid'];
  //                 localStorage.setItem('iframeURL', data['iframeurl']);
  //                 localStorage.setItem('request_guid', data['request_guid']);
  //                 // this.createClient(this.client);
  //                 if(typeof data['reused'] == "undefined"){
  //                   localStorage.setItem('reused', "0");
  //                 } else {
  //                   localStorage.setItem('reused', "1");
  //                 }
  //                 //  this.modal.close();
  //                 this.router.navigate(['ibv']);
  //             },
  //             error => {
  //                 console.log(error);
  //             }
  //         );
  //       },
  //       error => {
  //         console.log(error);
  //       });


  // }

  restartQuestion(){
    this.showContinue = true;
    this.test = true;
  }

  backQuestion(flag: number = 0){
    console.log("localStorage.getItem('salt_11')",localStorage.getItem('salt_11'));
    this.arrayquestions.pop();
    localStorage.setItem('answers', JSON.stringify(this.arrayquestions));
    if(localStorage.getItem('salt_11') && parseInt(`${localStorage.getItem('salt_11')}`) > 15){
      localStorage.setItem('salt_11', `${parseInt(`${localStorage.getItem('salt_11')}`) - 1}`)
    }else{
      localStorage.removeItem('salt_11');
    }
    if(flag){
      this.router.navigateByUrl(`/form/languaje`);
    }else{
      this.router.navigateByUrl(`/form/${this.arrayquestions.length}`);
    }

  }

  saveAnswer(answer,question_id) {

    if(question_id===10 && answer==42){
      this.firstLoan = false;
    }else if(question_id===10 && answer==41){
      this.firstLoan = true;
      localStorage.setItem('salt_11', '10');
    }else if(question_id===11 && answer==43){
      this.arrayquestions.push({question_id, answer});
      localStorage.setItem('answers', JSON.stringify(this.arrayquestions));
      this.firstLoan = false;
      this.finished();
      // this.router.navigateByUrl(`/form/finished`);
      return;
    }

    if(localStorage.getItem('salt_11') && parseInt(`${localStorage.getItem('salt_11')}`) < 16){
      localStorage.setItem('salt_11', `${parseInt(`${localStorage.getItem('salt_11')}`) + 1}`);
    }
    if(this.bad_answers.includes(String(answer))){
      this.showContinue = false;
      this.test = false;
      return;
    }

    this.arrayquestions.push({question_id, answer});
    localStorage.setItem('answers', JSON.stringify(this.arrayquestions));

    if(this.arrayquestions.length === 1){
      this.router.navigateByUrl(`/form/data-client`);
      return;
    }

    if(this.arrayquestions.length + 1 === 16){
      this.router.navigateByUrl(`/form/finished`);
      return;
    }

    this.router.navigateByUrl(`/form/${this.arrayquestions.length}`);

    // else if (question_id == 5 && this.test){//calculate formula
    //   const Frequency = this.answers_array['4'];
    //   var result = 0;
    //   if(Frequency == 20){//weekly
    //     result = (answer.answer*52)/12;
    //   } else if(Frequency == 21){//bi-weekly
    //     result = (answer.answer*26)/12;

    //   } else if(Frequency == 22){//bi-monthly
    //     result = (answer.answer*24)/12;
    //   } else {//monthly
    //     result = answer.answer*12;
    //   }

    //   if(result >1800 && answer.answer >= this.minQuantity_){
    //     this.answers_array[question_id] = answer.answer;
    //     this.numberQuestion += 1;
    //     this.numberAnswer += 1;
    //   } else {
    //     this.test = false;
    //   }

    // } else if(question_id == 10 && answer.id == 42){ // answer NO
    //   this.answers_array[question_id] = answer.id;
    //   this.endTest = true;
    //   this.numberQuestion += 6;
    //   this.getAllProvinces();
    //   this.getAllCities(1);
    // } else if (question_id != 5 && this.test){

    //   if(question_id == 12){
    //     this.answers_array[question_id] = answer.id;
    //     this.endTest = true;
    //     this.numberQuestion += 2;
    //     this.getAllProvinces();
    //     this.getAllCities(1);
    //   } else if(question_id == 1 && this.router.url.includes('form2')){
    //     this.answers_array[question_id] = answer.id;
    //     this.endTest = true;
    //     this.numberQuestion += 10;
    //     this.form2 = true;
    //     this.getAllProvinces();
    //     this.getAllCities(1);
    //   } else {
    //     this.answers_array[question_id] = answer.id;
    //     this.numberQuestion += 1;
    //     this.numberAnswer += 1;
    //   }

    // }
    // if(question_id == 8){

    //   this.numberQuestion += 1;
    //   this.numberAnswer += 1;

    //   console.log(this.arrayquestions)

    // }

    // if(question_id == 16){
    //   document.getElementById("init").click();
    //   this.createClient(this.client);
    //   return;
    // }

    // console.log(this.answers_array)
    // this.getQuestion(this.numberQuestion);
    // this.getAnswer(this.numberQuestion);
  }

  get f() { return this.form.controls; }

  saveForm(form) {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    var month, day;

    var date_birth = new Date (form.value.date_birth);

    if(date_birth.getMonth() < 9){
      month = date_birth.getMonth() + 1;
      month = "0"+month ;
    } else {
      month = date_birth.getMonth() + 1;
    }

    if(date_birth.getDate() < 10){
      day = "0"+date_birth.getDate()  ;
    } else {
      day = date_birth.getDate();
    }


    // form.value.date_birth = month+"/"+day+"/"+date_birth.getFullYear();
    form.value.cell_phone_number = form.value.cell_phone_number.replace("(","");
    form.value.cell_phone_number = form.value.cell_phone_number.replace(")","");
    form.value.cell_phone_number = form.value.cell_phone_number.replace("-","");

    if(form.value.landline_number != null){
      form.value.landline_number = form.value.landline_number.replace("(","");
      form.value.landline_number = form.value.landline_number.replace(")","");
      form.value.landline_number = form.value.landline_number.replace("-","");
      form.value.landline_number = form.value.landline_number.replace(/_/g,"");
    }

    form.value.employer_phone_name = form.value.employer_phone_name.replace("(","");
    form.value.employer_phone_name = form.value.employer_phone_name.replace(")","");
    form.value.employer_phone_name = form.value.employer_phone_name.replace("-","");

    // console.log(form.value);

    this.client = this.form.value;
    localStorage.setItem('data_client', JSON.stringify(this.client));
    // console.log("this.client",this.client)

    // this.endTest = false;

    // if( this.form2 == true || (this.form2 == false && this.answers_array[10]==42)){
    //   this.numberQuestion += 1;
    //   document.getElementById("init").click();
    //   this.createClient(this.client);
    //   return;
    // }

    this.router.navigateByUrl(`/form/continue`);

    // this.getQuestion(this.numberQuestion);
    // this.getAnswer(this.numberQuestion);

  }

  clearCellPhone(){
    if(this.phone_[0] == 0){
      (document.getElementById("inputCellPhoneNumber") as HTMLInputElement).value = "";
      this.phone_[0] = 1;
    }
  }

  clearCellPhoneReference1(){
    if(this.phone_[3] == 0){
      (document.getElementById("inputPhone1") as HTMLInputElement).value = "";
      this.phone_[3] = 1;
    }
  }

  clearCellPhoneReference2(){
    if(this.phone_[4] == 0){
      (document.getElementById("inputPhone2") as HTMLInputElement).value = "";
      this.phone_[4] = 1;
    }
  }

  clearLandlinePhone(){
    if(this.phone_[1] == 0){
      (document.getElementById("inputLandlineNumber") as HTMLInputElement).value = "";
      this.phone_[1] = 1;
    }
  }

  clearEmployerPhone(){
    if(this.phone_[2] == 0){
      (document.getElementById("inputEmployerPhoneName") as HTMLInputElement).value = "";
      this.phone_[2] = 1;
    }
  }
  search(x){
    console.log(x,"xxxxxxx")
    this.showContinue = true;
      this.test = true;
    // this.numberQuestion=this.arrayquestions[x];
    // this.numberAnswer=this.arrayquestions[x];
    let aux=[];
    for(var i=0;i<this.arrayquestions.length;i++){
      if(i<x){
        aux[i]=this.arrayquestions[i]
      }
    }
    this.arrayquestions=[];
    this.arrayquestions=aux;
    // this.getQuestion(this.numberQuestion);
    // this.getAnswer(this.numberAnswer);
    localStorage.setItem('sendMail', '0');
    this.question = this.fb.group({
      answer: [null,Validators.required]
    });
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/form', 'Follow User', 'userLabel', 1);
    this.inveriteService.getIPAddress().then((res:any)=>{
      this.ipAddress=res.ip;
      console.log(this.ipAddress)
    },err=>{
      console.log(err)
      this.ipAddress="190.36.186.3";
      console.log(this.ipAddress)

    });

  }
}
