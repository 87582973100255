import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientService } from 'src/app/services/client.service';
import { StatuService } from 'src/app/services/statu.service';
import { BranchService } from 'src/app/services/branch.service';
import { LoandiskService } from 'src/app/services/loandisk.service';
import { Ibv } from 'src/app/services/ibv.service';
import { QuestionAnswerService } from 'src/app/services/question-answer.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { MailService } from "../../services/mail.service";
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import {Router } from '@angular/router';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuditoryService } from 'src/app/services/auditory.service';
import { InveriteService } from 'src/app/services/inverite.service';
import { ReasonsService } from 'src/app/services/reasons.service';

@Component({
  selector: 'app-renewal',
  templateUrl: './renewal.component.html',
  styleUrls: ['./renewal.component.scss']
})
export class RenewalComponent implements OnInit {
  list_clients = null;
  message = '';
  showNav = [true,false,false,false,false,false,false,false,false,false];//All,view,approbate,edit
  userShow = null;
  userIbv = null;
  userIbvDetails = null;
  active = 'active';
  userHistory = null;
  detailsBank = null;
  list_status = null;
  list_branches = null;
  borrower_data = null;
  existIbv = false;
  banks = null;
  user_edit = null;
  request: FormGroup;
  selectedShow :number = null;
  modal : NgbModalRef;
  numberItems = 50;
  date = false;
  submitted = false;
  dataLoan = null;
  private borrower_id = null;
  colorTheme = 'theme-dark-blue';
  bsConfig: Partial<BsDatepickerConfig>;
  disabledDates = null;
  user_view = null;
  borrower
  loan_pack = Array();
  datepickerModel= null;
  daterangepickerModel: Date[];
  minDate: Date;
  maxDate: Date;
  goBackStatus = null;
  goBacks = null;
  setStatus = null;
  roles = null;
  showAdminBoard = null;
  showModeratorBoard = null;
  showCustomerServiceBoard = null;
  ipAddress = "";
  new_status = "";
  old_status = '';

  dropdownList: any = [];
  dropdownSettings: IDropdownSettings = {};
  dropdown = false;
  reasons = null;

  maskNumber = {
    guide: true,
    showMask: true,
    mask: ['(', /\d/, /\d/, /\d/, ')', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]//(999)999-9999
  };
  urls: string;
  urldetails: string;
  logs;
  id
  load=false
  page: number=1;
  hiddenLoadMore: boolean=false;
  band: boolean=true;
  allclient;
  search="";
  searchLoad: boolean=false;
  ibv: string;
  data: string;
  Id: string;
  constructor(
    private modalService: NgbModal,
    private loandiskService: LoandiskService,
    private MailService: MailService,
    private branchService: BranchService,
    private clientService: ClientService,
    private statuService: StatuService,
    private questionAnswerService: QuestionAnswerService,
    private IbvService: Ibv,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private rutaActiva: ActivatedRoute,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private auditory: AuditoryService,
    private inveriteService: InveriteService,
    private reasonService: ReasonsService
  ) { }
  back(x){
    this.getClients();
    this.showComponent(x);
    this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {

    });
  }
  isEquals(value_a, value_b){

    if(value_a && value_b && value_a.toUpperCase() == value_b.toUpperCase()){
      return true;
    }
    return false;
  }
  getAllData(){

  }
  ngOnInit() {
    // if(this.clientService.getDatatoSearchrequest("No.")!=null){
    //   this.allclient=this.clientService.getDatatoSearchrequest("No.")
    //   this.searchLoad=true;
    // }else{
    //   this.clientService.getAllClientSearchRequest("No.").subscribe(data=>{
    //     for(var i=0 ; i < Object.keys(data).length ; i++){
    //       data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
    //     }
    //     this.allclient=data
    //     this.allclient.filter(resp=>{
    //       resp.first_name=resp.first_name+" "+resp.last_name;
    //     })
    //     console.log(this.allclient,"allrequest")
    //     this.clientService.setDatatoSearchrequest("No.",this.allclient);
    //     this.searchLoad=true;

    //   })
    // }

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      enableCheckAll: false,
      itemsShowLimit: 10,
      allowSearchFilter: false
    };

    this.logs=[]
    this.message = '';
    this.id=this.rutaActiva.snapshot.params.id;
    // alert('hola mundo');
    console.log(this.rutaActiva.snapshot.params,"asdadsadsadadadsadsadasdsadasdasdsadsadad");
    this.goBackStatus = localStorage.getItem('statusRenewal');
    this.goBackStatus = this.goBackStatus == 0 ? null : this.goBackStatus;
    if(this.rutaActiva.snapshot.params.screen==null){
      console.log("entre aqui")
      this.message = '';
      if(this.setStatus=this.rutaActiva.snapshot.params.status==null){
        console.log("entre aqui 2")

        if(this.goBackStatus!=""){
          console.log("entre aqui 3")

          if(this.goBackStatus!=null){
            console.log("entre aqui 5")

            this.getClientStatus(this.goBackStatus);
            this.setStatus=this.goBackStatus;
          }else{
            console.log("entre aqui 4")

            this.getClients();
            this.getStatus();
            this.goBacks=localStorage.getItem('goBack');
          }
        }else{
          this.getClients();
          this.getStatus();
          this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/requests', 'Follow User', 'userLabel', 1);
          this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
          this.goBacks=localStorage.getItem('goBack');
        }
      }else{
        this.setStatus=this.rutaActiva.snapshot.params.status
        console.log(this.setStatus,"xxxxx")
        if(this.setStatus!="all"){
          this.goBacks=localStorage.getItem('goBack');
          this.page=1;
          this.getClientStatusType(this.setStatus,this.rutaActiva.snapshot.params.type);
        }else{
          console.log(this.setStatus,"yyy")
          this.setStatus=0;
          this.goBacks=localStorage.getItem('goBack');
          this.page=1
          this.getClientsDates(this.rutaActiva.snapshot.params.type);
        }
      }
      //this.getStatus();
      this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/requests', 'Follow User', 'userLabel', 1);
      this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
      console.log(window.location.hostname,"request");
    }else{
      if(this.rutaActiva.snapshot.params.screen==2){
        this.auditory.getAuditoryClient(this.rutaActiva.snapshot.params.id).subscribe(resp=>{
          this.logs=resp;
        })
        this.Show(1,this.rutaActiva.snapshot.params.id)
        this.getStatus();
      }else{
        this.message = '';
        // this.getClients();
        this.getClientStatus(this.setStatus);
        this.getStatus();
        this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/requests', 'Follow User', 'userLabel', 1);
        this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
      }
    }
    this.setStatus=0;
    if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
      this.urls="https://sandbox.inverite.com/merchant/request/view/";
      // console.log(this.urls)
    }else{
      this.urls="https://inverite.com/merchant/request/view/";
    }
    // this.getClients();
    this.getStatus();
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/renewal', 'Follow User', 'userLabel', 1);
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme });
    console.log(window.location.hostname,"renewal")
    this.showComponent(0);

    const user = this.tokenStorageService.getUser();
    this.roles = user.roles;

    this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
    this.showModeratorBoard = this.roles.includes('ROLE_UNDERWRITER');
    this.showCustomerServiceBoard = this.roles.includes('ROLE_CUSTOMER SERVICE');
    console.log('hola mundo ');
    console.log(this.showAdminBoard);
    console.log(this.showModeratorBoard);
    console.log(this.showCustomerServiceBoard);

    console.log(this.router.url,"xxssxsx")
    let rout=this.router.url.split("/");
    console.log(rout);
    if(rout[1]=="inverite"){
      this.ibv=rout[1];
      this.data=rout[3];
      this.Id=rout[4];
      this.showComponent(8)
    }
    if(rout[1]=="flink"){
      this.ibv=rout[1];
      this.data=rout[3];
      this.Id=rout[4];
      this.showComponent(9)
    }
  }
  getStatus(){
    this.statuService.getStatus()
      .subscribe(
        data => {
           this.list_status = data;
          //  console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  showComponent(value){
    for (var i = 0; i < this.showNav.length; i++) {

      if(i == value){
        this.showNav[i] = true;
      } else {
        this.showNav[i] = false;
      }

    }
  }
  getClientsDates(type) {
      this.load=false
      this.clientService.getAllRenewal(type,this.page)
      .subscribe(
        data => {

          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }

          if(this.page==1){
            this.list_clients = data;
            this.list_clients.filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
           })
         }
         else{
           let aux=[];
           aux.push(data)
           console.log(aux[0].length)
           if(aux[0].length==0){
             this.hiddenLoadMore=true;
           }
           aux[0].filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
             this.list_clients.push(resp)
           })
           console.log(aux,"auxxxxx");
          }
      console.log(window.location.hostname)
      if(window.location.hostname=="localhost"||window.location.hostname=="testprestobroker.ddns.net"){
        this.urls="https://sandbox.inverite.com/merchant/request/view/";
        // console.log(this.urls)
      }else{
        this.urls="https://inverite.com/merchant/request/view/";
      }
      this.load=true
        },
        error => {
          console.log(error);
        });
  }
  getClientStatusType(id,type) {
    this.load=false
    this.clientService.getAllRenewalStatus(type,id,this.page).subscribe(
        data => {

          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }

          if(this.page==1){
            this.list_clients = data;
            this.list_clients.filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
           })
         }
         else{
           let aux=[];
           aux.push(data)
           console.log(aux[0].length)
           if(aux[0].length==0){
             this.hiddenLoadMore=true;
           }
           aux[0].filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
             this.list_clients.push(resp)
           })
           console.log(aux,"auxxxxx");
          }
          // console.log(data);
          this.load=true
        },
        error => {
          console.log(error);
        });
  }
  ItemsCant($event){
    this.numberItems = parseInt(((document.getElementById("numitems") as HTMLInputElement).value));
  }
  public onOptionsSelected(event) {
    const value = event.target.value;
    this.page=1;
    console.log(value,"ssssssssssssssssssssssssssssssssssssssssssssssssssss");
    window.localStorage.setItem('statusRenewal',value);
    if(value == 0){
      this.getClients();
    } else {
      this.getClientStatus(value);
    }
  }
  clearSearch(){
    this.search="";
    this.onInputChanged("");
  }
  loadMore(page,status=null){
    console.log(status,this.rutaActiva.snapshot.params.type,this.setStatus)
    this.hiddenLoadMore=false;
    this.page++;

    // this.getClientStatus(status);
    this.band=true;
    if(this.setStatus!=0&&this.rutaActiva.snapshot.params.type!=null)
    this.getClientStatusType(this.setStatus,this.rutaActiva.snapshot.params.type);
    else
    if(status==null)
    this.getClients()
    else
    this.getClientStatus(status);
  }
  getClients() {
    this.load=false;
    const user = this.tokenStorageService.getUser();
    // this.inveriteService.getIPAddress().subscribe((res:any)=>{
    //   this.ipAddress=res.ip;
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'view list of renewal',status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};
    //   console.log(datas);
    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data,"sasdadasdda");
    //   });

    // },err=>{
    //   console.log(err)

    //   this.ipAddress="190.36.186.3";
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'view list of renewal',status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // });
    // this.page=1;
    // if(this.clientService.getClientRenewalSetted()!=null&&this.band==true){
    //   this.list_clients=this.clientService.getClientRenewalSetted();
    //   this.load=true

    // }else{
    //   alert(this.page);
    this.clientService.getClientsRenewal(this.page)
      .subscribe(
        data => {

          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }

          if(this.page==1){
            this.list_clients = data;
            this.list_clients.filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
           })
         }
         else{
           let aux=[];
           aux.push(data)
           console.log(aux[0].length)
           if(aux[0].length==0){
             this.hiddenLoadMore=true;
           }
           aux[0].filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
             this.list_clients.push(resp)
           })
           console.log(aux,"auxxxxx");
         }
          // this.clientService.setClientStatusRenewal(this.list_clients);
        console.log(this.list_clients)
        this.load=true;
        //  this.clientService.setClientRenewal(this.list_clients);
        //  this.band=false;
        },
        error => {
          console.log(error);
        });
    // }
  }
  getClientStatus(id) {
    this.hiddenLoadMore=false;
    this.clientService.getClientStatusRenewal(id,this.page)
      .subscribe(
        data => {

          for(var i=0 ; i < Object.keys(data).length ; i++){
            data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
          }

          if(this.page==1){
            this.list_clients = data;
            this.list_clients.filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
           })
         }
         else{
           let aux=[];
           aux.push(data)
           console.log(aux[0].length)
           if(aux[0].length==0){
             this.hiddenLoadMore=true;
           }
           aux[0].filter(resp=>{
             resp.first_name=resp.first_name+" "+resp.last_name;
             this.list_clients.push(resp)
           })
           console.log(aux,"auxxxxx");
         }
          this.clientService.setClientStatusRenewal(this.list_clients);
          // console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  viewHistory(id){
    this.showComponent(3);
    this.clientService.getHistory(id)
      .subscribe(
        data => {
          this.userHistory = data;
          // console.log(data);
        },
        error => {
          console.log(error)
        });
  }
  saveDataRequest(form){
    // console.log(this.userShow)
    this.old_status = this.userShow.statu.description;
    var data = { cell_phone_number: form.cell_phone_number,
                 status_id: form.status,
                 description: form.description,
                 health_card: form.health_card,
                 driving_license: form.driving_license,
                 social_insurance: form.social_insurance,
                 identification_ID: form.identification_ID,
                 reasons: JSON.stringify({ reasons: form.reason}),
                guid:form.login_id };
    var dataSend = { cell_phone_number: form.cell_phone_number,
                 status_id: form.status,
                 description: form.description,
                 health_card: form.health_card,
                 driving_license: form.driving_license,
                 social_insurance: form.social_insurance,
                 identification_ID: form.identification_ID,
                 guid:form.login_id
                };

                this.urldetails=this.urls+form.login_id
                //  console.log(data);return "";


    for (let i = 0; i < this.list_status.length; i++) {
      if (this.list_status[i].id == form.status) {
        this.userShow.statu.description = this.list_status[i].description;
      }
    }


    var amount = {amount: form.amount};

    var date_ = new Date(this.userShow.date_birth);
    var day, month, borrower_landline;

    if(date_.getMonth() < 10){
      month = date_.getMonth() + 1;
      month = "0" + month;
    } else {
      month = date_.getMonth() + 1;
    }

    if(date_.getDate() < 10){
      day = "0"+date_.getDate();
    } else {
      day = date_.getDate();
    }

    const user = this.tokenStorageService.getUser();

      this.ipAddress = localStorage.getItem('ip') || "190.36.186.3";
      // console.log(this.ipAddress)
      let datas = {nameUser: user.username,action: 'Update status in renewal',status: this.old_status, new_status: this.new_status, userId: user.id,clientId: this.userIbv.id, nameClient : this.userIbv.first_name, email: this.userIbv.email, ip_address: this.ipAddress, if_renewal: this.userIbv.if_renewal,updated_file: 1};

      this.auditory.createRegisterAuditory(datas).subscribe(data => {
        console.log(data);
      });


    if(this.userShow.landline_number != null){
      borrower_landline = this.userShow.landline_number.replace("(","");
      borrower_landline = borrower_landline.replace(")","");
      borrower_landline = borrower_landline.replace("-","");
      borrower_landline = borrower_landline.replace(/_/g,"");
    } else {
      borrower_landline = '';
    }

    if(this.userShow.borrower_id && form.status != 15){

      this.branchService.selectedBranch()
        .subscribe(
          data => {
            this.loandiskService.getBorrower(this.userShow.borrower_id,data[0]['value'])
              .subscribe(
                borrower_user => {
                  this.borrower_data = borrower_user;
                  this.borrower_data = this.borrower_data.response.Results[0];
                  // console.log(this.borrower_data, 'el borrower del usuario');
                  let data_borrower = {
                    borrower_id: this.borrower_data.borrower_id,
                    borrower_unique_number: this.borrower_data.borrower_unique_number,
                    borrower_firstname: this.borrower_data.borrower_firstname,
                    borrower_lastname: this.borrower_data.borrower_lastname,
                    borrower_country: this.borrower_data.borrower_country,
                    borrower_gender: this.borrower_data.borrower_gender,
                    borrower_mobile: this.borrower_data.borrower_mobile,
                    borrower_email: this.borrower_data.borrower_email,
                    borrower_address: this.borrower_data.borrower_address,
                    borrower_city: this.borrower_data.borrower_city,
                    borrower_province: this.borrower_data.borrower_province,
                    borrower_zipcode: this.borrower_data.borrower_zipcode,
                    borrower_landline: this.borrower_data.borrower_landline,
                    custom_field_5703: this.borrower_data.custom_field_5703,
                    custom_field_5690: form.health_card,//Health card / Assurance Maladie
                    custom_field_5691: form.driving_license,//Driving License
                    custom_field_5692: form.social_insurance,//SIN
                    custom_field_5693: form.identification_ID,//Health card / Assurance Maladie
                    custom_field_5934: this.userShow.name_reference_one,
                    custom_field_5936: this.userShow.name_reference_one_number,
                    custom_field_5935: this.userShow.name_reference_two,
                    custom_field_5937: this.userShow.name_reference_two_number,
                    custom_field_6370: this.borrower_data.custom_field_6370,
                    custom_field_8611: this.borrower_data.custom_field_8611,
                    branch_id: data[0]['value'],
                    borrower_business_name: this.borrower_data.borrower_business_name,
                    borrower_credit_score: this.borrower_data.borrower_credit_score,
                    borrower_description: this.borrower_data.borrower_description,
                    borrower_dob: this.borrower_data.borrower_dob,
                    borrower_fullname: this.borrower_data.borrower_fullname,
                    borrower_title: this.borrower_data.borrower_title,
                    borrower_working_status: this.borrower_data.borrower_working_status
                  };

                // this.loandiskService.updateBorrower(data_borrower)
                //   .subscribe(
                //     data => {
                //       //console.log(data);

                //     },
                //     error => {
                //       console.log(error);
                //     }
                //   );
              }
            );
          },
          error => {
            console.log(error);
          }
        );
    }
    console.log(data,"update")

    this.clientService.updateClient(form.user_id,data)
      .subscribe(
        data => {
          //console.log(data);
          this.questionAnswerService.updateAmount(form.user_id,amount)
            .subscribe(
              data => {
                //console.log(data);

                this.clientService.getClient(form.user_id)
                  .subscribe(
                    data => {
                      //console.log(data);
                      // this.userShow = data;

                      this.toastrService.success('Client successfully updated.', 'Success', {
                        timeOut: 5000
                      });

                      if(form.status == 15){
                        this.request.controls['description'].disable();
                        this.request.controls['health_card'].disable();
                        this.request.controls['driving_license'].disable();
                        this.request.controls['social_insurance'].disable();
                        this.request.controls['identification_ID'].disable();
                      } else {
                        this.request.controls['description'].enable();
                        this.request.controls['health_card'].enable();
                        this.request.controls['driving_license'].enable();
                        this.request.controls['social_insurance'].enable();
                        this.request.controls['identification_ID'].enable();
                      }
                    },
                    error => {
                      console.log(error);
                });

              },
              error => {
                console.log(error);
              });
              if(dataSend.status_id==6){
                let data_2 = {
                  mail_to:this.userShow.email,
                  name_to:this.userShow.first_name,
                  subject:`${this.userShow.first_name},You have been approved`,
                  textContent:"...",
                  id: this.userShow.id,
                  template: 'approved'
                };
                console.log(data_2);
                this.MailService.sendEmail(data_2)
                      .subscribe(
                        data => {
                          console.log("enviado");
                          this.toastrService.success('Have been sent an email to: '+this.userShow.email, 'Success', {
                            timeOut: 5000
                          });

                        },
                        error => {
                          console.log(error.message);
                          this.toastrService.error(`Have not been sent an email to: ${this.userShow.email}. Please click again in 'SAVE' button`, 'Error', {
                            timeOut: 5000
                          });
                        }
                      );
              }
              if(dataSend.status_id==15){
                let data_2 = {
                  mail_to:this.userShow.email,
                  name_to:this.userShow.first_name,
                  subject:`${this.userShow.first_name}, Your request has been refused.`,
                  textContent:"...",
                  id: this.userShow.id,
                  template: 'refusal'
                };
                console.log(data_2);
                this.MailService.sendEmail(data_2)
                      .subscribe(
                        data => {
                          this.toastrService.success('Have been sent an email to: '+this.userShow.email, 'Success', {
                            timeOut: 5000
                          });

                        },
                        error => {
                          // this.toastrService.error('Have not been sent an email to: '+this.userShow.email+". Please click again in 'SAVE' button.', 'Error', {
                          //   timeOut: 5000
                          // });

                          this.toastrService.error(`Have not been sent an email to: ${this.userShow.email}. Please click again in 'SAVE' button`, 'Error', {
                            timeOut: 5000
                          });
                        }
                      );
              }
        },
        error => {
          console.log(error);
        });

  }
  onInputChanged(event){
    const name = this.search
    // this.list_clients=this.allclient;
    // this.list_clients = this.list_clients.filter((item) => {

    //   // createdAt
    //     return item.first_name.toLowerCase().includes(name.toLowerCase()) || item.email.toLowerCase().includes(name.toLowerCase()) || item.statu.description.toLowerCase().includes(name.toLowerCase()) || item.cell_phone_number.toLowerCase().includes(name.toLowerCase()) || item.createdAt.toLowerCase().includes(name.toLowerCase())

    // });

    if(name == ""){
      if(this.setStatus > 0){
        this.getClientStatus(this.setStatus);
        this.hiddenLoadMore = false;
      }else{
        this.getClients();
        this.hiddenLoadMore = false;
      }
    }else{
      this.clientService.filterClientsRenewal(name, this.setStatus)
        .subscribe(
          data => {
            // console.log(data,"searching")
            for(var i=0 ; i < Object.keys(data).length ; i++){
              data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
              data[i].first_name = data[i].first_name+" "+data[i].last_name;
            }

            this.list_clients = data;
            this.list_clients = this.list_clients.filter((item) => {
            this.hiddenLoadMore = true;
              // createdAt
                return item.first_name.toLowerCase().includes(name.toLowerCase()) || item.email.toLowerCase().includes(name.toLowerCase()) || item.statu.description.toLowerCase().includes(name.toLowerCase()) || item.cell_phone_number.toLowerCase().includes(name.toLowerCase()) || item.createdAt.toLowerCase().includes(name.toLowerCase())

            });
            // console.log(data);
          },
          error => {
            console.log(error);
          }
      );
    }
  }
  onValueChange(value: Date): void {

    (document.getElementById("shared") as HTMLInputElement).value = ""+moment(''+value).format('DD/MM/YYYY');
    this.date = false;
    console.log(value)
    this.clientService.filterClientsRenewal(""+value, this.setStatus)
        .subscribe(
          data => {

            for(var i=0 ; i < Object.keys(data).length ; i++){
              data[i].cell_phone_number = "("+data[i].cell_phone_number.substring(0, 3)+")"+data[i].cell_phone_number.substring(3, 6)+"-"+data[i].cell_phone_number.substring(6, 10);
            }

            this.list_clients = data;
            //  console.log(data);
          },
          error => {
            console.log(error);
          }
      );
  }
  showDate(){
    if(this.date == false){
      this.date = true;
    }else{
      this.date = false;
    }
  }

  setStatusSeeing(){
    this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {

    });
  }

  Show(value,user_id){
    this.user_id = user_id;
    console.log(this.user_id,"Show()", value)
    if(value == 3){
      //this.deleteUser(user_id);
    } else if(user_id != 0){
      this.clientService.getStatusSeeing(user_id).subscribe(user => {
        this.user_view = user;
        if(this.user_view.seeing == 0){
          this.getClientIbv(user_id);
          this.clientService.getClient(user_id)
            .subscribe(
              dataLoanCLient => {
                this.dataLoan = dataLoanCLient;
                let data = this.dataLoan.client;
                console.log(this.dataLoan,"Showssssss");
                this.urldetails=this.urls+data["guid"];
                console.log(this.urldetails);
                data['employer_phone_name'] = "("+data['employer_phone_name'].substring(0, 3)+")"+data['employer_phone_name'].substring(3, 6)+"-"+data['employer_phone_name'].substring(6, 10) || "";

                let reason_array = Array();

                this.dataLoan.reason.forEach(el => {
                  reason_array.push({ item_id: el.reason_id, item_text: el.description });
                });

                if(data['landline_number'] != null && data['landline_number'] != ""){
                  data['landline_number'] = "("+data['landline_number'].substring(0, 3)+")"+data['landline_number'].substring(3, 6)+"-"+data['landline_number'].substring(6, 10);
                } else {
                  data['landline_number'] = "-";
                }

                this.userShow = data;

                for(var i = 0; i < data['question_answers'].length; i++){
                  if(data['question_answers'][i]['question_id'] == 5){
                    var amount = data['question_answers'][i]['amount'];
                  }
                }

                this.request = this.fb.group({
                  user_id: [data['id'],Validators.required],
                  cell_phone_number: [data['cell_phone_number'],Validators.required],
                  amount: [amount,Validators.required],
                  login_id: [data['login_id'],Validators.required],
                  status: [data['status_id'],Validators.required],
                  conditions: [null],
                  reason: [reason_array],
                  description: [data['description'],Validators.required],
                  health_card: [data['health_card'],Validators.required],
                  driving_license: [data['driving_license'],Validators.required],
                  social_insurance: [data['social_insurance'],Validators.required],
                  identification_ID: [data['identification_ID'],Validators.required],
                });

                if(data['login_id'] != null) {
                  this.request.controls['login_id'].disable();
                } else {
                  this.request.controls['login_id'].enable();
                }

                if(data['status_id'] == 15){
                  this.getReason(data['status_id']);
                  this.request.controls['description'].disable();
                  this.request.controls['health_card'].disable();
                  this.request.controls['driving_license'].disable();
                  this.request.controls['social_insurance'].disable();
                  this.request.controls['identification_ID'].disable();
                } else {
                  this.request.controls['description'].enable();
                  this.request.controls['health_card'].enable();
                  this.request.controls['driving_license'].enable();
                  this.request.controls['social_insurance'].enable();
                  this.request.controls['identification_ID'].enable();
                }

                this.clientService.setStatusSeeing(user_id, 1).subscribe(data_st => {

                });

                this.showComponent(value);

              },
              error => {
                console.log(error);
          });
        }else{
          window.location.reload();
          // this.router.navigate([this.goBacks]);
        }
      });


    } else {

      this.showComponent(value);
    }

  }
  user_id(user_id: any, arg1: string) {
    throw new Error("Method not implemented.");
  }

  verifyStatus(){
    this.dropdown = false;
    // this.reasons.reasons = [];
    this.dropdownList = [];

    if(parseInt( this.request.value.status) === 15 /*|| parseInt(this.client_form.value.status) === 6*/){
      this.getReason(parseInt( this.request.value.status));
    }else{
      this.request.controls['reason'].clearValidators();
      this.request.controls['reason'].updateValueAndValidity();
    }

    // if(parseInt(this.request.value.status) !== 6){
    //   this.request.controls['amount'].clearValidators();
    //   this.request.controls['amount'].updateValueAndValidity();
    // }else{
    //   this.request.controls['amount'].setValidators(Validators.required);
    //   this.request.controls['amount'].updateValueAndValidity();
    // }
  }

  getReason(status_id){
    console.log(status_id)
    this.reasonService.getReason(status_id)
      .subscribe(res => {
        this.reasons = res;

        this.reasons.reasons.forEach(el => {
          this.dropdownList.push({ item_id: el.id, item_text: el.description });
        });

        this.dropdown = this.reasons.ok;

        this.request.controls['reason'].setValidators(Validators.required);
        this.request.controls['reason'].updateValueAndValidity();

        console.log(this.reasons.reasons.length, this.dropdownList, )
      });
  }

  fieldIsInvalid(option){

  }

  verifyOptions(){
    let notes = Array();

    // console.log(this.client_form.value.reason);

    for (let i = 0; i < this.request.value.reason.length; i++) {
      for (let j = 0; j < this.dropdownList.length; j++) {
        if(this.dropdownList[j].item_text === this.request.value.reason[i].item_text){
          notes.push(this.dropdownList[j].item_text);
        }
      }
    }

    this.request.controls['description'].setValue(notes.join('\n'))
  }

  getClientIbv(id) {
    console.log("get client");
    const user = this.tokenStorageService.getUser();

    // this.inveriteService.getIPAddress().then((res:any)=>{
    //   this.ipAddress=res.ip;
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'View renewal details',status: this.userShow.statu.description,userId: user.id,clientId: this.userIbv.id, nameClient : this.userIbv.first_name, email: this.userIbv.email, ip_address: this.ipAddress, if_renewal: this.userIbv.if_renewal};
    //   console.log(this.userIbv, 'data client');

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });


    // },err=>{
    //   console.log(err)

    //   this.ipAddress="190.36.186.3";
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'View renewal details',status: this.userShow.statu.description,userId: user.id,clientId: this.userIbv.id, nameClient : this.userIbv.first_name, email: this.userIbv.email, ip_address: this.ipAddress, if_renewal: this.userIbv.if_renewal};
    //   console.log(this.userIbv, 'data client');

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });


    // });

    this.IbvService.getClient(id)
      .subscribe(
        data => {
          this.userIbv = data;
          console.log(this.userIbv);
          if(data['ibv_client'] == null){
            this.existIbv = false;
          } else{
            this.existIbv = true;

            data['ibv_client']['phone'] = data['ibv_client']['phone'].replace("(","");
            data['ibv_client']['phone'] = data['ibv_client']['phone'].replace(")","");
            data['ibv_client']['phone'] = data['ibv_client']['phone'].replace("-","");
            data['ibv_client']['phone'] = data['ibv_client']['phone'].replace(" ","");

          }
           console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  reloadPage(){
    location.reload();
  }
  async createLoan(content){

    // alert()
        this.open(content);
        this.branchService.selectedBranch()
          .subscribe(
            data => {

              if(this.userShow.status_id == 15){
                this.toastrService.error('Client rejected.', 'Error', {
                  timeOut: 5000
                });
                this.modal.close();
              } else if(this.userShow.health_card != '' || this.userShow.driving_license != '' || this.userShow.social_insurance != '' || this.userShow.identification_ID != '' ){
                this.createBorrower(data[0]['value']);
              } else {
                this.toastrService.info("Health card, Driver's license, Social security, Identification ID.", 'You must complete at least one of the fields to generate the loan.', {
                  timeOut: 5000
                });

                this.modal.close();
              }
              this.showComponent(7);

            },
            error => {
              console.log(error);
            }
          );

      }

      searchStatus(){
        let id_status = parseInt(((document.getElementById("status") as HTMLInputElement).value));

        for(let i = 0; i < this.list_status.length; i++){
          if(this.list_status[i].id == id_status){
            this.new_status = this.list_status[i].description;
          }
        }
      }

      async createBorrower (branch_id){
        console.log(this.userShow,"createBorrowerxxxxx")
        console.log(this.userIbv);
        this.loan_pack.push(this.userShow);
        this.loan_pack.push(this.userIbv);
        this.clientService.getLoanID(this.userShow.id)
          .subscribe(
            data => {
              console.log(data,"getLoan");

              if(Object.keys(data).length > 0){
                this.toastrService.info('The loan has already been created.', 'Info', {
                  timeOut: 5000
                });
                this.modal.close();
              } else {

                  var date_ = new Date(this.userShow.date_birth);
                  var day, month, borrower_landline;

                  if(date_.getMonth() < 10){
                    month = date_.getMonth() + 1;
                    month = "0" + month;
                  } else {
                    month = date_.getMonth() + 1;
                  }

                  if(date_.getDate() < 10){
                    day = "0"+date_.getDate();
                  } else {
                    day = date_.getDate();
                  }

                  if(this.userShow.landline_number != null){
                    borrower_landline = this.userShow.landline_number.replace("(","");
                    borrower_landline = borrower_landline.replace(")","");
                    borrower_landline = borrower_landline.replace("-","");
                    borrower_landline = borrower_landline.replace(/_/g,"");
                  } else {
                    borrower_landline = '';
                  }

                  let data_borrower = {
                      borrower_unique_number: 'CID00000'+this.userShow.id,
                      borrower_firstname: this.userShow.first_name,
                      borrower_lastname: this.userShow.last_name,
                      borrower_country: "CA",
                      borrower_gender: (this.userShow.gender == "female") ? "Female" : "Male",
                      borrower_mobile: this.userShow.cell_phone_number,
                      borrower_email: this.userShow.email,
                      borrower_address: null,
                      borrower_city: null,
                      borrower_province: null,
                      borrower_zipcode: null,
                      borrower_landline: borrower_landline,
                      custom_field_5703: "Canada",
                      custom_field_5690: this.userShow.health_card,//Health card / Assurance Maladie
                      custom_field_5691: this.userShow.driving_license,//Driving License
                      custom_field_5692: this.userShow.social_insurance,//SIN
                      custom_field_5693: this.userShow.identification_ID,//Health card / Assurance Maladie
                      branch_id: branch_id,
                      client_id: this.userIbv.id,
                      loan_other: this.dataLoan.loan
                    };
                    // console.log(data_borrower)
                    this.borrower=data_borrower;
                    this.modal.close();

                    // this.loandiskService.createBorrower(data_borrower)
                    //   .subscribe(
                    //     data => {
                    //       console.log(data);
                    //       this.borrower_id = data;
                    //       this.userShow.borrower_id = this.borrower_id.response.borrower_id;
                    //       this.createLoanApi(this.borrower_id.response.borrower_id,branch_id);
                    //     },
                    //     error => {
                    //       console.log(error);
                    //     }
                    //   );
              }
            },
            error => {
              console.log(error);
            }
        );

      }
      open(content) {
        this.modal = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
        this.modal.result.then((e) => {

        });

      }

}
