import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';

@Component({
  selector: 'app-thanks-page',
  templateUrl: './thanks-page.component.html',
  styleUrls: ['./thanks-page.component.scss']
})
export class ThanksComponent implements OnInit {

  
  constructor(
    public googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
	let languaje = localStorage.getItem('ifrench');

	if(languaje == '1'){
		localStorage.clear();
		window.location.href = 'https://prestocash.ca/merci-prestocash/';
	}else{
		localStorage.clear();
		window.location.href = 'https://prestocash.ca/thank-you-prestocash/';
	}
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/thank-you', 'Follow User', 'userLabel', 1); 
  }

}
