import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Select2OptionData } from 'ng2-select2';
import { BranchService } from 'src/app/services/branch.service';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuditoryService } from 'src/app/services/auditory.service';
import { InveriteService } from 'src/app/services/inverite.service';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss']
})
export class BranchComponent implements OnInit {

  showNav = [true,false,false,false];//All,add,show,edit
  form: FormGroup;
  newBranch = new Object();
  branchShow = null;  
  list_branches = null;
  value: string[];
  countBranch= null;
  ipAddress = "";

  constructor(
    private fb: FormBuilder,
    private branchService: BranchService,
    private toastrService: ToastrService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private tokenStorageService: TokenStorageService,
    private auditory: AuditoryService,
    private inveriteService: InveriteService,
  ) { }

  ngOnInit() {
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/branch', 'Follow User', 'userLabel', 1);
    this.getBranches();
  }

  Show(value,branch_id, name){

    if(value == 4){//delete
      this.deleteBranch(branch_id, name);
    } else if(value == 1){//create
      this.form = this.fb.group({
        branch_name: [null,Validators.required],
        branch_value: [null,Validators.required]       
      });
      this.showComponent(value);
    } else if(value == 2){//view
      this.branchService.getBranch(branch_id)
        .subscribe(
          data => {
            this.branchShow = data;
            this.showComponent(value); 
          },
          error => {
            console.log(error);
      });
    } else if(value == 3){//edit
      this.branchService.getBranch(branch_id)
        .subscribe(
          data => {
            this.branchShow = data;
            const user = this.tokenStorageService.getUser();
            // this.inveriteService.getIPAddress().subscribe((res:any)=>{  
            //   this.ipAddress=res.ip; 
            //   console.log(this.ipAddress)
            //   let datas = {nameUser: user.username,action: 'View branch of name '+name,status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

            //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
            //     console.log(data);
            //   });

            // },err=>{
            //   console.log(err)
              
            //   this.ipAddress="190.36.186.3";
            //   console.log(this.ipAddress)
            //   let datas = {nameUser: user.username,action: 'View branch of name '+name,status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

            //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
            //     console.log(data);
            //   });

            // }); 
            this.showComponent(value); 
             //console.log(this.value);
            this.form = this.fb.group({
              branch_name: [data['name'],Validators.required],
              branch_value: [data['value'],Validators.required]
            });
          },
          error => {
            console.log(error);
      });
    } else {//list
      this.form = this.fb.group({
        branch_name: [null,Validators.required],
        branch_value: [null,Validators.required]
        
      });
      
      this.showComponent(value);
    } 
  }

  showComponent(value){
    for (var i = 0; i < this.showNav.length; i++) {

      if(i == value){
        this.showNav[i] = true;
      } else {
        this.showNav[i] = false;
      }
    }
  }

  saveBranch(form) {

    if(form.value.branch_name == null || form.value.branch_value == null){
      this.toastrService.error("empty fields", 'Info', {
        timeOut: 5000
      });
    }
    
    // this.newBranch['id'] = 0;
    this.newBranch['branch_name'] = form.value.branch_name;
    this.newBranch['branch_value'] = form.value.branch_value;
    
    if(form.value.branch_name && form.value.branch_value){

      const user = this.tokenStorageService.getUser();
      // this.inveriteService.getIPAddress().subscribe((res:any)=>{  
      //   this.ipAddress=res.ip; 
      //   console.log(this.ipAddress)
      //   let datas = {nameUser: user.username,action: 'Create branch of name '+this.newBranch['branch_name'],status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

      //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
      //     console.log(data);
      //   });

      // },err=>{
      //   console.log(err)
        
      //   this.ipAddress="190.36.186.3";
      //   console.log(this.ipAddress)
      //   let datas = {nameUser: user.username,action: 'Create branch of name '+this.newBranch['branch_name'],status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

      //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
      //     console.log(data);
      //   });

      // }); 

      this.branchService.createBranch(this.newBranch)
        .subscribe(
          data => {       
            //console.log(data);
            this.toastrService.success(data['message'], 'Success', {
              timeOut: 5000
            });
            this.getBranches();
            this.Show(0,0,'');
          },
          err => {
            this.toastrService.error(err.error.message, 'Error', {
              timeOut: 5000
            });
            console.log(err.error.message);
      });
    }
    
    

  }

  deleteBranch(branch_id, name) {

    const user = this.tokenStorageService.getUser();
    // this.inveriteService.getIPAddress().subscribe((res:any)=>{  
    //   this.ipAddress=res.ip; 
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'Delete branch '+name,status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // },err=>{
    //   console.log(err)
      
    //   this.ipAddress="190.36.186.3";
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'Delete branch '+name,status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // }); 

    this.branchService.deleteBranch(branch_id)
    .subscribe(
      data => {
        // this.currentRoles = data;          
        // console.log(data);
        this.toastrService.success(data['message'], 'Success', {
          timeOut: 5000
        });
        
        this.getBranches();
        this.Show(0,0,'');
      },
      err => {
        console.log(err.error.message);
  });
  }

  updateBranch(form,branch_id) {

    if(form.value.branch_name == null || form.value.branch_value == null){
      this.toastrService.info('empty fields', 'Info', {
        timeOut: 5000
      });
    }
    
    // this.newBranch['id'] = branch_id;
    this.newBranch['name'] = form.value.branch_name;
    this.newBranch['value'] = form.value.branch_value;

    if(form.value.branch_name != null && form.value.branch_value != null){
      // console.log(this.newUser);

      const user = this.tokenStorageService.getUser();
      // this.inveriteService.getIPAddress().subscribe((res:any)=>{  
      //   this.ipAddress=res.ip; 
      //   console.log(this.ipAddress)
      //   let datas = {nameUser: user.username,action: 'Update branch to '+this.newBranch['name'],status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

      //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
      //     console.log(data);
      //   });

      // },err=>{
      //   console.log(err)
        
      //   this.ipAddress="190.36.186.3";
      //   console.log(this.ipAddress)
      //   let datas = {nameUser: user.username,action: 'Update branch to '+this.newBranch['name'],status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

      //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
      //     console.log(data);
      //   });

      // }); 

      this.branchService.updateBranch(branch_id,this.newBranch)
        .subscribe(
          data => {
            // console.log(data);
            this.toastrService.success(data['message'], 'Success', {
              timeOut: 5000
            });
            this.getBranches();
            this.Show(0,0,'');
          },
          err => {
            this.toastrService.error(err.error.message, 'Error', {
              timeOut: 5000
            });
            // console.log(err.error.message);
      });
    } 

  }

  getBranches(){
    const user = this.tokenStorageService.getUser();
    // this.inveriteService.getIPAddress().subscribe((res:any)=>{  
    //   this.ipAddress=res.ip; 
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'view list of branch',status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // },err=>{
    //   console.log(err)
      
    //   this.ipAddress="190.36.186.3";
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'view list of branch',status: null,userId: user.id,clientId: null, nameClient : null, email: null, ip_address: this.ipAddress, if_renewal: null};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // }); 

    this.branchService.branches()
      .subscribe(
        data => {
          // console.log(data.length);
           this.list_branches = data;
           this.countBranch = Object.keys(data).length;
        },
        error => {
          console.log(error);
        });
  }

  handleChange(evt, name){ 
    // this.displayParameter = evt;

    var data;

    for(var i = 1; i <= this.countBranch ; i++){

      if(i == evt){
        data = { selected: 1};
      } else {
        data = { selected: 0};
      }
      
      this.branchService.updateBranch(i,data)
        .subscribe(
          data => {
            // console.log(data);      
          },
          err => {
            this.toastrService.error(err.error.message, 'Success', {
              timeOut: 5000
            });
            // console.log(err.error.message);
      });
    }

    const user = this.tokenStorageService.getUser();
    // this.inveriteService.getIPAddress().subscribe((res:any)=>{  
    //   this.ipAddress=res.ip; 
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'change branch branch to '+name,status: '',userId: user.id,clientId: '', nameClient : '', email: '', ip_address: this.ipAddress, if_renewal: ''};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // },err=>{
    //   console.log(err)
      
    //   this.ipAddress="190.36.186.3";
    //   console.log(this.ipAddress)
    //   let datas = {nameUser: user.username,action: 'change branch branch to '+name,status: '',userId: user.id,clientId: '', nameClient : '', email: '', ip_address: this.ipAddress, if_renewal: ''};

    //   this.auditory.createRegisterAuditory(datas).subscribe(data => {
    //     console.log(data);
    //   });

    // }); 
    
    this.toastrService.success("Selected Branch", 'Success', {
      timeOut: 5000
    });
      
    
} 
}
