import { Component, OnInit, Input,SimpleChanges,Output, EventEmitter } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { StatuService } from 'src/app/services/statu.service';
import { BranchService } from 'src/app/services/branch.service';
import { LoandiskService } from 'src/app/services/loandisk.service';
import { Ibv } from 'src/app/services/ibv.service';
import { QuestionAnswerService } from 'src/app/services/question-answer.service';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef  } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { MailService } from "../../services/mail.service";
import * as moment from 'moment';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { AuditoryService } from 'src/app/services/auditory.service';
import { InveriteService } from 'src/app/services/inverite.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss']
})
export class LoanComponent implements OnInit {
  @Input() loan_pack;
  @Input() borrower;
  borrowerLoan;
  userShowLoan = null;
  ivbUserLoan;
  institutionselected;
  message_loan = '';
  data_loan = null;
  data_loan_api = null;
  solvent = true;
  id_loan = null;
  transit_number = null;
  loan:FormGroup;
  account_number
  account_number_seected;
  loan_num_of_repayments =3;
  ipAddress = "";
  @Output() backing = new EventEmitter<number>();
  private borrower_id = null;
  quantity_payments = 0;
  constructor(
    private modalService: NgbModal,
    private loandiskService: LoandiskService,
    private MailService: MailService,
    private branchService: BranchService,
    private clientService: ClientService,
    private statuService: StatuService,
    private questionAnswerService: QuestionAnswerService,
    private IbvService: Ibv,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private tokenStorageService: TokenStorageService,
    private auditory: AuditoryService,
    private inveriteService: InveriteService,
    private rutaActiva: ActivatedRoute
    ) { }

  ngOnInit() {
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/loan', 'Follow User', 'userLabel', 1);

    this.rutaActiva.params
      .pipe(
        switchMap(({ id }) => this.clientService.getDataClientLoan(id))
      )
      .subscribe(res => {
        this.userShowLoan = res;
        this.changeForm();
      })
  }
  sending=false;

  createLoan(value){
    console.log(value, this.userShowLoan, this.borrowerLoan);

    this.sending=true;

    // return '';
    if(this.userShowLoan.borrower_id){
      this.createLoanApi(value, this.userShowLoan.borrower_id);
      this.updateDataClient(value, this.userShowLoan.id);
    }else{
      let borrower_employer = this.userShowLoan.employer_phone_name.replace("(","");
      borrower_employer = borrower_employer.replace(")","");
      borrower_employer = borrower_employer.replace("-","");
      borrower_employer = borrower_employer.replace(/_/g,"");
      let data_borrower = {
        borrower_unique_number: this.borrowerLoan.borrower_unique_number,
        borrower_firstname: value.name,
        borrower_lastname: value.last_name,
        borrower_country: this.borrowerLoan.borrower_country,
        borrower_gender: value.gender,
        borrower_mobile: value.mobile_number,
        borrower_email: value.email,
        borrower_address: value.address,
        borrower_city: value.city,
        borrower_province: value.province,
        borrower_zipcode: value.zip_code,
        custom_field_8611: value.employeer,
        borrower_landline: this.borrowerLoan.borrower_landline,
        custom_field_5703: this.borrowerLoan.custom_field_5703,
        custom_field_5690: this.borrowerLoan.custom_field_5690,//Health card / Assurance Maladie
        custom_field_5691: this.borrowerLoan.custom_field_5691,//Driving License
        custom_field_5692: this.borrowerLoan.custom_field_5692,//SIN
        custom_field_5693: this.borrowerLoan.custom_field_5693,//Health card / Assurance Maladie
        custom_field_5934: this.userShowLoan.name_reference_one,
        custom_field_5936: this.userShowLoan.name_reference_one_number,
        custom_field_5935: this.userShowLoan.name_reference_two,
        custom_field_5937: this.userShowLoan.name_reference_two_number,
        custom_field_6370:borrower_employer,
        // /this.userShowLoan
        branch_id: this.borrowerLoan.branch_id
      };
      // console.log(data_borrower);
      this.loandiskService.createBorrower(data_borrower)
      .subscribe(
        data => {
          // console.log(data);
          this.borrower_id = data;
          this.userShowLoan.borrower_id = this.borrower_id.response.borrower_id;
          this.createLoanApi(value, this.borrower_id.response.borrower_id);
          this.updateDataClient(value, this.userShowLoan.id);
        },
        error => {
          console.log(error);
        }
      );
    }

    // this.createLoanApi(value);
  }

  createLoanApi(value, borrower_id){
    console.log(value,"value...",this.quantity_payments)
    let loan_released_date = new Date();
    let day, month;

    if(loan_released_date.getMonth() < 9){
      month = loan_released_date.getMonth() + 1;
      month = "0" + month;
    } else {
      month = loan_released_date.getMonth() + 1;
    }

    if(loan_released_date.getDate() < 10){
      day = "0"+loan_released_date.getDate();
    } else {
      day = loan_released_date.getDate();
    }
    let cont=0;
    this.userShowLoan.ibv_accounts.find(resp=>{
      // console.log(resp,this.loan.value.transit_number)
      if(resp.id==parseInt(this.loan.value.transit_number)){
        this.account_number=this.userShowLoan.ibv_accounts[cont].transit;
        // console.log(cont)

      }
      cont++
    })
    let borrower_employer = this.userShowLoan.employer_phone_name.replace("(","");
    borrower_employer = borrower_employer.replace(")","");
    borrower_employer = borrower_employer.replace("-","");
    borrower_employer = borrower_employer.replace(/_/g,"");
    // console.log(day + "/" + month + "/" + loan_released_date.getFullYear())
    var data_ = {
      loan_product_id: 67680,//Flexi-Loan
      borrower_id: this.userShowLoan.borrower_id,
      loan_application_id: 'PR100000'+this.userShowLoan.id,
      loan_disbursed_by_id: 51498,//Interac E-transfer
      loan_principal_amount: value.amount,
      loan_released_date: day + "/" + month + "/" + loan_released_date.getFullYear(),//Date now moment(''+value.loan_first_repaiment).format('DD/MM/YYYY'),
      loan_interest_method: "compound_interest",
      loan_interest_type: "percentage",
      loan_interest: 23,
      loan_interest_period: "Year",
      loan_interest_schedule: "charge_interest_normally",
      loan_duration_period: "Months",
      loan_duration: value.duration,
      loan_payment_scheme_id: value.repayment_cycle,//by-weekly
      loan_num_of_repayments: this.loan_num_of_repayments,
      automatic_payments: 1,//yes
      payment_posting_period: value.payment_posting_period,//04.01am - 08.00am
      loan_fee_id_4065: value.loan_fee_id_4065,//Credit Protection Membership
      loan_fee_schedule_4065: "distribute_fees_evenly",//How should Credit Protection Membership be charged in Loan Schedule? evenly
      loan_decimal_places: "round_off_to_two_decimal",
      loan_status_id: 8,//Processing
      custom_field_3271: "Yes",
      custom_field_5696: this.account_number_seected,
      custom_field_5695: this.transit_number,
      custom_field_5694: this.institutionselected,
      custom_field_5701: "API KEY",
      custom_field_5702: "Hashed Signature (VOPAY)",
      custom_field_6370:borrower_employer,
      branch_id: this.borrowerLoan.branch_id,
      loan_first_repayment_date: moment(''+value.loan_first_repaiment).format('DD/MM/YYYY')
    }
// console.log(data_);
    this.loandiskService.createLoan(data_)
        .subscribe(
          data => {
            // console.log(data);

            // this.modal.close();
            let borrower_employer = this.userShowLoan.employer_phone_name.replace("(","");
            borrower_employer = borrower_employer.replace(")","");
            borrower_employer = borrower_employer.replace("-","");
            borrower_employer = borrower_employer.replace(/_/g,"");
            if(typeof data['response']['Errors'] === 'undefined'){

              var data2_ = {
                loan_id: data['response']['loan_id'],
                loan_product_id: 67680,//Flexi-Loan
                borrower_id: borrower_id,
                loan_application_id: 'PR100000'+this.userShowLoan.id,
                loan_disbursed_by_id: 51498,//Interac E-transfer
                loan_principal_amount: value.amout,
                loan_released_date: day + "/" + month + "/" + loan_released_date.getFullYear(),//Date now moment(''+value.loan_first_repaiment).format('DD/MM/YYYY'),
                loan_interest_method: "compound_interest",
                loan_interest_type: "percentage",
                loan_interest: 23,
                loan_interest_period: "Year",
                loan_interest_schedule: "charge_interest_normally",
                loan_duration_period: "Months",
                loan_duration: value.duration,
                loan_payment_scheme_id: value.repayment_cycle,//by-weekly
                loan_num_of_repayments: this.loan_num_of_repayments,
                automatic_payments: 1,//yes
                payment_posting_period: value.payment_posting_period,//04.01am - 08.00am
                loan_fee_id_4065: value.loan_fee_id_4065,//Credit Protection Membership
                loan_fee_schedule_4065: "distribute_fees_evenly",//How should Credit Protection Membership be charged in Loan Schedule? evenly
                loan_decimal_places: "round_off_to_two_decimal",
                loan_status_id: 8,//Processing
                custom_field_3271: "Yes",
                custom_field_5696: this.account_number_seected,
                custom_field_5695: this.transit_number,
                custom_field_5694: this.institutionselected,
                custom_field_5701: "API KEY",
                custom_field_6370:borrower_employer,
                custom_field_5702: this.userShowLoan.first_name+" "+this.userShowLoan.last_name+" "+data['response']['loan_id'],
                branch_id: this.borrowerLoan.branch_id,
                loan_first_repayment_date: moment(''+value.loan_first_repaiment).format('DD/MM/YYYY')
              }
              //console.log(data2_);
              const user = this.tokenStorageService.getUser();

              this.ipAddress = localStorage.getItem('ip') || "190.36.186.3";
              // console.log(this.ipAddress)
              let datas = {nameUser: user.username,action: 'Create new loan',status: null, new_status: null, userId: user.id,clientId: this.borrowerLoan.client_id, nameClient : this.borrowerLoan.borrower_firstname, email: this.borrowerLoan.borrower_email, ip_address: this.ipAddress, if_renewal: this.borrowerLoan.if_renewal,updated_file: 1};

              this.auditory.createRegisterAuditory(datas).subscribe(data => {
                // console.log(data);
              });

              this.loandiskService.updateLoan(data2_)
                  .subscribe(data__ => {
                      //console.log(data__);
                      let data_loan = {
                        loan_id: data['response']['loan_id'],
                        client_id: this.userShowLoan.id
                      };

                      this.loandiskService.createLoanLocal(data_loan)
                        .subscribe(data => {
                            this.toastrService.success('Borrower and Loan successfully created.', 'Success', {
                              timeOut: 5000
                            });
                            // this.modal.close();
                            this.sending=false;

                          },
                          error => {
                            console.log(error);
                      });

                      var data_ = { borrower_id:  borrower_id};
                      //console.log(data_);
                      this.clientService.updateClient(this.userShowLoan.id,data_)
                        .subscribe(
                          data => {

                          },
                          error => {
                            console.log(error);
                          }
                      );
                    },
                    error => {
                      console.log(error);
                });


                if(loan_released_date.getMonth() < 10){
                  month = loan_released_date.getMonth() + 1;
                  month = "0" + month;
                } else {
                  month = loan_released_date.getMonth() + 1;
                }

                if(loan_released_date.getDate() < 10){
                  day = "0"+loan_released_date.getDate();
                } else {
                  day = loan_released_date.getDate();
                }
            } else {
              this.toastrService.error("Error: "+data['response']['Errors'], 'Error', {
                timeOut: 5000
              });
              this.sending=false;

              // this.modal.close();
            }

          },
          error => {
            console.log(error);
          }
      );

    // console.log(data_);
  }

  back(){
    this.backing.emit(1);
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    // this.borrowerLoan=changes.borrower.currentValue;
    // console.log(changes,"xxx");

    if(changes.loan_pack){
      this.ivbUserLoan = changes.loan_pack.currentValue[1];
      // this.userShowLoan = changes.loan_pack.currentValue[0];
    }

    if(changes.borrower){
      this.borrowerLoan=changes.borrower.currentValue;
      // this.changeForm();
    }

  }

  changeForm(){
    // console.log(this.userShowLoan, 'el loan');
    // console.log(this.borrowerLoan);
    let amount = 0;
    // for(let i = 0; i < this.userShowLoan.question_answers.length; i++){
    //   if(this.userShowLoan.question_answers[i].amount != null){
    //     amount = this.userShowLoan.question_answers[i].amount;
    //     break;
    //   }
    // }
    console.log('Hola mundo form')
    // console.log(this.ivbUserLoan,"cscscsc");
    let loan_released_date = new Date();
    let month,day,dates
    if(loan_released_date.getMonth() < 10){
      month = loan_released_date.getMonth() + 1;
      month = "0" + month;
    } else {
      month = loan_released_date.getMonth() + 1;
    }

    if(loan_released_date.getDate() < 10){
      day = "0"+loan_released_date.getDate();
    } else {
      day = loan_released_date.getDate();
    }
    dates=`${loan_released_date.getFullYear()}-${month}-${day}`;//Date now
    console.log(dates+' La fecha');
    let birtDate= null;
    // console.log(this.userShowLoan, "data de los loan");
    if(this.userShowLoan.date_birth!=null)
      birtDate=this.userShowLoan.date_birth.split("/");
      // this.ivbUserLoan.ibv_client.ibv_accounts[0].transit
      // console.log(birtDate, "fecha del dia")
      // console.log(this.ivbUserLoan, "detalles del cliente");

      let amount_value = 0;

      try{
        amount_value = this.userShowLoan.question_answers.find(el => el.amount !== null).amount;
      }catch(e){
        amount_value = 0;
      }

      this.loan = this.fb.group({
        name: [this.userShowLoan.first_name,Validators.required],
        last_name: [this.userShowLoan.last_name,Validators.required],
        email: [this.userShowLoan.email,Validators.required],
        mobile_number: [this.userShowLoan.cell_phone_number,Validators.required],
        dbo: [birtDate[0]==null?"":`${birtDate[2]}-${birtDate[1]}-${birtDate[0]}`,Validators.required],
        gender: [this.userShowLoan.gender,Validators.required],
        address: [this.userShowLoan.address,Validators.required],
        country: ['Canada',Validators.required],
        city: [this.userShowLoan.city_id,Validators.required],
        province: [this.userShowLoan.province.description,Validators.required],
        zip_code: [this.userShowLoan.postal_code,Validators.required],
        amount: [amount_value,Validators.required],
        duration: [3,Validators.required],
        payment_posting_period: ['2', Validators.required],
        loan_num_of_repayments: [this.loan_num_of_repayments, Validators.required],
        repayment_cycle: [3,Validators.required],
        loan_fee_id_4065: [252,Validators.required],
        loan_first_repaiment: [dates,Validators.required],
        // overdraft: ["",Validators.required],
        transit_number: ['',Validators.required],
        account_number: ['',Validators.required],
        institution: ['',Validators.required],
        employeer: [this.userShowLoan.employer_name,Validators.required],
        // borrower: [this.borrowerLoan.borrower_id_client,Validators.required]
      });
      // this.account_number_seected = this.ivbUserLoan.ibv_client.ibv_accounts[0]?this.ivbUserLoan.ibv_client.ibv_accounts[0].account:'';

    // this.searchEmail(this.userShowLoan.email, this.userShowLoan.branch_id);
  }

  searchEmail(email, branch){
    let data = { email : email };
    this.clientService.searchClientEmail(data)
    .subscribe(data_ => {
      this.data_loan = data_;
      for(let i = 0; i < this.data_loan.length; i++){
        // console.log(this.data_loan[i]);
        this.loandiskService.getDataLoan(this.data_loan[i], branch)
        .subscribe(loan_data => {
          this.data_loan_api = loan_data;
          this.data_loan_api.response.Results.forEach(loan_user => {
            if(parseFloat(loan_user.balance_amount) > 0 && parseInt(loan_user.loan_status_id) !== 8 && parseInt(loan_user.loan_status_id) !== 9 && parseInt(loan_user.loan_status_id) !== 17){
              this.solvent = false;
              this.message_loan = `The client owns a debt of ${loan_user.balance_amount}$`;
              this.id_loan = this.data_loan[i];
              return;
            }
          });
          // let balance = parseFloat(this.data_loan_api.response.Results[0].balance_amount);
          // console.log(this.data_loan_api);
          // if(balance > 0){
          //   this.solvent = false;
          //   this.message_loan = `The client owns a debt of ${balance}$`;
          //   this.id_loan = this.data_loan[i];
          // }
          // console.log(this.data_loan_api.response.Results[0].balance_amount);
        });
      }
    });
  }

  calculate(){
    // this.quantity_payments = this.userShowLoan.question_answers[13] != undefined ? this.userShowLoan.question_answers[13].answer_id == 51 ? 3 : 4 : null;
    // return this.quantity_payments;
    return 0;
  }
  search(){
    let cont=0;
    let loan_released_date = new Date();
    let month,day,dates
    if(loan_released_date.getMonth() < 10){
      month = loan_released_date.getMonth() + 1;
      month = "0" + month;
    } else {
      month = loan_released_date.getMonth() + 1;
    }

    if(loan_released_date.getDate() < 10){
      day = "0"+loan_released_date.getDate();
    } else {
      day = loan_released_date.getDate();
    }
    let amount = 0;
    for(let i = 0; i < this.userShowLoan.question_answers.length; i++){
      if(this.userShowLoan.question_answers[i].amount != null){
        amount = this.userShowLoan.question_answers[i].amount;
        break;
      }
    }

    dates=loan_released_date.getFullYear()+"-"+month+"-"+day,//Date now
    // console.log(this.loan.value.transit_number)
    this.userShowLoan.ibv_accounts.find(resp=>{
      // console.log(resp,this.loan.value.transit_number)
      if(resp.id==parseInt(this.loan.value.transit_number)){
        this.account_number=this.userShowLoan.ibv_accounts[cont].account;

        (document.getElementById("account_number") as HTMLInputElement).value = this.userShowLoan.ibv_accounts[cont].account;
        this.account_number_seected = this.userShowLoan.ibv_accounts[cont].account;

        (document.getElementById("institution") as HTMLInputElement).value = this.userShowLoan.ibv_accounts[cont].institution;
        this.institutionselected = this.userShowLoan.ibv_accounts[cont].institution;
        this.transit_number = this.userShowLoan.ibv_accounts[cont].transit;
        // alert(this.ivbUserLoan.ibv_client.ibv_accounts[cont].account);
        // console.log(cont)
        let birtDate=this.userShowLoan.date_birth.split("T");
      // this.ivbUserLoan.ibv_client.ibv_accounts[0].transit
        // console.log(birtDate)
        // this.loan = this.fb.group({
        //   name: [this.userShowLoan.first_name,Validators.required],
        //   last_name: [this.userShowLoan.last_name,Validators.required],
        //   email: [this.userShowLoan.email],
        //   mobile_number: [this.userShowLoan.cell_phone_number,Validators.required],
        //   dbo: [birtDate[0],Validators.required],
        //   gender: [this.userShowLoan.gender,Validators.required],
        //   address: [this.userShowLoan.address != null? this.userShowLoan.address : null,Validators.required],
        //   country: ['Canada',Validators.required],
        //   city: [this.userShowLoan.city_id != null? this.userShowLoan.city_id : null,Validators.required],
        //   province: [this.userShowLoan.province_id !=null ? this.userShowLoan.province.description : null,Validators.required],
        //   zip_code: [this.userShowLoan.postal_code !=null ? this.userShowLoan.postal_code : null,Validators.required],
        //   amount: [amount,Validators.required],
        //   duration: [this.userShowLoan.question_answers[13].answer_id == 51 ? 3 : 4,Validators.required],
        //   payment_posting_period: ['2', Validators.required],
        //   loan_num_of_repayments: [this.calculate(), Validators.required],
        //   repayment_cycle: ['3',Validators.required],
        //   loan_fee_id_4065: [this.userShowLoan.question_answers[13].answer_id == 51 ? 252 : 352,Validators.required],
        //   loan_first_repaiment: [dates,Validators.required],
        //   // overdraft: ["",Validators.required],
        //   transit_number: [this.ivbUserLoan.ibv_client.ibv_accounts[cont].id,Validators.required],
        //   account_number: [this.ivbUserLoan.ibv_client.ibv_accounts[cont].account,Validators.required],
        //   institution: [this.ivbUserLoan.ibv_client.ibv_accounts[cont].institution,Validators.required],
        // });

      }
      cont++
    })
  }

  calculateFees(){
    // (document.getElementById("shared") as HTMLInputElement).value = ""+moment(''+value).format('DD/MM/YYYY');
    let duration = parseInt(((document.getElementById("duration") as HTMLInputElement).value));
    let repayment_cycle = parseInt(((document.getElementById("repayment_cycle") as HTMLInputElement).value));

    if(repayment_cycle == 3){
      this.quantity_payments = duration;
      (document.getElementById("loan_num_of_repayments") as HTMLInputElement).value = ""+(duration);
      this.loan_num_of_repayments = duration;
    }

    if(repayment_cycle == 9){
      this.quantity_payments = duration * 2;
      (document.getElementById("loan_num_of_repayments") as HTMLInputElement).value = ""+(duration * 2);
      this.loan_num_of_repayments = duration * 2;
    }

    if(repayment_cycle == 4){
      this.quantity_payments = duration * 4;
      (document.getElementById("loan_num_of_repayments") as HTMLInputElement).value = ""+(duration * 4);
      this.loan_num_of_repayments = duration * 4;
    }

    if(repayment_cycle == 12){
      this.quantity_payments = duration / 2 ;
      (document.getElementById("loan_num_of_repayments") as HTMLInputElement).value = ""+Math.round(duration / 2);
      this.loan_num_of_repayments = duration / 2;
    }
  }

  updateDataClient(value, client_id){

    let data_client = {
      first_name: value.name,
      last_name: value.last_name,
      email: value.email,
      cell_phone_number: value.mobile_number,
      date_birth: value.dbo,
      gender: value.gender,
      address: value.address,
      city_id: value.city,
      borrower_id: this.borrower_id.response.borrower_id,

      postal_code: value.zip_code,
    }
// console.log(data_client, this.borrower_id.response.borrower_id, 'data nueva');
    let question_answer5 = {
      amount: value.amount
    }

    let question_answer13 = {
      answer_id: value.duration
    }

    let account_data = {
      transit: value.transit_number
    }

    this.clientService.updateClient(client_id, data_client)
    .subscribe(data => {
      // console.log(data);
    });

    this.questionAnswerService.updateAmount(client_id, question_answer5)
    .subscribe(data => {
      // console.log(data);
    });

    // console.log(question_answer13);
    // console.log(account_data);
    // console.log(client_id);

      // borrower_province: value.province,

  }

}
