import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-ibv',
  templateUrl: './ibv.component.html',
  styleUrls: ['./ibv.component.scss']
})
export class IbvComponent implements OnInit{

  iframeFlinks = environment.production == false ? environment.flinks_page : environment.flinks_page;
  urlFlinks!: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute
  ) {

   }

  ngOnInit() {
    this.urlFlinks = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeFlinks);

    this.activatedRoute.params
      .subscribe(({id}) => {
        if(id){
          localStorage.setItem('client_id', id);
        }
      });
  }


}
