import { Output, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlinksService } from 'src/app/services/flinks.service';

@Component({
  selector: 'app-bank-details-inverite',
  templateUrl: './bank-details-inverite.component.html',
  styleUrls: ['./bank-details-inverite.component.scss']
})
export class BankDetailsInveriteComponent implements OnInit {
  @Input() data
  @Input() user
  @Input() urls
  @Output() backs = new EventEmitter<string>();
  current: any;
  load=false;
  dataUser: string;
  request: any;
  loadInverite: boolean=false;
  loadPayment=false;
  loadStadistics=false;
  transacctions: any;
  payment: any;
  stadistics: any;
  link
  id: any;
  goBacks: boolean;
  constructor(
    private flinksService:FlinksService,
    private router:Router,

  ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    // alert();
    console.log(this.router.url);
    let change=this.router.url.split("/")
    if(change[2]=='rew'){
      this.goBacks=true;
    }else{
      this.goBacks=false
    }
    console.log(changes.user.currentValue)
    this.id=changes.user.currentValue
    this.current=changes.data.currentValue;
    this.link=changes.urls.currentValue
    console.log(this.current);
    this.flinksService.getAccountsDetail(this.current).subscribe(resp=>{
      console.log(JSON.parse(resp[0]['full_data']))
      this.request=JSON.parse(resp[0]['full_data'])
      this.flinksService.setAllAcount(this.request)
      this.load=true
      this.loadInverite=false
      this.loadPayment=false
      this.loadStadistics=false
})
  }
  check(request_id){
    this.transacctions=this.flinksService.getAllDataInverite(request_id);
    this.loadInverite=true
    this.loadPayment=false
    this.loadStadistics=false

    console.log(this.transacctions)
  }
  pay(request_id){
    this.payment=this.flinksService.getAllDataInverite(request_id);
    this.loadInverite=false
    this.loadPayment=true
    this.loadStadistics=false
  }
  backDetail(){
    this.backs.emit(this.dataUser);
  }

  stadistic(request_id){
    this.stadistics=this.flinksService.getAllDataInverite(request_id);
    this.loadInverite=false
    this.loadPayment=false
    this.loadStadistics=true
  }

}
