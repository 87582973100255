import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/ibv';

@Injectable({
  providedIn: 'root'
})

export class Ibv {

  constructor(private http: HttpClient) { }

  addClient(data) {
    return this.http.post(`${baseUrl}/client`,data);
  }
  
  addContact(data) {
    return this.http.post(`${baseUrl}/contact`,data);
  }

  addAccount(data){
    return this.http.post(`${baseUrl}/account`,data);
  }

  addStatistic(data){
    return this.http.post(`${baseUrl}/statistic`,data);
  }

  addPayschedule(data){
    return this.http.post(`${baseUrl}/payschedule`,data);
  }

  addPayment(data){
    return this.http.post(`${baseUrl}/payment`,data);
  }

  addFuturePayment(data){
    return this.http.post(`${baseUrl}/futurePayment`,data);
  }

  addMissedPayment(data){
    return this.http.post(`${baseUrl}/missedPayment`,data);
  }

  addTransaction(data){
    return this.http.post(`${baseUrl}/transaction`,data);
  }

  getClient(id){
    return this.http.get(`${baseUrl}/getClient/${id}`);
  }

  getDetailsAccount(id){
    return this.http.get(`${baseUrl}/getDetailsAccount/${id}`);
  }

  getDetailsStatistic(id){
    return this.http.get(`${baseUrl}/getDetailsStatistic/${id}`);
  }

}