import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { Administrator } from '../components/interfaces/auditory-log';

const baseUrl = environment.url_server+'/api/auditory';

@Injectable({
  providedIn: 'root'
})
export class AuditoryService {

  constructor(private http: HttpClient) { }

  createRegisterAuditory(data) {
    return this.http.post(`${baseUrl}/add/register`, data);
  }

  getList(page, params){
    console.log(params);
    return this.http.get(`${baseUrl}/getList/${page}/${params ? params.username : null}/${params ? params.startDate : null}/${params ? params.endDate : null}`);
  }

  getAuditoryClient(id){
    return this.http.get(`${baseUrl}/getAuditoryClient/${id}`);
  }

  getPercentFilesApprobedRejectUser(dateStart: string, dateEnd: string): Observable<Administrator>{
    return this.http.get<Administrator>(`${baseUrl}/getPercentFilesApprobedRejectUser/${dateStart}/${dateEnd}`);
  }
}
