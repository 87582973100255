import { Component, OnInit } from '@angular/core';
import { FlinksService } from 'src/app/services/flinks.service';

@Component({
  selector: 'app-save-ibv',
  templateUrl: './save-ibv.component.html',
  styles: [`
    img {
      width: 10%;
      padding-top: 5%;
    }

    p {
      font-size: 5.5em;
    }

    span {
      color: #3f51b5;
    }

    .title-p {
      font-size: 65px; font-weight: 600;
    }
    .sub-title-p {
      font-size: 60px;
    }
    .content-title-p {
      font-size: 55px;
    }
    .footer-title-p {
      font-size: 45px; font-weight: 600;
    }

    .text-footer-p{
      font-size: 3em;
      color: #3f51b5;
    }

    .lds-spinner {
      color: #3f51b5;
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-spinner div {
      transform-origin: 40px 40px;
      animation: lds-spinner 1.2s linear infinite;
    }
    .lds-spinner div:after {
      content: " ";
      display: block;
      position: absolute;
      top: 3px;
      left: 37px;
      width: 6px;
      height: 18px;
      border-radius: 20%;
      background: #3f51b5;
    }
    .lds-spinner div:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.1s;
    }
    .lds-spinner div:nth-child(2) {
      transform: rotate(30deg);
      animation-delay: -1s;
    }
    .lds-spinner div:nth-child(3) {
      transform: rotate(60deg);
      animation-delay: -0.9s;
    }
    .lds-spinner div:nth-child(4) {
      transform: rotate(90deg);
      animation-delay: -0.8s;
    }
    .lds-spinner div:nth-child(5) {
      transform: rotate(120deg);
      animation-delay: -0.7s;
    }
    .lds-spinner div:nth-child(6) {
      transform: rotate(150deg);
      animation-delay: -0.6s;
    }
    .lds-spinner div:nth-child(7) {
      transform: rotate(180deg);
      animation-delay: -0.5s;
    }
    .lds-spinner div:nth-child(8) {
      transform: rotate(210deg);
      animation-delay: -0.4s;
    }
    .lds-spinner div:nth-child(9) {
      transform: rotate(240deg);
      animation-delay: -0.3s;
    }
    .lds-spinner div:nth-child(10) {
      transform: rotate(270deg);
      animation-delay: -0.2s;
    }
    .lds-spinner div:nth-child(11) {
      transform: rotate(300deg);
      animation-delay: -0.1s;
    }
    .lds-spinner div:nth-child(12) {
      transform: rotate(330deg);
      animation-delay: 0s;
    }
    @keyframes lds-spinner {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @media screen and (max-width: 576px) { // mobile cellphones
      img {
        width: 40%;
        padding: 5%;
      }

      p {
        font-size: 1.5em;
      }

      .text-footer-p{
        font-size: 1.3em;
        padding: 10%;
        color: #3f51b5;
      }

      .title-p {
        font-size: 30px; font-weight: 600;
      }
      .sub-title-p {
        font-size: 30px;
      }
      .content-title-p {
        font-size: 20px;
        padding: 0 5%;
      }
      .footer-title-p {
        font-size: 17px; font-weight: 600;
        padding: 0 5%;
      }
    }
  `]
})
export class SaveIbvComponent implements OnInit {

  wait: boolean = true;
  process_1: string = `Nous traitons vos informations`;
  process_2: string = `Ce ne sera pas long!`;
  process_3: string = `Veuillez ne pas réinitialiser cette page. Le processus peut prendre jusqu'à 5 minutes.`;

  languaje: string = 'fr';

  constructor(private flinksService: FlinksService) { }

  ngOnInit(): void {
    let ibv = localStorage.getItem('ibv');
    let loginId = localStorage.getItem('loginId');
    let requestId = localStorage.getItem('requestId');
    let institution = localStorage.getItem('institution');
    let url_thanks = localStorage.getItem('url_thanks');
    let client_id = localStorage.getItem('client_id');
    let languages = JSON.parse(localStorage.getItem('answers'));
    let language = 1;

    for (let i = 0; i < languages.length; i++) {
      if(languages[i].question_id == 1){
        language = languages[i].answer;
      }
    }

    if(language == 2){
      this.process_1 = `We're processing your information`;
      this.process_2 = `Won't be long!`;
      this.process_3 = `Please do not refresh this page. The process can take up to 5 minutes.`;

      this.languaje = 'en';
    }

    console.log(ibv, loginId, requestId, institution, url_thanks, client_id)

    this.flinksService.addTransactions({ibv, loginId, requestId, institution, url_thanks, client_id})
      .subscribe(res => {
        console.log(res)
        if(res.ok){
          localStorage.clear();
          location.href = `https://prestocash.ca/thank-you-prestocash`;
        }else{
          this.wait = true;
        }
        //
      });
  }

}
