import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-transacction-inverite',
  templateUrl: './transacction-inverite.component.html',
  styleUrls: ['./transacction-inverite.component.scss']
})
export class TransacctionInveriteComponent implements OnInit {
  @Input() trans
  transacctions: any;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.transacctions=changes.trans.currentValue;

    
  }

}
