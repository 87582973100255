import { Component, OnInit } from '@angular/core';
import { QuestionService } from 'src/app/services/question.service';
import { ClientService } from 'src/app/services/client.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectOption } from '@angular/forms';
import { StatuService } from 'src/app/services/statu.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Administrator } from '../interfaces/auditory-log';
import { AuditoryService } from 'src/app/services/auditory.service';
import { Label, MultiDataSet } from 'ng2-charts';



@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  //  data = {
  //   labels: ['approved', 'refusal'],
  //   datasets: [
  //     {
  //       label: 'My First Dataset',
  //       data: [300, 600],
  //       fill: false,
  //       backgroundColor: [
  //         'rgb(40, 167, 69)',
  //         '#dc3545'
  //       ],
  //       borderColor: [
  //         'rgb(40, 167, 69)',
  //         '#dc3545'
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };
  data
  startDate
  endDate
  currentQuestion = null;
  message = '';
  requesting
  flag=false
  totalRequest=0;
  searchDates=false;
  roles = null;
  showAdminBoard = false;
  user=null;
  maxDate;
  showToday: boolean=false;
  showYesterday: boolean=false;
  showWeek: boolean=false;
  showOthers: boolean=false;
  moduleApp = null;
  origin=null;
  total=0;
  approved: number = 0;
  refusal: number = 0;
  funded: number = 0;
  percen: number;
  renewal;
  totalRenewal: number;
  approvedRenewal: number = 0;
  refusalRenewal: number = 0;
  fundedRenewal: number = 0;
  dat2: { labels: string[]; datasets: { label: string; data: (string | number)[]; fill: boolean; backgroundColor: string[]; borderColor: string[]; borderWidth: number; }[]; };
  notElegible: number = 0;
  approvedRequest: number = 0;
  refusalRequest: number = 0;
  notElegibleRequest: number = 0;
  approvalrateRenewal: number = 0;
  approvalrateRequest: number = 0;
  public doughnutChartLabels: Label[] = ['% Approved', '% Refusal', '% Not Eligible - Existing client'];
  public doughnutChartData: MultiDataSet = [  ];
;
  generateExcel=false;
  list_status = null;
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  xlsx = null;

  
  // user quantity
  approvedUserRequest: | Administrator = {
    ok: false,
    stadistics: []
  };

  constructor(
    private questionService: QuestionService,
    private clientService: ClientService,
    private statuService: StatuService,
    private tokenStorageService: TokenStorageService,
    private auditoryService: AuditoryService,
    private route: ActivatedRoute) {


     }
     onSelect(event) {
      console.log(event);
    }
  ngOnInit() {
    this.message = '';
    this.getStatus();
    this.user = this.tokenStorageService.getUser();
    this.roles = this.user.roles;
    this.showAdminBoard=this.roles.includes('ROLE_ADMIN');
    console.log('user', this.user.roles);
    this.origin = localStorage.getItem('module') ? localStorage.getItem('module') : 'prestobroker';
    this.maxDate = this.getdate();
    this.getQuestion(this.route.snapshot.paramMap.get('id'));
    this.setSelectModule("");

    // this.clientService.countRequest().subscribe(resp=>{
    //   this.clientService.countRequestAll().subscribe(r=>{
    //     console.log(r,"mmmsmms")
    //     this.totalRequest=r[0].status;
    //     this.requesting=resp
    //     console.log(this.requesting,"requesting")
    //     this.flag=true
    //     this.showToday=true
    //     this.showYesterday=false;
    //     this.showWeek=false;
    //     this.showOthers=false;
    //     this.setSelectModule("");
    //   })
      
    // });
    this.startDate=this.convertDate(new Date());
    this.endDate=this.convertDateAddDay(new Date());
    this.getStadistics(this.startDate, this.endDate);
    this.clientService.countRenewal("today").subscribe(resp=>{
      this.renewal=resp;
      console.log(resp)
      this.totalRenewal=0
      this.approvedRenewal=0;
      this.notElegible=0;
        this.refusalRenewal=0;
        this.renewal.filter(r=>{
          this.totalRenewal+=r.status;
          if(r.statu.id==6){
            this.approvedRenewal=r.status;
          }
          if(r.statu.id==15){
            this.refusalRenewal=r.status;
          }
          if(r.statu.id==17){
            this.notElegible=r.status;
          }
          if(r.statu.id==7){
            this.fundedRenewal=r.status;
          }
        })
        this.dat2 = {
          labels: ['% Approved - Funded', '% Refusal', '% Not Eligible - Existing client'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [(((this.approvedRenewal + this.fundedRenewal)*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2), this.refusalRenewal!=0?((this.refusalRenewal*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2):0,((this.notElegible*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2)],
              fill: false,
              backgroundColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderWidth: 1,
            },
          ],
        };
    })
  }

  getdate(){
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1;
    let yyyy = today.getFullYear();
    let month = mm < 10 ? '0'+mm : mm;
    let day = dd < 10 ? '0'+dd : dd;
    return yyyy+'-'+month+'-'+day;
    
  }

  getQuestion(id) {
    this.questionService.get(id)
      .subscribe(
        data => {
          this.currentQuestion = data;
          // console.log(data);
        },
        error => {
          console.log(error);
        });
  }
  setSelectOption(event){
    let option  = (document.getElementById("optionDate") as HTMLInputElement) != null ? (document.getElementById("optionDate") as HTMLInputElement).value : '';
    this.refusal=this.approved=0;
    if(option == 'today'){
      this.startDate=this.convertDate(new Date());
      this.endDate=this.convertDateAddDay(new Date());

      this.getStadistics(this.startDate, this.endDate);

      this.searchDates = false;
      this.generateExcel = false;
      this.clientService.countRequest().subscribe(resp=>{
        this.requesting=resp;
        this.flag=true;
        this.totalRequest=0;
        for (let i in this.requesting) {
          this.totalRequest+=this.requesting[i].status;
          if(this.requesting[i].statu.id==6){
            this.approved=this.requesting[i].status;
          }
          if(this.requesting[i].statu.id==15){
            this.refusal=this.requesting[i].status;
          }
          if(this.requesting[i].statu.id==7){
            this.funded=this.requesting[i].status;
          }
        }
        this.data = {
            labels: ['% Approved - Funded', '% Refusal'],
            datasets: [
              {
                label: 'My First Dataset',
                data: [(((this.approved + this.funded)*100)/(this.approved+this.refusal+this.funded)).toFixed(2), this.refusal!=0?((this.refusal*100)/(this.approved+this.refusal+this.funded)).toFixed(2):0],
                fill: false,
                backgroundColor: [
                  'rgb(40, 167, 69)',
                  '#dc3545'
                ],
                borderColor: [
                  'rgb(40, 167, 69)',
                  '#dc3545'
                ],
                borderWidth: 1,
              },
            ],
          };
        this.showToday=true
        this.showYesterday=false;
        this.showWeek=false;
        this.showOthers=false;
      });
      
      this.clientService.countRenewal(option).subscribe(resp=>{
        this.renewal=resp;
        this.totalRenewal=0
        this.approvedRenewal=0;
        this.refusalRenewal=0;
        this.notElegible=0;
        this.renewal.filter(r=>{
          this.totalRenewal+=r.status;
          if(r.statu.id==6){
            this.approvedRenewal=r.status;
          }
          if(r.statu.id==15){
            this.refusalRenewal=r.status;
          }
          if(r.statu.id==17){
            this.notElegible=r.status;
          }
          if(r.statu.id==7){
            this.fundedRenewal=r.status;
          }
        })
        this.dat2 = {
          labels: ['% Approved - Funded', '% Refusal','% Not Eligible - Existing client'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [(((this.approvedRenewal + this.fundedRenewal)*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2), this.refusalRenewal!=0?((this.refusalRenewal*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2):0,((this.notElegible*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2)],
              fill: false,
              backgroundColor: [
                'rgb(40, 167, 69)',
                '#dc3545'
              ],
              borderColor: [
                'rgb(40, 167, 69)',
                '#dc3545'
              ],
              borderWidth: 1,
            },
          ],
        };
      })
    }

    if(option == 'yesterday'){

      this.startDate=this.convertDate(new Date(this.formatDate(0)));
      this.endDate=this.convertDate(new Date());

      this.getStadistics(this.startDate, this.endDate);

      this.searchDates = false;
      this.generateExcel = false;
      this.clientService.countRequestYesterday().subscribe(resp=>{
        this.requesting=resp;
        this.flag=true;
        this.totalRequest=0;
        for (let i in this.requesting) {
          this.totalRequest+=this.requesting[i].status;
          if(this.requesting[i].statu.id==6){
            this.approved=this.requesting[i].status;
          }
          if(this.requesting[i].statu.id==15){
            this.refusal=this.requesting[i].status;
          }
          if(this.requesting[i].statu.id==7){
            this.funded=this.requesting[i].status;
          }
        }
        this.data = {
            labels: ['% Approved - Funded', '% Refusal'],
            datasets: [
              {
                label: 'My First Dataset',
                data: [(((this.approved + this.funded)*100)/(this.approved+this.refusal+this.funded)).toFixed(2), ((this.refusal*100)/(this.approved+this.refusal+this.funded)).toFixed(2)],
                fill: false,
                backgroundColor: [
                  'rgb(40, 167, 69)',
                  '#dc3545'
                ],
                borderColor: [
                  'rgb(40, 167, 69)',
                  '#dc3545'
                ],
                borderWidth: 1,
              },
            ],
          };
        this.showToday=false;
        this.showYesterday=true;
        this.showWeek=false;
        this.showOthers=false;
      });
      this.clientService.countRenewal(option).subscribe(resp=>{
        console.log(resp,option)
        this.renewal=resp;
        this.totalRenewal=0
        this.approvedRenewal=0;
        this.notElegible=0;
        this.refusalRenewal=0;
        this.renewal.filter(r=>{
          this.totalRenewal+=r.status;
          if(r.statu.id==6){
            this.approvedRenewal=r.status;
          }
          if(r.statu.id==15){
            this.refusalRenewal=r.status;
          }
          if(r.statu.id==17){
            this.notElegible=r.status;
          }
          if(r.statu.id==7){
            this.fundedRenewal=r.status;
          }
        })
        this.dat2 = {
          labels: ['% Approved - Funded', '% Refusal','% Not Eligible - Existing client'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [(((this.approvedRenewal + this.fundedRenewal)*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2), this.refusalRenewal!=0?((this.refusalRenewal*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2):0,((this.notElegible*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2)],
              fill: false,
              backgroundColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderWidth: 1,
            },
          ],
        };
      })
    }

    if(option == 'week'){

      this.startDate=this.getMonday(new Date(this.formatDate(0)));
      this.endDate=this.convertDateAddDay(new Date());

      this.getStadistics(this.startDate, this.endDate);

      this.searchDates = false;
      this.generateExcel = false;
      this.clientService.countRequestWeek().subscribe(resp=>{
        this.requesting=resp;
        this.flag=true;
        this.totalRequest=0;
        for (let i in this.requesting) {
          this.totalRequest+=this.requesting[i].status;
          if(this.requesting[i].statu.id==6){
            this.approved=this.requesting[i].status;
          }
          if(this.requesting[i].statu.id==15){
            this.refusal=this.requesting[i].status;
          }
          if(this.requesting[i].statu.id==7){
            this.funded=this.requesting[i].status;
          }
        }
        this.data = {
            labels: ['% Approved - Funded', '% Refusal'],
            datasets: [
              {
                label: 'My First Dataset',
                data: [(((this.approved + this.funded)*100)/(this.approved+this.refusal+this.funded)).toFixed(2), ((this.refusal*100)/(this.approved+this.refusal+this.funded)).toFixed(2)],
                fill: false,
                backgroundColor: [
                  'rgb(40, 167, 69)',
                  '#dc3545'
                ],
                borderColor: [
                  'rgb(40, 167, 69)',
                  '#dc3545'
                ],
                borderWidth: 1,
              },
            ],
          };
        this.showToday=false;
        this.showYesterday=false;
        this.showWeek=true;
        this.showOthers=false;

      });
      this.clientService.countRenewal(option).subscribe(resp=>{
        console.log(resp,option)
        this.renewal=resp;
        this.totalRenewal=0
        this.approvedRenewal=0;
        this.refusalRenewal=0;
        this.notElegible=0;
        this.renewal.filter(r=>{
          this.totalRenewal+=r.status;
          if(r.statu.id==6){
            this.approvedRenewal=r.status;
          }
          if(r.statu.id==15){
            this.refusalRenewal=r.status;
          }
          if(r.statu.id==17){
            this.notElegible=r.status;
          }
          if(r.statu.id==7){
            this.fundedRenewal=r.status;
          }
        })
        this.dat2 = {
          labels: ['% Approved - Funded', '% Refusal','% Not Eligible - Existing client'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [(((this.approvedRenewal + this.fundedRenewal)*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2), this.refusalRenewal!=0?((this.refusalRenewal*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2):0,((this.notElegible*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2)],
              fill: false,
              backgroundColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderWidth: 1,
            },
          ],
        };
      })
    }

    if(option == 'other'){
      this.searchDates = true;
      this.generateExcel = false;
     
    }

    if(option == 'excel'){
      this.generateExcel = true;
      this.searchDates = false;
    }
  }

  generateXMLX(event){
    let startDate = (document.getElementById("dateStart") as HTMLInputElement).value;
    let endDate = (document.getElementById("dateEnd") as HTMLInputElement).value;
    let option = (document.getElementById("option") as HTMLInputElement).value;

    this.clientService.generateListExcel({startDate: startDate, endDate: endDate, option: option})
    .subscribe(data => {
      this.xlsx = data;
      this.exportAsExcelFile(this.xlsx, 'clients');
    });
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + this.EXCEL_EXTENSION);
  }

  getStatus(){
    this.statuService.getStatus()
      .subscribe(
        data => {
           this.list_status = data;
          //  console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  formatDate(i){        
    var d = new Date,
         dformat = [ d.getFullYear(),
                    this.padLeft(d.getMonth()+1),
                    this.padLeft(d.getDate()-i)
                    ].join('-');
     return ''+dformat
  }

  setSelectModule(event){
    let option = this.origin;
    console.log(option,"option")
    localStorage.setItem('module',`${option}`);
    this.setSelectOption(event);
  }

  serachDate($event){
    let dateStart  = (document.getElementById("dateStart") as HTMLInputElement).value;
    let dateEnd  = (document.getElementById("dateEnd") as HTMLInputElement).value;

    dateEnd = dateEnd.replace(new RegExp("-","g"),"/");

    this.refusal=this.approved=0;

    if(dateEnd < dateStart){
      alert('The end date cannot be less than the start date!');
      (document.getElementById("dateStart") as HTMLInputElement).value = '';
      (document.getElementById("dateEnd") as HTMLInputElement).value = '';
      return;
    }

    
    this.startDate = dateStart;
    this.endDate = this.convertDateAddDay(new Date(dateEnd));

    this.getStadistics(this.startDate, this.endDate);

    this.searchDates = true;
    this.clientService.countRequestDate(dateStart, dateEnd).subscribe(resp=>{
      this.requesting=resp;
            
      
      console.log(resp,"date format")
      this.flag=true;
      this.totalRequest=0;
      for (let i in this.requesting) {
        this.totalRequest+=this.requesting[i].status;
        if(this.requesting[i].statu.id==6){
          this.approved=this.requesting[i].status;
        }
        if(this.requesting[i].statu.id==15){
          this.refusal=this.requesting[i].status;
        }
        if(this.requesting[i].statu.id==7){
          this.funded=this.requesting[i].status;
        }
      }
      this.data = {
          labels: ['% Approved - Funded', '% Refusal'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [(((this.approved + this.funded)*100)/(this.approved+this.refusal+this.funded)).toFixed(2), ((this.refusal*100)/(this.approved+this.refusal+this.funded)).toFixed(2)],
              fill: false,
              backgroundColor: [
                'rgb(40, 167, 69)',
                '#dc3545'
              ],
              borderColor: [
                'rgb(40, 167, 69)',
                '#dc3545'
              ],
              borderWidth: 1,
            },
          ],
        };
      this.showToday=false;
      this.showYesterday=false;
      this.showWeek=false;
      this.showOthers=true;
    });
    this.clientService.countRenewal("others").subscribe(resp=>{
      this.renewal=resp;
      this.totalRenewal=0
      this.approvedRenewal=0;
      this.notElegible=0;
        this.refusalRenewal=0;
        this.renewal.filter(r=>{
          this.totalRenewal+=r.status;
          if(r.statu.id==6){
            this.approvedRenewal=r.status;
          }
          if(r.statu.id==15){
            this.refusalRenewal=r.status;
          }
          if(r.statu.id==17){
            this.notElegible=r.status;
          }
          if(r.statu.id==7){
            this.fundedRenewal=r.status;
          }
        })
        this.dat2 = {
          labels: ['% Approved - Funded', '% Refusal','% Not Eligible - Existing client'],
          datasets: [
            {
              label: 'My First Dataset',
              data: [(((this.approvedRenewal + this.fundedRenewal)*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2), this.refusalRenewal!=0?((this.refusalRenewal*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2):0,((this.notElegible*100)/(this.approvedRenewal+this.refusalRenewal+this.notElegible+this.fundedRenewal)).toFixed(2)],
              fill: false,
              backgroundColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderColor: [
                'rgb(40, 167, 69)',
                '#dc3545',
                "#6c757d"
              ],
              borderWidth: 1,
            },
          ],
        };
      console.log(this.renewal,"others")
    })
    //  data = {
  //   labels: ['approved', 'refusal'],
  //   datasets: [
  //     {
  //       label: 'My First Dataset',
  //       data: [300, 600],
  //       fill: false,
  //       backgroundColor: [
  //         'rgb(40, 167, 69)',
  //         '#dc3545'
  //       ],
  //       borderColor: [
  //         'rgb(40, 167, 69)',
  //         '#dc3545'
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

    console.log(dateEnd);
  }
  percentage(){
    this.total=0;
    this.requesting.filter(resp=>{
      if(resp.statu.id==6||resp.statu.id==15){
        this.total+=resp.status;
        if(resp.statu.id==6){
          this.approved=resp.status;
        }else{
          this.refusal=resp.status;

        }
      }
    })
    this.percen=(this.approved*100)/this.total
    return this.percen;
  }

  getStadistics(dateStart: string, dateEnd: string){
    this.auditoryService.getPercentFilesApprobedRejectUser(dateStart, dateEnd)
      .subscribe(res => {
        this.approvedUserRequest = res;
        // console.log(res, 'casa');
        localStorage.setItem('startDate', this.startDate);
        localStorage.setItem('endDate', this.endDate);

        this.approvedUserRequest.stadistics.forEach(el => {
          this.approvedRequest += el.total_approved;
          this.refusalRequest += el.total_refused;
        });
    console.log(this.approvedUserRequest, 'calipso')
        // this.approvedUserRequest.arrayRefused.forEach(el => {
        //   this.refusalRequest += el.quantity;
        // });
  
        this.doughnutChartLabels = ['% Approved', '% Refusal'];
        let approved = this.approvedRequest!=0?((this.approvedRequest*100)/(this.approvedRequest+this.refusalRequest)).toFixed(2):0;
        let refused = this.refusalRequest!=0?((this.refusalRequest*100)/(this.approvedRequest+this.refusalRequest)).toFixed(2):0;
        this.doughnutChartData = [
          [
            parseFloat(approved.toString()),
            parseFloat(refused.toString())
          ]
        ];
      })
  }

  padLeft(n: number){
    return ("00" + n).slice(-2);
  }

  getMonday(d: any) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1);
    let a = new Date(d.setDate(diff));
    let dformat = [ a.getFullYear(),
      this.padLeft(a.getMonth()+1),
      this.padLeft(a.getDate())
      ].join('-');
    return ''+dformat
  }

  convertDate(fecha: any){
    var day = "";
    var month = "";
  
    if(fecha.getMonth() < 9){
      month = fecha.getMonth() + 1;
      month = "0" + month;
    } else {
      month = fecha.getMonth() + 1;
    }
  
    if(fecha.getDate() < 9){
      day = "0"+fecha.getDate();
    } else {
      day = fecha.getDate();
    }
  
    return `${fecha.getFullYear()}-${month}-${day}`;
  }

  convertDateAddDay(fecha: any){
    var day = "";
    var month = "";

    fecha = fecha.getTime()+(1*24*60*60*1000);
    fecha = new Date(fecha);
  
    if(fecha.getMonth() < 9){
      month = fecha.getMonth() + 1;
      month = "0" + month;
    } else {
      month = fecha.getMonth() + 1;
    }
  
    if(fecha.getDate() < 9){
      day = "0"+fecha.getDate();
    } else {
      day = fecha.getDate();
    }
  
    return `${fecha.getFullYear()}-${month}-${day}`;
  }

}
