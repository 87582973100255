import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { FlinksService } from 'src/app/services/flinks.service';

@Component({
  selector: 'app-bank-details',
  templateUrl: './bank-details.component.html',
  styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {
  @Input() data
  @Input() user
  @Output() backs = new EventEmitter<string>();
  load=false
  initial = false;
  transacctions
  bankDetails
  account_details
  goBacks=false
  id: any;
  constructor(
    private flinksService:FlinksService,
    private router:Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    let change=this.router.url.split("/")
    if(change[2]=='rew'){
      this.goBacks=true;
    }else{
      this.goBacks=false
    }

    this.activatedRoute.params
      .subscribe(({ request_id }) => {
        this.id = request_id
      });

    this.activatedRoute.params
      .pipe(
        switchMap(({ request_id }) => this.flinksService.getAccountsDetail(request_id))
      )
      .subscribe(resp => {
        this.initial = true;
        this.bankDetails = resp

      });


  }

  check(account){
    console.log(account)
    this.load = true;
    this.account_details = account;
  }

}
