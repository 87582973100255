import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment'

const baseUrl = environment.url_server+'/api/status';

@Injectable({
  providedIn: 'root'
})
export class StatuService {
  listStatus: any;

  constructor(private http: HttpClient) { }

  getStatus() {
    let status=this.http.get(`${baseUrl}`);
    console.log(status,"status")
    return status;
  }
  setStatus(data){
    this.listStatus=data;
  }
  getStatusSetted(){
    if(this.listStatus!=null){
      return this.listStatus;
    }
    return null

  }
  getStatusSettedCheckNewdata(){
    if(this.listStatus!=null){
      this.getStatus().subscribe(resp=>{
        let data
        data=resp;
        let totalData=data.length;
        let totalListStatus=this.listStatus;
        if(totalData>totalListStatus){
          this.listStatus=data;
          return this.listStatus
        }else{
          return this.listStatus;
        }

      })
    }
    return null
  }
}