import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = environment.url_server+'/api/question_answers_frs';

@Injectable({
  providedIn: 'root'
})

export class QuestionAnswerfrService {

  constructor(private http: HttpClient) { }

  create(data) {
    return this.http.post(baseUrl, data);
  }

  getAll() {
    return this.http.get(`${baseUrl}`);
  }

  updateAmount(id,data){
    return this.http.put(`${baseUrl}/update/${id}`, data);
  }
  
}
