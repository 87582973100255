import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormComponent } from './components/form/form.component';
import { Form2Component } from './components/form2/form2.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { IbvComponent } from './components/ibv/ibv.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ThanksComponent } from './components/thanks-page/thanks-page.component';
import { LoanComponent } from './components/loan/loan.component';
import { RolesComponent } from './components/rols_privilegie/rols_privilegie.component';
import { SaveIbvComponent } from './components/save-ibv/save-ibv.component';

const routes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'form/:id', component: FormComponent },
  { path: 'form2', component: Form2Component },
  { path: 'login', component: LoginComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'ibv', component: IbvComponent },
  { path: 'ibv/save', component:SaveIbvComponent},
  { path: 'admin', component: AdminComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'logs', component: DashboardComponent },
  { path: 'logs/details/:username/:startDate/:endDate', component: DashboardComponent },
  { path: 'marketing', component: DashboardComponent },
  { path: 'select-ibv', component: DashboardComponent },
  { path: 'users', component: DashboardComponent },
  { path: 'requests', component: DashboardComponent },
  { path: 'requests/details/:screen/:id', component: DashboardComponent },
  { path: 'requests/:type/:status', component: DashboardComponent },
  { path: 'flink/req/:request_id', component: DashboardComponent },
  { path: 'inverite/req/:request_id/:id', component: DashboardComponent },
  { path: 'flink/rew/:request_id', component: DashboardComponent },
  { path: 'inverite/rew/:request_id/:id', component: DashboardComponent },
  { path: 'renewal', component: DashboardComponent },
  { path: 'renewals/details/:screen/:id', component: DashboardComponent },
  { path: 'renewals/:type/:status', component: DashboardComponent },
  { path: 'contracts', component: DashboardComponent },
  { path: 'setting', component: DashboardComponent },
  { path: 'branch', component: DashboardComponent },
  { path: 'thank-you/:numberid', component: ThanksComponent },
  { path: 'ibv/:id', component: IbvComponent },
  { path: 'loan',component: LoanComponent },
  { path: 'roles', component: DashboardComponent },
  { path: 'form', redirectTo: 'form/languaje' },
  { path: '**', redirectTo: 'form/languaje' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
