import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ContentComponent } from './components/content/content.component';
import { RequestComponent } from './components/request/request.component';
import { BankComponent } from './components/bank/bank.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TextMaskModule } from 'angular2-text-mask';
import { Mugan86GoogleAnalyticsModule } from 'mugan86-ng-google-analytics';
import { KnobModule } from "@xmlking/ngx-knob";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { authInterceptorProviders } from './helpers/auth.interceptor';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FormComponent } from './components/form/form.component';
import { IbvComponent } from './components/ibv/ibv.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { UserComponent } from './components/user/user.component';
import { RenewalComponent } from './components/renewal/renewal.component';
import { ContractComponent } from './components/contract/contract.component';
import { SettingComponent } from './components/setting/setting.component';
import { Select2Module } from 'ng2-select2';
import { DataTablesModule } from 'angular-datatables';
import { AdminComponent } from './components/admin/admin.component';
import { BranchComponent } from './components/branch/branch.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ThanksComponent } from './components/thanks-page/thanks-page.component';
import { LoanComponent } from './components/loan/loan.component';

// Datepicker module
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

//toastr
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { SelectComponent } from './components/select/select.component';
import { RolesComponent } from './components/rols_privilegie/rols_privilegie.component';
import { LogsComponent } from './components/logs/logs.component';
import { CardLogsComponent } from './components/card-logs/card-logs.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import { SelectIbvComponent } from './components/select-ibv/select-ibv.component';
import { BankDetailsComponent } from './components/bank-details/bank-details.component';
import { TransacctionsComponent } from './components/bank-details/transacctions/transacctions.component';
import { BankDetailsInveriteComponent } from './components/bank-details-inverite/bank-details-inverite.component';
import { TransacctionInveriteComponent } from './components/bank-details-inverite/transacction-inverite/transacction-inverite.component';
import { PaymentScheduleComponent } from './components/bank-details-inverite/payment-schedule/payment-schedule.component';
import { StadisticsInveriteComponent } from './components/bank-details-inverite/stadistics-inverite/stadistics-inverite.component';
import { Form2Component } from './components/form2/form2.component';
import { DatakpiComponent } from './components/dashboard/datakpi/datakpi.component';
import { GraphicComponent } from './components/dashboard/graphic/graphic.component';
import { SaveIbvComponent } from './components/save-ibv/save-ibv.component';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    ContentComponent,
    LoginComponent,
    HomeComponent,
    ProfileComponent,
    FormComponent,
    IbvComponent,
    RequestComponent,
    BankComponent,
    UserComponent,
    RenewalComponent,
    ContractComponent,
    SettingComponent,
    AdminComponent,
    BranchComponent,
    ThanksComponent,
    SelectComponent,
    LoanComponent,
    RolesComponent,
    LogsComponent,
    CardLogsComponent,
    MarketingComponent,
    SelectIbvComponent,
    BankDetailsComponent,
    TransacctionsComponent,
    BankDetailsInveriteComponent,
    TransacctionInveriteComponent,
    PaymentScheduleComponent,
    StadisticsInveriteComponent,
    Form2Component,
    DatakpiComponent,
    GraphicComponent,
    SaveIbvComponent,
    TransactionDetailsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ChartjsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgxPaginationModule,
    Select2Module,
    DataTablesModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    CommonModule,
    KnobModule,
    ToastrModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    Mugan86GoogleAnalyticsModule.forRoot(
      {
        analyticsId: 'UA-168609025-1',
        showLog: true
      }
    )
  ],
  providers: [authInterceptorProviders, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
