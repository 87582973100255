import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

const API_URL = environment.url_server+'/api';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + '/all', { responseType: 'text' });
  }

  getModeratorBoard(): Observable<any> {
    return this.http.get(API_URL + '/dashboard', { responseType: 'text' });
  }

  getAdminBoard(): Observable<any> {
    return this.http.get(API_URL + '/admin/dashboard', { responseType: 'text' });
  }

  getUsers(){
    return this.http.get(API_URL+'/users');
  }

  getRoles(){
    return this.http.get(API_URL+'/roles');
  }

  createUser(data){
    return this.http.post(API_URL+'/auth/signup',data);
  }

  getUser(id){
    return this.http.get(API_URL+'/user/'+id);
  }

  updateUser(id,data){
    return this.http.put(API_URL+'/user/'+id,data);
  }

  deleteUser(id){
    return this.http.delete(API_URL+'/user/delete/'+id);
  }

  getPrivilegies(id){
    return this.http.get(API_URL+'/rol_privilegie/privilegies/'+id);
  }
}