import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/answers';

@Injectable({
  providedIn: 'root'
})

export class AnswerService {

  constructor(private http: HttpClient) { }

  getAnswerAll(question_id) {
    return this.http.get(`${baseUrl}/question/${question_id}`);
  }
  
  get(id) {
    return this.http.get(`${baseUrl}/${id}`);
  }

}
