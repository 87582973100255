import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/mail';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(private http: HttpClient) { }

  sendEmail(data){
    return this.http.post(`${baseUrl}/send`, data);
  }
  sendEmailRefusal(data){
    return this.http.post(`${baseUrl}/sendRefusal`, data);
  }

}
