import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stadistics-inverite',
  templateUrl: './stadistics-inverite.component.html',
  styleUrls: ['./stadistics-inverite.component.scss']
})
export class StadisticsInveriteComponent implements OnInit {
  @Input() stadistic
  stadistics: any;
  loadDebits = false;
  loadCredicts = false;
  loadLoans = false;
  loadOverdraft = false;
  loadPayday = false;
  loadReturns = false;
  has_transaction_days = false;
  mean_closing_balance = false;
  auto_and_transport = false;
  bills_and_utilities = false;
  fees_and_charges = false;
  food_and_dining = false;
  health_and_fitness = false;
  income = false;
  insurance = false;
  shopping = false;
  transfer = false;
  data=[]
  labels=[]
  chart
  constructor() { }

  ngOnInit() {
    this.loadDebits = true;
    this.loadCredicts = false;

    this.loadOverdraft=false;
    this.loadPayday=false;
    this.loadReturns=false;
    this.loadLoans=true;

    this.has_transaction_days = false;
    this.mean_closing_balance = true;

    this.auto_and_transport = true;
    this.bills_and_utilities = false;
    this.fees_and_charges = false;
    this.food_and_dining = false;
    this.health_and_fitness = false;
    this.income = false;
    this.insurance = false;
    this.shopping = false;
    this.transfer = false;
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.stadistics=changes.stadistic.currentValue.statistics;
    console.log(this.stadistics);
    this.labels.push('auto and transport')
    this.labels.push('bills and utilities')
    this.labels.push('fees and charges')
    this.labels.push('food and dining')
    this.labels.push('health and fitness')
    this.labels.push('income')
    this.labels.push('insurance')
    this.labels.push('shopping')
    this.labels.push('transfer')

    this.data.push(this.stadistics.auto_and_transport_net*-1)
    this.data.push(this.stadistics.bills_and_utilities_net*-1)
    this.data.push(this.stadistics.fees_and_charges_net*-1)
    this.data.push(this.stadistics.food_and_dining_net*-1)
    this.data.push(this.stadistics.health_and_fitness_net*-1)
    this.data.push(0)
    this.data.push(this.stadistics.insurance_net*-1)
    this.data.push(this.stadistics.shopping_net*-1)
    this.data.push(this.stadistics.transfer_net*-1)

    this.chart={
      labels:this.labels,
      datasets:[
        {
          data:this.data,
          fill: false,
          borderWidth: 1,
          backgroundColor: [
            '#69a1a9',
            '#a2f8db',
            "#f9de24",
            "#11902d",
            "#77f168",
            "#1f676f",
            "#5aa6da",
            "#58b7eb",
            "#64f903",
          ],
        }
      ]
    }
  }


  changeTypeAccount(event){
    let option  = (document.getElementById("optTypeAccount") as HTMLInputElement) != null ? (document.getElementById("optTypeAccount") as HTMLInputElement).value : '';

    if(option == 'debit'){
      this.loadCredicts = false;
      this.loadDebits = true;
    }else{
      this.loadDebits = false;
      this.loadCredicts = true;
      
    }
  }

  changeLoanAccount(event){
    let option  = (document.getElementById("optLoanAccount") as HTMLInputElement) != null ? (document.getElementById("optLoanAccount") as HTMLInputElement).value : '';

    if(option == 'loan'){
      this.loadOverdraft=false;
      this.loadPayday=false;
      this.loadReturns=false;
      this.loadLoans=true;
    }
    
    if(option == 'overdraft'){
      this.loadLoans=false;
      this.loadPayday=false;
      this.loadReturns=false;
      this.loadOverdraft=true;
    }

    if(option == 'payday'){
      this.loadLoans=false;
      this.loadOverdraft=false;
      this.loadReturns=false;
      this.loadPayday=true;
    }

    if(option == 'returns'){
      this.loadLoans=false;
      this.loadOverdraft=false;
      this.loadPayday=false;
      this.loadReturns=true;
    }
  }


  changeBalance(event){
    let option  = (document.getElementById("optBalance") as HTMLInputElement) != null ? (document.getElementById("optBalance") as HTMLInputElement).value : '';

    if(option == 'has_transaction_days'){
      this.has_transaction_days = false;
      this.mean_closing_balance = true;
    }else{
      this.mean_closing_balance = false;
      this.has_transaction_days = true;
      
    }
  }

  changeCreditDebit(event){
    let option  = (document.getElementById("optCreditDebit") as HTMLInputElement) != null ? (document.getElementById("optCreditDebit") as HTMLInputElement).value : '';

    if(option == 'auto_and_transport'){
      this.auto_and_transport = true;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = false;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'bills_and_utilities'){
      this.auto_and_transport = false;
      this.bills_and_utilities = true;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = false;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'fees_and_charges'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = true;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = false;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'food_and_dining'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = true;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = false;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'health_and_fitness'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = true;
      this.income = false;
      this.insurance = false;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'income'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = true;
      this.insurance = false;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'insurance'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = true;
      this.shopping = false;
      this.transfer = false;
    }

    if(option == 'shopping'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = false;
      this.shopping = true;
      this.transfer = false;
    }

    if(option == 'transfer'){
      this.auto_and_transport = false;
      this.bills_and_utilities = false;
      this.fees_and_charges = false;
      this.food_and_dining = false;
      this.health_and_fitness = false;
      this.income = false;
      this.insurance = false;
      this.shopping = false;
      this.transfer = true;
    }
  }

}
