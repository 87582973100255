import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Select2OptionData } from 'ng2-select2';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';

@Component({
  selector: 'app-roles',
  templateUrl: './rols_privilegie.component.html',
  styleUrls: ['./rols_privilegie.component.scss']
})
export class RolesComponent implements OnInit {

  currentUser = null;
  currentRoles: Array<Select2OptionData>;
  showNav = [true,false,false,false];//All,add,show,edit
  form: FormGroup;
  newUser = new Object();
  userShow = null;  
  exampleData: Array<Select2OptionData>;
  value: string[];
  options: Select2Options;
  current: string;
  newRoles  = new Object();
  privilegies = null;
  rol_name = null;
  rolShow = null;
  

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    public googleAnalyticsService: GoogleAnalyticsService
  ) { }

  ngOnInit() {
    this.exampleData = [
      {
        id: 'basic1',
        text: 'Basic 1'
      },
      {
        id: 'basic2',
        disabled: true,
        text: 'Basic 2'
      },
      {
        id: 'basic3',
        text: 'Basic 3'
      },
      {
        id: 'basic4',
        text: 'Basic 4'
      }
    ];
    console.log(this.exampleData);
    this.getUsers();
    this.getRoles();
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/user', 'Follow User', 'userLabel', 1); 
  }
  changed(data: {value: string[]}) {

     if(data.value.length == 0){
      this.current = null;
     } else {
      this.current = data.value.join(' | ');
     }
    this.newRoles = data.value;
  }
  getUsers(){
    this.userService.getUsers()
      .subscribe(
        data => {
          this.currentUser = data;          
          // console.log(data[0]);
        },
        error => {
          console.log(error);
    });
  }

  getRoles(){
    this.userService.getRoles()
      .subscribe(
        data => {
          let arrCertificates = [];
          
          
          for (var i = 0; i < Object.keys(data).length; i++) {
                  const certif = {id: data[i]['id'], text: data[i]['name']};
                  arrCertificates.push(certif);
          }
          this.currentRoles = arrCertificates;

          // this.currentRoles = data;         
          console.log(this.currentRoles)
        },
        error => {
          console.log(error);
    });
  }

  getPrivilegies(id){
    this.userService.getPrivilegies(id)
    .subscribe(
      data => {
        this.privilegies = data;
    });
  }

  Show(value,rol_id,name){

    if(value == 4){
      this.deleteUser(rol_id);
    } else if(rol_id != 0){
      this.rol_name = name;
      this.userService.getPrivilegies(rol_id)
        .subscribe(
          data => {
            this.rolShow = data;
            console.log(this.rolShow);
            //console.log(data['roles']);
            // this.value = new Array();
            // for (var i = 0; i < Object.keys(data['roles']).length; i++) {
            //   this.value.push(data['roles'][i]['name']);
            // }

            this.showComponent(value); 
             //console.log(this.value);
            // this.form = this.fb.group({
            //   first_name: [data['first_name'],Validators.required],
            //   last_name: [data['last_name'],Validators.required],
            //   username: [data['username'],Validators.required],
            //   email: [data['email'],Validators.required],
            //   password: [null,Validators.required],
            //   role: [this.value[0], Validators.required]
            // });
          },
          error => {
            console.log(error);
          }
      );
    } else{
      this.form = this.fb.group({
        first_name: [null,Validators.required],
        last_name: [null,Validators.required],
        username: [null,Validators.required],
        email: [null,Validators.required],
        password: [null,Validators.required],
        role: [null,Validators.required]
      });

      this.value = null;
      
      this.showComponent(value);
    }

    this.options = {
      multiple: true
    }
    
  }

  showComponent(value){
    for (var i = 0; i < this.showNav.length; i++) {

      if(i == value){
        this.showNav[i] = true;
      } else {
        this.showNav[i] = false;
      }

    }
  }
  saveUser(form) {
    // console.log(form);return "";
    if(form.value.first_name == null || form.value.last_name == null || form.value.username == null || form.value.email == null || form.value.password == null || form.value.role == null){
      this.toastrService.info("empty fields", 'Info', {
        timeOut: 5000
      });
    }
    
    if (form.value.role == null){
      this.toastrService.info("Select a Rol", 'Info', {
        timeOut: 5000
      });
    }
    
    this.newUser['id'] = 0;
    this.newUser['first_name'] = form.value.first_name;
    this.newUser['last_name'] = form.value.last_name;
    this.newUser['username'] = form.value.username;
    this.newUser['email'] = form.value.email;
    this.newUser['password'] = form.value.password;
    this.newUser['roles'] = [form.value.role];
    
    if(form.value.first_name != null && form.value.last_name != null && form.value.username != null && form.value.email != null && form.value.password != null && form.value.role != null){
      // console.log(this.newUser);

      this.userService.createUser(this.newUser)
        .subscribe(
          data => {       
            //console.log(data);
            this.toastrService.success(data['message'], 'Success', {
              timeOut: 5000
            });
            this.getUsers();
            this.getRoles();
            this.Show(0,0,'');
          },
          err => {
            this.toastrService.error(err.error.message, 'Error', {
              timeOut: 5000
            });
            console.log(err.error.message);
      });
    }
    
    

  }

  deleteUser(user_id) {
      
    this.userService.deleteUser(user_id)
        .subscribe(
          data => {
            // this.currentRoles = data;          
            // console.log(data);
            this.toastrService.success(data['message'], 'Success', {
              timeOut: 5000
            });
            this.getUsers();
            this.getRoles();
            this.Show(0,0,'');
          },
          err => {
            console.log(err.error.message);
      });
  }

  updateUser(form,user_id) {

    if(form.value.first_name == null || form.value.last_name == null || form.value.username == null || form.value.email == null || form.value.password == null){
      this.toastrService.info("empty fields", 'Info', {
        timeOut: 5000
      });
    }
    if (form.value.role == null){
      this.toastrService.info("Select a Rol", 'Info', {
        timeOut: 5000
      });
    }
    
    this.newUser['id'] = user_id;
    this.newUser['first_name'] = form.value.first_name;
    this.newUser['last_name'] = form.value.last_name;
    this.newUser['username'] = form.value.username;
    this.newUser['email'] = form.value.email;
    this.newUser['password'] = form.value.password;
    this.newUser['roles'] = [form.value.role];

    if(form.value.first_name != null && form.value.last_name != null && form.value.username != null && form.value.email != null && form.value.password != null && form.value.role != null){
      // console.log(this.newUser);

      this.userService.updateUser(user_id,this.newUser)
        .subscribe(
          data => {
            // console.log(data);
            this.toastrService.success(data['message'], 'Success', {
              timeOut: 5000
            });
            this.getUsers();
            this.getRoles();
            this.Show(0,0,'');
          },
          err => {
            this.toastrService.error(err.error.message, 'Error', {
              timeOut: 5000
            });
            console.log(err.error.message);
      });
    } 

  }
}
