import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditoryService } from 'src/app/services/auditory.service';
import { StatuService } from 'src/app/services/statu.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  logs;
  list_status;
  total_list;
  routes: any = null;

  constructor(
    private auditoryService:AuditoryService,
    private statuService:StatuService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    if(this.router.url.includes('details')){
      this.activatedRoute.paramMap
        .subscribe( routes => {
          this.routes = routes;
        });

        console.log(this.routes);
    }

    this.auditoryService.getList(1, this.routes ? this.routes.params: null).subscribe(resp=>{
      console.log(resp, "teste");
      this.logs=resp;
      this.total_list=this.logs;
      // console.log(this.logs);

    },err=>{
      console.log(err);
    })
    this.getStatus(); 
  }
  onInputChanged($event){
    let name = $event.srcElement.value
    
    if(name!=""&&name!=0){
    this.logs = this.total_list.filter((item) => {
          return item.name_client.toLowerCase().includes(name.toLowerCase()) || 
                 item.name_user.toLowerCase().includes(name.toLowerCase()) || 
                 item.action.toLowerCase().includes(name.toLowerCase()) || 
                 (item.new_status && item.new_status.toLowerCase().includes(name.toLowerCase()))
    })
    }else{
      this.logs=this.total_list
    }
  }

  getStatus(){
    this.statuService.getStatus()
      .subscribe(
        data => {
           this.list_status = data;
          //  console.log(data);
        },
        error => {
          console.log(error);
        });
  }
}
