import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/clients';

@Injectable({
  providedIn: 'root'
})

export class ClientService {
  listClients: any;
  listRenewal: any;
  alldataRequest: any;
  alldataRenewal: any;

  constructor(private http: HttpClient) { }

  create(data) {
    console.log(data,"service");
    return this.http.post(baseUrl, data);
  }
  countRequest(){
    console.log("hola todos")
    let data={
      today:new Date() ,
      yesterday:new Date(this.formatDate(0))
    }
    let startDate=this.convertDate(new Date());
    let endDate=this.convertDateAddDay(new Date());

    let option = localStorage.getItem('module');
    window.localStorage.setItem('dateToday',JSON.stringify(data) );
    return this.http.get(`${baseUrl}/count/${startDate}/${endDate}/${option}`);
  }
  countRequestAll(){
    console.log("hola all")
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/countRequest/${option}`);
  }

  countRequestYesterday(){
    console.log("hola yesterday")
    let startDate=this.convertDate(new Date(this.formatDate(0)));
    let endDate=this.convertDate(new Date());
    console.log(startDate);
    let data={
      today:new Date(this.formatDate(0)) ,
      yesterday:new Date(this.formatDate(1))
    }
    let option = localStorage.getItem('module');
    window.localStorage.setItem('dateYesterday',JSON.stringify(data) );
    return this.http.get(`${baseUrl}/countYesterday/${option}/${startDate}/${endDate}`);
  }

  countRequestWeek(){
    console.log("hola week")
    let startDate=this.getMonday(new Date());
    let endDate=this.convertDateAddDay(new Date());
    console.log(`desde ${startDate} hasta ${endDate}`);
    let data={
      currentDate:new Date(new Date()),
      currentMonday:this.getMonday(new Date())
    }
    let option = localStorage.getItem('module');
    window.localStorage.setItem('dateWeek',JSON.stringify(data) );
    return this.http.get(`${baseUrl}/countWeek/${option}/${startDate}/${endDate}`);
  }
  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
    let a = new Date(d.setDate(diff));
    let dformat = [ a.getFullYear(),
      this.padLeft(a.getMonth()+1),
      this.padLeft(a.getDate())
      ].join('-');
    return ''+dformat
  }
  countRequestDate(dateStart, dateEnd){
    dateEnd = new Date(dateEnd);
    dateEnd = dateEnd.getTime()+(2*24*60*60*1000);
    dateEnd = new Date(dateEnd);
    let data={
      dateStart:dateStart,
      dateEnd:dateEnd
    }
    let option = localStorage.getItem('module');
    window.localStorage.setItem('dateRange',JSON.stringify(data) );
    return this.http.get(`${baseUrl}/countselected/${dateStart}/${this.convertDate(new Date(dateEnd))}/${option}`);
  }


  getClients(page) {//getClientsDate
    window.localStorage.setItem('goBack',`/requests`);
    window.localStorage.setItem('goBackStatus','');
    console.log("xxssxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxsxs");
    let option = localStorage.getItem('module');
    console.log('paginas a buscar ',page);
    //http://localhost:8000/api/clients/findAllClients/list/prestobroker/3/50
    return this.http.get(`${baseUrl}/findAllClients/list/${option}/${page}/100`);
  }
  getAllClientSearchRequest(if_renewal){
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/alldata/${option}/${if_renewal}`);

  }
  getDatatoSearchrequest(if_renewal){
    if(if_renewal=="Yes."){
      return this.alldataRequest;
    }else{
      return this.alldataRenewal
    }
    return null
  }
  setDatatoSearchrequest(if_renewal,data){
    if(if_renewal=="Yes."){
      this.alldataRequest=data
    }else{
      this.alldataRenewal=data;
    }
  }
  setClients(data){
    this.listClients=data;
  }
  getClientsStatusSetted(id){
    console.log(this.listClients,"getClientsStatusSetted")
    return this.listClients.filter(data=>{
      if(data.statu.id==id){
        return data
      }
    })
  }
  getClientsSetted(){
    if(this.listClients!=null)
      return this.listClients
    return null
  }
  getClientsDate(type,page) {//getClientsDate
    let startDate
    let endDate
    let datatotal
    let option = localStorage.getItem('module');
    if(type=="today"){
      datatotal=JSON.parse(localStorage.getItem('dateToday'));
      startDate=this.convertDate(new Date());
      endDate=this.convertDate(new Date());
      console.log(`all desde ${startDate} hasta ${endDate}`);
      window.localStorage.setItem('goBack',`/requests/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getClientsDate/${startDate}/${endDate}/${option}`);
    }
    if(type=="yesterday"){
      datatotal=JSON.parse(localStorage.getItem('dateYesterday'));
      startDate=this.convertDate(new Date(this.formatDate(0)));
      endDate=this.convertDate(new Date());
      console.log(`yesterday desde ${startDate} hasta ${endDate}`);
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      // return ''
      return this.http.get(`${baseUrl}/getClientsDateYesterday/${startDate}/${endDate}/${option}`);
    }
    if(type=="week"){
      datatotal=JSON.parse(localStorage.getItem('dateWeek'));
      startDate=this.getMonday(new Date());
      endDate=this.convertDateAddDay(new Date());
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getClientsDateWeek/${startDate}/${endDate}/${option}`);
    }
    if(type=="others"){
      datatotal=JSON.parse(localStorage.getItem('dateRange'));
      startDate=datatotal.dateStart
      endDate=datatotal.dateEnd
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      console.log(`${baseUrl}/getClientsOthers/${startDate}/${endDate}/${option}`);
      return this.http.get(`${baseUrl}/getClientsOthers/${startDate}/${this.convertDate(new Date(endDate))}/${option}`);
    }
  }

  convertDate(fecha){
    var day = "";
    var month = "";

    if(fecha.getMonth() < 9){
      month = fecha.getMonth() + 1;
      month = "0" + month;
    } else {
      month = fecha.getMonth() + 1;
    }

    if(fecha.getDate() < 9){
      day = "0"+fecha.getDate();
    } else {
      day = fecha.getDate();
    }

    return `${fecha.getFullYear()}-${month}-${day}`;
  }

  convertDateAddDay(fecha){
    var day = "";
    var month = "";

    fecha = fecha.getTime()+(1*24*60*60*1000);
    fecha = new Date(fecha);

    if(fecha.getMonth() < 9){
      month = fecha.getMonth() + 1;
      month = "0" + month;
    } else {
      month = fecha.getMonth() + 1;
    }

    if(fecha.getDate() < 9){
      day = "0"+fecha.getDate();
    } else {
      day = fecha.getDate();
    }

    return `${fecha.getFullYear()}-${month}-${day}`;
  }


  formatDate(i){
    var d = new Date,
         dformat = [ d.getFullYear(),
                    this.padLeft(d.getMonth()+1),
                    this.padLeft(d.getDate()-i)
                    ].join('-');
     return ''+dformat
  }

  padLeft(n){
    return ("00" + n).slice(-2);
  }

  getClientsRenewal(page) {
    let option = localStorage.getItem('module');
    //return this.http.get(`${baseUrl}/findAllClientsRenewal/list/${option}/${page}/100`);


    return this.http.get(`${baseUrl}/findAllClientsRenewal/list/${option}/${page}/100`);
  }
  getClientRenewalSetted(){
    if(this.listRenewal!=null){
      return this.listRenewal;
    }
    return null
  }
  setClientRenewal(data){
    this.listRenewal=data;
  }
  filterClients(caracters, status){
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/filter/${caracters}/${option}/${status}`);
  }
  filterClientsRenewal(caracters, status){
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/filter/renewal/${caracters}/${option}/${status}`);
  }

  getClientStatus(id,page){
    window.localStorage.setItem('goBack',`/requests`);
    window.localStorage.setItem('goBackStatus',id);
    let option = localStorage.getItem('module');
    // return this.http.get(`${baseUrl}/status/renewal/${id}/${option}/${page}/100`);

    return this.http.get(`${baseUrl}/status/${id}/${option}/${page}/100`);
  }
  setClientStatusType(data){
    this.listClients=data;
  }
  setClientStatusTypeSetted(data){
    this.listClients=data;
    console.log(this.listClients,"setClientStatusTypeSetted")
  }
  getClientStatusTypeSetted(type){
    if(this.listClients!=null){
        return this.listClients;
    }
    return null
  }
  getClientStatusType(id,type,page){
    let startDate
    let endDate
    let datatotal
    let option = localStorage.getItem('module');
    if(type=="today"){
      datatotal=JSON.parse(localStorage.getItem('dateToday'));
      startDate=this.convertDate(new Date());
      endDate=this.convertDate(new Date());
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/${id}`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getClientStatusTypeToday/${id}/${startDate}/${endDate}/${option}/${page}/100`);
    }
    if(type=="yesterday"){
      datatotal=JSON.parse(localStorage.getItem('dateYesterday'));
      startDate=this.convertDate(new Date(this.formatDate(0)));
      endDate=this.convertDate(new Date());
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/${id}`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getClientStatusTypeYesterday/${id}/${startDate}/${endDate}/${option}/${page}/100`);
    }
    if(type=="week"){
      datatotal=JSON.parse(localStorage.getItem('dateWeek'));
      startDate=this.getMonday(new Date());
      endDate=this.convertDateAddDay(new Date());
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/${id}`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getClientStatusTypeWeek/${id}/${startDate}/${endDate}/${option}/${page}/100`);
    }
    if(type=="others"){
      datatotal=JSON.parse(localStorage.getItem('dateRange'));
      startDate=datatotal.dateStart
      endDate=datatotal.dateEnd
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/requests/${type}/${id}`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getClientStatusOthers/${id}/${startDate}/${this.convertDate(new Date(endDate))}/${option}/${page}/100`);
    }
  }

  getClientStatusRenewal(id,page){
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/status/renewal/${id}/${option}/${page}/100`);
  }
  getClientStatusRenewalSetted(){
    if(this.listRenewal!=null){
      return this.listRenewal;
    }
    return null
  }
  setClientStatusRenewal(data){
    this.listRenewal=data
  }
  getClient(id) {
    let option = localStorage.getItem('module');
    console.log(`${baseUrl}/${id}/${option}`)
    return this.http.get(`${baseUrl}/${id}/${option}`);
  }

  getHistory(id) {
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/history/${id}/${option}`);
  }

  setStatus(id){
    return this.http.post(`${baseUrl}/setStatus`, id);
  }

  setStatusSeeing(id, flag){
    if(flag == 0){
      localStorage.removeItem('cheking');
    }else{
      window.localStorage.setItem('cheking',id);
    }
    return this.http.post(`${baseUrl}/setStatusSeeing`, {flag: flag, id: id });
  }

  getStatusSeeing(id){
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/getStatusSeeing/${id}/${option}`);
  }

  updateClient(id,data){
    console.log(data,"updateClient")
    return this.http.put(`${baseUrl}/update/${id}`, data);
  }

  updateClient2(id,data){
    console.log(data,"updateClient")
    return this.http.put(`${baseUrl}/update2/${id}`, data);
  }


  getLoanID(client_id){
    let option = localStorage.getItem('module');
    return this.http.get(`${baseUrl}/loan/${client_id}/${option}`);
  }

  searchClientEmail(data){
    return this.http.post(`${baseUrl}/searchEmail`,data);
  }

  generateListExcel(data){
    return this.http.get(`${baseUrl}/getListClientsIBV/list/${data.startDate}/${data.endDate}/${data.option}`);
  }

  countRenewal(type){
    let startDate
    let endDate
    let datatotal
    let option = localStorage.getItem('module');
    if(type=="today"){
      datatotal=JSON.parse(localStorage.getItem('dateToday'));
      startDate=this.convertDate(new Date());
      endDate=this.convertDate(new Date());
      console.log(`all desde ${startDate} hasta ${endDate}`);
      return this.http.get(`${baseUrl}/getCountRenewal/${startDate}/${endDate}/${option}`);
    }
    if(type=="yesterday"){
      datatotal=JSON.parse(localStorage.getItem('dateYesterday'));
      startDate=this.convertDate(new Date(this.formatDate(0)));
      endDate=this.convertDate(new Date());
      console.log(`yesterday desde ${startDate} hasta ${endDate}`);
      console.log(datatotal)
      // return ''
      return this.http.get(`${baseUrl}/getCountRenewal/${startDate}/${endDate}/${option}`);
    }
    if(type=="week"){
      datatotal=JSON.parse(localStorage.getItem('dateWeek'));
      startDate=this.getMonday(new Date());
      endDate=this.convertDateAddDay(new Date());
      console.log(datatotal)
      return this.http.get(`${baseUrl}/getCountRenewal/${startDate}/${endDate}/${option}`);
    }
    if(type=="others"){
      datatotal=JSON.parse(localStorage.getItem('dateRange'));
      startDate=datatotal.dateStart
      endDate=datatotal.dateEnd
      console.log(datatotal)
      console.log(`${baseUrl}/getClientsOthers/${startDate}/${endDate}/${option}`);
      return this.http.get(`${baseUrl}/getCountRenewal/${startDate}/${this.convertDate(new Date(endDate))}/${option}`);
    }
  }

  getAllRenewal(type,page){
    let startDate
    let endDate
    let datatotal
    let option = localStorage.getItem('module');
    if(type=="today"){
      datatotal=JSON.parse(localStorage.getItem('dateToday'));
      startDate=this.convertDate(new Date());
      endDate=this.convertDate(new Date());
      console.log(`all desde ${startDate} hasta ${endDate}`);
      window.localStorage.setItem('goBack',`/renewal/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getAllRenewal/${startDate}/${endDate}/${option}`);
    }
    if(type=="yesterday"){
      datatotal=JSON.parse(localStorage.getItem('dateYesterday'));
      startDate=this.convertDate(new Date(this.formatDate(0)));
      endDate=this.convertDate(new Date());
      console.log(`yesterday desde ${startDate} hasta ${endDate}`);
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/renewal/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      // return ''
      return this.http.get(`${baseUrl}/getAllRenewal/${startDate}/${endDate}/${option}`);
    }
    if(type=="week"){
      datatotal=JSON.parse(localStorage.getItem('dateWeek'));
      startDate=this.getMonday(new Date());
      endDate=this.convertDateAddDay(new Date());
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/renewal/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getAllRenewal/${startDate}/${endDate}/${option}`);
    }
    if(type=="others"){
      datatotal=JSON.parse(localStorage.getItem('dateRange'));
      startDate=datatotal.dateStart
      endDate=datatotal.dateEnd
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/renewal/${type}/all`);
      window.localStorage.setItem('goBackStatus','');
      console.log(`${baseUrl}/getClientsOthers/${startDate}/${endDate}/${option}`);
      return this.http.get(`${baseUrl}/getAllRenewal/${startDate}/${this.convertDate(new Date(endDate))}/${option}`);
    }
  }

  getAllRenewalStatus(type, status_id,page){
    let startDate
    let endDate
    let datatotal
    let option = localStorage.getItem('module');
    if(type=="today"){
      datatotal=JSON.parse(localStorage.getItem('dateToday'));
      startDate=this.convertDate(new Date());
      endDate=this.convertDate(new Date());
      console.log(`all desde ${startDate} hasta ${endDate}`);
      window.localStorage.setItem('goBack',`/renewal/${type}/${status_id}`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getAllRenewalStatus/${startDate}/${endDate}/${option}/${status_id}/${page}/100`);
    }
    if(type=="yesterday"){
      datatotal=JSON.parse(localStorage.getItem('dateYesterday'));
      startDate=this.convertDate(new Date(this.formatDate(0)));
      endDate=this.convertDate(new Date());
      console.log(`yesterday desde ${startDate} hasta ${endDate}`);
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/renewal/${type}/${status_id}`);
      window.localStorage.setItem('goBackStatus','');
      // return ''
      return this.http.get(`${baseUrl}/getAllRenewalStatus/${startDate}/${endDate}/${option}/${status_id}/${page}/100`);
    }
    if(type=="week"){
      datatotal=JSON.parse(localStorage.getItem('dateWeek'));
      startDate=this.getMonday(new Date());
      endDate=this.convertDateAddDay(new Date());
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/renewal/${type}/${status_id}`);
      window.localStorage.setItem('goBackStatus','');
      return this.http.get(`${baseUrl}/getAllRenewalStatus/${startDate}/${endDate}/${option}/${status_id}/${page}/100`);
    }
    if(type=="others"){
      datatotal=JSON.parse(localStorage.getItem('dateRange'));
      startDate=datatotal.dateStart
      endDate=datatotal.dateEnd
      console.log(datatotal)
      window.localStorage.setItem('goBack',`/renewal/${type}/${status_id}`);
      window.localStorage.setItem('goBackStatus','');
      console.log(`${baseUrl}/getClientsOthers/${startDate}/${endDate}/${option}`);
      return this.http.get(`${baseUrl}/getAllRenewalStatus/${startDate}/${this.convertDate(new Date(endDate))}/${option}/${status_id}/${page}/100`);
    }
  }

  getBorrowerClient(email){
    return this.http.get(`${baseUrl}/getBorrowerClient/${email}`);
  }

  getDataClientLoan(id:any){
    return this.http.get(`${baseUrl}/getDataClientLoan/${id}`);
  }
}
