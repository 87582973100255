import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/inverite';

@Injectable({
  providedIn: 'root'
})
export class InveriteService {

  constructor(private http: HttpClient) { }
  
  list(data) {
    return this.http.post(`${baseUrl}/list`, data);
  }

  create(data) {
    return this.http.post(`${baseUrl}/create`, data);
  }

  status(data) {
    return this.http.post(`${baseUrl}/status`, data);
  }
  
  fetch(guid) {
    return this.http.get(`${baseUrl}/fetch/${guid}`);
  }

  list_available() {
    return this.http.get(`${baseUrl}/bank/list_available`);
  }

  bank_form(id) {
    return this.http.get(`${baseUrl}/bank_form/${id}`);
  }

  session_start(data,guid) {
    return this.http.post(`${baseUrl}/session_start/${guid}`, data);
  }

  session_status(jobID) {
    return this.http.get(`${baseUrl}/session_status/${jobID}`);
  }

  session_challenge_response(data,jobID) {
    return this.http.post(`${baseUrl}/session_challenge_response/${jobID}`, data);
  }

  refresh(data) {
    return this.http.post(`${baseUrl}/refresh`, data);
  }

  //get Ip address
  getIPAddress(){
    return axios.get("https://api.ipify.org/?format=json"); 
  } 

}
