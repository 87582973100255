import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ClientService } from 'src/app/services/client.service';
import { Administrator } from '../../interfaces/auditory-log';

export interface tableData {
  username: string;
  ratio_approval: number;
  approved: number;
  ratio_funding: number;
  funding: number;
  touched: number;
};

@Component({
  selector: 'app-datakpi',
  templateUrl: './datakpi.component.html',
  styleUrls: ['./datakpi.component.scss']
})

export class DatakpiComponent implements OnInit, OnChanges {

   @Input() requestQuatityUser!: Administrator;
   totalApproved: number = 0;
   totalViewed: number = 0;
   totalFunded: number = 0;
   averageApproval: number = 0;
   averageFunded: number = 0;
   tableArray: tableData[] = [];
   startDate: string = '';
   endDate: string = '';
   sumPercent: number = 0;
   quantity: number = 0;

  constructor(
    private clientService: ClientService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.requestQuatityUser = changes.requestQuatityUser.currentValue;
    this.tableArray = [];
    this.totalApproved = 0;
    this.totalFunded = 0;
    this.totalViewed = 0;
    this.calc();

  }

  ngOnInit(): void {
    this.tableArray = [];
    this.totalApproved = 0;
    this.totalFunded = 0;
    this.totalViewed = 0;
    this.calc();
  }

  calc(){
    this.startDate = `${localStorage.getItem('startDate')}`;
    this.endDate = `${localStorage.getItem('endDate')}`;
    // let total = 0;

    if(this.requestQuatityUser){
      let count_ = 0, sumApproved = 0, sumFunded = 0, sumTouch = 0;
      console.log(this.requestQuatityUser.stadistics)
      this.requestQuatityUser.stadistics.forEach(request => {

        sumTouch += request.total_touched;

        this.totalApproved += request.total_approved;
        this.totalFunded += request.total_funded;
        count_++;
        let ratio_approval = '0'
        if(request.total_touched > 0){
          ratio_approval = ((request.total_approved) / ( request.total_touched ) * 100 ).toFixed(0);
          sumApproved += parseFloat(ratio_approval);
          this.sumPercent += parseFloat(ratio_approval);
        }


        let ratio_funding  = '0';
        if(request.total_approved > 0){
          ratio_funding  = (request.total_funded / request.total_approved * 100).toFixed(0);
          sumFunded += parseFloat(ratio_funding);
        }
        this.quantity++
        this.tableArray.push({username: request.username, ratio_approval: parseFloat(ratio_approval), approved: request.total_approved, ratio_funding: parseFloat(ratio_funding), funding: request.total_funded, touched: request.total_touched});
      });

      this.totalViewed += sumTouch;

      this.averageApproval = parseFloat((this.sumPercent / this.quantity).toFixed(0));
      this.averageFunded = parseFloat(((this.totalFunded / this.totalApproved) * 100).toFixed(0));

    }else{
      // this.totalApproved = parseFloat((this.requestQuatityUser.approved * 100 / (this.requestQuatityUser.approved + this.requestQuatityUser.refused)).toFixed(2));
      // this.totalFunded = parseFloat((this.requestQuatityUser.refused * 100 / (this.requestQuatityUser.approved + this.requestQuatityUser.refused)).toFixed(2));
      // console.log(this.totalRefused + ' - ' + this.totalApproved)
    }
  }

}
