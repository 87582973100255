import { Component, OnInit } from '@angular/core';
import { QuestionAnswerService } from 'src/app/services/question-answer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';

@Component({
  selector: 'app-bank',
  templateUrl: './bank.component.html',
  styleUrls: ['./bank.component.scss']
})
export class BankComponent implements OnInit {

  currentQuestion = null;
  message = '';

  constructor(
    private questionAnswerService: QuestionAnswerService,
    private route: ActivatedRoute,
    public googleAnalyticsService: GoogleAnalyticsService
    ) { }

  ngOnInit() {
    this.message = '';
    this.getQuestion();
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/bank', 'Follow User', 'userLabel', 1);
  }

  getQuestion() {
    this.questionAnswerService.getAll()
      .subscribe(
        data => {
          this.currentQuestion = data;
          console.log(data);
        },
        error => {
          console.log(error);
        });
  }

}
