import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-payment-schedule',
  templateUrl: './payment-schedule.component.html',
  styleUrls: ['./payment-schedule.component.scss']
})
export class PaymentScheduleComponent implements OnInit {
  @Input() pay
  payment
  listMonth=[
  ]
  employer_monthly_income: any;
  employer_monthly_income_freq: any;
  government_monthly_income: any;
  government_monthly_income_freq: any;
  constructor() { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.payment=changes.pay.currentValue;
    console.log(this.payment)
    this.employer_monthly_income=this.payment.statistics.employer_monthly_income
    this.employer_monthly_income_freq=this.payment.statistics.employer_monthly_income_freq
    this.government_monthly_income=this.payment.statistics.government_monthly_income
    this.government_monthly_income_freq=this.payment.statistics.government_monthly_income_freq
    this.employer_monthly_income=Object.values(this.employer_monthly_income);
    this.employer_monthly_income_freq=Object.values(this.employer_monthly_income_freq);
    this.government_monthly_income_freq=Object.values(this.government_monthly_income_freq);
    this.government_monthly_income=Object.values(this.government_monthly_income);

    let allData=[];
    let cont=0;
    this.employer_monthly_income.filter(resp=>{
      allData.push({
        month:cont+" Months Ago",
        employer_monthly_income:resp,
        employer_monthly_income_freq:this.employer_monthly_income_freq[cont],
        government_monthly_income_freq:this.government_monthly_income_freq[cont],
        government_monthly_income:this.government_monthly_income[cont],
      })
      cont++;
    })
    this.employer_monthly_income=allData
    console.log(allData)
    
  }
}
