import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'mugan86-ng-google-analytics';
import { ClientService } from "../../services/client.service";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  content = '';
  private roles: string[];
  isLoggedIn = false;
  username: string;
  showAdminBoard = false;
  showModeratorBoard = false;
  showCustomerServiceBoard = false;
  selectedIndex: number = null;
  year: number = 0;

  showNav = [true,false,false,false,false,false,false,false,false,false,false,false,false,false,false];//Default,Request,User,Renewal,Contract,Setting
  showNav_ = ["dashboard","users","requests","renewal","contracts","setting","branch","roles","marketing","","select ibv"];//Default,Request,User,Renewal,Contract,Setting
  activeRol = [true,false,false,false,false,false,false,false,false];
  user_id: any;

  constructor(
    private router:Router,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    public googleAnalyticsService: GoogleAnalyticsService,
    private rutaActiva: ActivatedRoute,
    private clientService: ClientService,
    private title: Title
    ) { }

  ngOnInit() {
    let date = new Date();
    this.title.setTitle('Approbex PC');
    this.year = date.getFullYear();
    this.isLoggedIn = !!this.tokenStorageService.getToken();
    this.user_id=localStorage.getItem('cheking');
    this.googleAnalyticsService.eventEmitter('testprestobroker.ddns.net/dashboard', 'Follow User', 'userLabel', 1);
    let screen=this.rutaActiva.snapshot.params.screen;
    console.log(screen,"xxxxxxxx")

    let separate=this.router.url.split("/")
    console.log(separate,"ddd")
    console.log(this.router.url,"ASDASDASDASDSADSA")

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      console.log(user);
      this.username = user.username;
      this.roles = user.roles;
      console.log(this.user_id)
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_UNDERWRITER');
      this.showCustomerServiceBoard = this.roles.includes('ROLE_CUSTOMER SERVICE');
      console.log('hola mundo ');
      console.log(this.showAdminBoard);
      console.log(this.showModeratorBoard);
      console.log(this.showCustomerServiceBoard);
    } else {
      this.router.navigate(['login']);
    }
    if(this.router.url === '/dashboard'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 0;
        this.Show(0);
        return;
      });

    } else if(separate[1]=="flink" && separate[2]=="req"){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 11;
        this.Show(11);
        return;
      });
    } else if(separate[1]=="inverite" && separate[2]=="req"){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 12;
        this.Show(12);
        return;
      });
    }else if(separate[1]=="flink" && separate[2]=="rew"){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 11;
        this.Show(13);
        return;
      });
    } else if(separate[1]=="inverite" && separate[2]=="rew"){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 12;
        this.Show(14);
        return;
      });
    }
     else if(this.router.url === '/users'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 1;
        this.Show(1);
      });

      return;
    } else if(((this.router.url === '/requests'||separate[1]=="requests")||(separate[1]!='renewals'&&screen==1))||(this.rutaActiva.snapshot.params.type=="today"&&(separate[1]!='renewals'&&screen==1))||(this.rutaActiva.snapshot.params.type=="yesterday"&&(separate[1]!='renewals'&&screen==1))||(this.rutaActiva.snapshot.params.type=="week"&&(separate[1]!='renewals'&&screen==1))||(this.rutaActiva.snapshot.params.type=="others"&&(separate[1]!='renewals'&&screen==1))){
      // alert(screen)
      window.localStorage.setItem('statusRenewal','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 2;
        this.Show(2);
      });
      return;
    } else if(this.router.url === '/renewal'||  separate[1]=='renewals'){
      // alert()
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 3;
        this.Show(3);
      });
      return;
    } else if(this.router.url === '/contracts'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 4;
        this.Show(4);
      });
      return;
    } else if(this.router.url === '/setting'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 5;
        this.Show(5);
      });
      return;
    } else if(this.router.url === '/branch'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 6;
        this.Show(6);
      });
      return;
    } else if(this.router.url === '/select-ibv'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 10;
        this.Show(10);
      });
      return;
    }
     else if(this.router.url === '/roles'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 7;
        this.Show(7);
      });
      return;
    } else if(this.router.url === '/logs' || this.router.url.includes("/logs")){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 8;
        this.Show(8);
      });
      return;
    } else if(this.router.url === '/marketing'){
      window.localStorage.setItem('statusRenewal','0');
      window.localStorage.setItem('goBackStatus','0');
      this.clientService.setStatusSeeing(this.user_id, 0).subscribe(data_st => {
        this.selectedIndex = 9;
        this.Show(9);
      });
      return;
    }



    if(this.showAdminBoard){
      this.userService.getAdminBoard().subscribe(
        data => {
          this.content = data;
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    } else{
      this.userService.getModeratorBoard().subscribe(
        data => {
          this.content = data;
        },
        err => {
          this.content = JSON.parse(err.error).message;
        }
      );
    }

  }

  logout() {
    this.tokenStorageService.signOut();
    window.location.reload();
  }

  Show(value){
    console.log(value,"showwwwwwww")
    this.selectedIndex = value;
    for (var i = 0; i < this.showNav.length; i++) {

      if(i == value){
        // alert(i+" - "+value);
        this.showNav[i] = true;

      } else {
        this.showNav[i] = false;
      }

    }
    console.log(this.showNav)
  }


}
