import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const Ibvs = environment.url_server+'/api/ibvs';
const ibvWebsites = environment.url_server+'/api/ibv-websites';
const websites = environment.url_server+'/api/websites';

@Injectable({
  providedIn: 'root'
})
export class IbvWebsitesService {

  constructor(private http: HttpClient) { }

  getAllIbv() {
    return this.http.get(`${Ibvs}`);
  }
  getAllIbvWebsites() {
    return this.http.get(`${ibvWebsites}`);
  }
  getWebsites() {
    return this.http.get(`${websites}`);
  }
  postUpdate(data){
    return this.http.post(`${ibvWebsites}/update`,data);

  }
  getSearch(id){
    return this.http.get(`${ibvWebsites}/search/${id}`);
  }
  
}
