import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/branches';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private http: HttpClient) { }

  branches() {
    return this.http.get(`${baseUrl}`);
  }

  getBranch(id) {
    return this.http.get(`${baseUrl}/get_branch/${id}`);
  }

  createBranch(data) {
    return this.http.post(`${baseUrl}/create_branch`, data);
  }

  updateBranch(id,data){
    return this.http.put(`${baseUrl}/update_branch/${id}`,data);
  }

  deleteBranch(id){
    return this.http.delete(`${baseUrl}/delete/${id}`);
  }

  selectedBranch(){
    return this.http.get(`${baseUrl}/selected`);
  }
}
