import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/flinks';

@Injectable({
  providedIn: 'root'
})
export class FlinksService {
  alldata: any;

  constructor(private http: HttpClient) { }

  addTransactions(data: any){
    const url = `${baseUrl}/updateclient`;
    return this.http.post<any>(url, data);
  }

  getRequestID(loginId){
    return this.http.post(`${baseUrl}/getAccountClient`, loginId);
  }
  getAccountsDetail(request){
    return this.http.get(`${baseUrl}/getAccountsDetails/${request}`);
  }

  fetch(requestID) {
    return this.http.post(`${baseUrl}/getAccountsDetail`,requestID);
  }
  setAllAcount(data){
    this.alldata=data;
  }
  getAllAcount(request_id){
    let data=this.alldata.Accounts.find(resp=>{
      if(resp.AccountNumber==request_id){
        return resp
      }
    })
    return data
  }
  getAllDataInverite(request_id){
    let data=this.alldata.accounts.find(resp=>{
      if(resp.account==request_id){
        return resp
      }
    })
    return data
  }

  saveTansactions(data){
    return this.http.post(`${baseUrl}/saveDataDetails`,data);
  }

}
