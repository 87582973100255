import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/loandisk';

@Injectable({
  providedIn: 'root'
})
export class LoandiskService {

  constructor(private http: HttpClient) { }
  
  createBorrower(data) {
    return this.http.post(`${baseUrl}/borrower/create_borrower`, data);
  }
  createLoan(data) {
    return this.http.post(`${baseUrl}/loan/create_loan`, data);
  }
  updateBorrower(data){
    return this.http.put(`${baseUrl}/borrower/update_borrower`,data);
  }

  createLoanLocal(data){
    return this.http.post(`${baseUrl}/loan/createLoanLocal`,data);
  }

  updateLoan(data){
    return this.http.put(`${baseUrl}/loan/update_loan`,data);
  }

  getBorrower(borrower, branch){
    return this.http.get(`${baseUrl}/borrower/${borrower}/${branch}`);
  }
  //loan_first_repayment_date
  getDataLoan(loan_id, branch_id){
    return this.http.get(`${baseUrl}/loan/${loan_id}/${branch_id}`);
  }
}