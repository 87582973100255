import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-transacctions',
  templateUrl: './transacctions.component.html',
  styleUrls: ['./transacctions.component.scss']
})
export class TransacctionsComponent implements OnInit {
  // @Input() trans
  transacctions
  constructor() { }

  ngOnInit() {
  }
  // ngOnChanges(changes: SimpleChanges): void {
  //   //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //   //Add '${implements OnChanges}' to the class.
  //   // this.transacctions=changes.trans.currentValue;
  // }
}
