import { Component, OnInit } from '@angular/core';
import { AuditoryService } from 'src/app/services/auditory.service';
import { StatuService } from 'src/app/services/statu.service';
import { ClientService } from 'src/app/services/client.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.component.html',
  styleUrls: ['./marketing.component.scss']
})
export class MarketingComponent implements OnInit {
  logs;
  total_list
  list_status = null;
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  xlsx = null;
  maxDate

  constructor(
    private auditoryService:AuditoryService,
    private statuService:StatuService,
    private clientService: ClientService,
  ) { }

  ngOnInit() {
    this.getStatus();
    this.maxDate = this.getdate();
  }

  getdate(){
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1;
    let yyyy = today.getFullYear();
    let month = mm < 10 ? '0'+mm : mm;
    let day = dd < 10 ? '0'+dd : dd;
    return yyyy+'-'+month+'-'+day;
    
  }

  getStatus(){
    this.statuService.getStatus()
      .subscribe(
        data => {
           this.list_status = data;
          //  console.log(data);
        },
        error => {
          console.log(error);
        });
  }

  generateXMLX(event){
    let startDate = (document.getElementById("dateStart") as HTMLInputElement).value;
    let endDate = (document.getElementById("dateEnd") as HTMLInputElement).value;
    let option = (document.getElementById("option") as HTMLInputElement).value;

    if(endDate < startDate){
      alert('The end date cannot be less than the start date!');
      (document.getElementById("dateStart") as HTMLInputElement).value = '';
      (document.getElementById("dateEnd") as HTMLInputElement).value = '';
      return;
    }

    if(option == 'Choose Status'){
      alert("Select a Status.");
      return;
    }

    this.clientService.generateListExcel({startDate: startDate, endDate: endDate, option: option})
    .subscribe(data => {
      this.xlsx = data;
      this.exportAsExcelFile(this.xlsx, 'clients');
    });
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
     const data: Blob = new Blob([buffer], {type: this.EXCEL_TYPE});
     FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + this.EXCEL_EXTENSION);
  }
  
}
