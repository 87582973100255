import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';

const baseUrl = environment.url_server+'/api/timeStatus';

@Injectable({
  providedIn: 'root'
})
export class TimeStadisticService {

  constructor(private http: HttpClient) { }

  createTimeStadistic(data) {
    return this.http.post(`${baseUrl}/create`, data);
  }

  updateTimeStadistic(data){
    return this.http.put(`${baseUrl}/update`, data);
  }
}
